import AmountInput from 'components/AmountInput'
import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import {
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap'
import { usePostPayReceivableMutation } from '../receivable-notes/receivableNotesApiSlice'
import {
  toggleBannerReceivable,
  toggleSidebarReceivable,
} from '../receivable-notes/receivableNotesSlice'
import {
  useLazyGetPayableQuery,
  usePostPayPayableMutation,
} from './payableNotesApiSlice'
import { setBannerMsg, toggleBanner, toggleSidebar } from './payableNotesSlice'

const ModalAdjustPayable = ({ row, isReceivable, toggle, isOpen }) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const [errMsg, setErrMsg] = useState('')
  const [amount, setAmount] = useState('')
  const [postPayPayable, result] = usePostPayPayableMutation()
  const [postPayReceivable, resultReceivable] = usePostPayReceivableMutation()
  const [getPayable] = useLazyGetPayableQuery()

  const handleSubmit = async () => {
    try {
      const body = {
        amountPaid: Number(
          amount?.replace?.(/\./g, '')?.replace?.(/,/g, '.')?.slice?.(0, 15),
        ),
        payableId: row?.id,
        notes: row?.notes,
        manual: row?.manual,
        ...(isReceivable && {
          payerName: row?.payerName,
        }),
        ...(!isReceivable && {
          receivableName: row?.name,
        }),
      }

      const resp = isReceivable
        ? await postPayReceivable(body).unwrap()
        : await postPayPayable(body).unwrap()

      if (!resp?.data?.error) {
        toggle()
        dispatch(isReceivable ? toggleSidebarReceivable() : toggleSidebar())
        getPayable({ type: isReceivable ? 'receivable' : 'payable' })
        dispatch(
          isReceivable
            ? setBannerMsg('Penyesuaian piutang berhasil.')
            : setBannerMsg('Penyesuaian tagihan berhasil.'),
        )
        dispatch(isReceivable ? toggleBannerReceivable() : toggleBanner())
      } else {
        throw resp
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.data?.message ?? error?.message)
    }
  }

  useEffect(() => {
    setErrMsg('')
  }, [amount])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpen}
      toggle={() => {
        toggle()
        setErrMsg('')
      }}
      autoFocus={false}
    >
      <ModalBody>
        <h5>Sesuaikan {isReceivable ? 'piutang' : 'utang'}</h5>
        <FormGroup className="mt-3">
          <Label className="fw-500">
            {isReceivable
              ? 'Sudah terima pembayaran sebesar'
              : 'Sudah bayar sebesar'}
          </Label>
          <InputGroup>
            <InputGroupText className="bg-transparent">Rp</InputGroupText>
            <AmountInput
              max={(row?.amount ?? 0) - (row?.amountPaid ?? 0)}
              cb={(res) => {
                setAmount(res)
              }}
              placeholder="0"
            />
          </InputGroup>
          <div className="mt-1 text-gray-600">
            Sisa {isReceivable ? 'piutang' : 'utang'}: Rp
            {intl.formatNumber((row?.amount ?? 0) - (row?.amountPaid ?? 0), {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </div>
        </FormGroup>
        {errMsg && (
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        )}
        <div className="d-flex gap-3 mt-4">
          <CustomButton
            onClick={() => {
              toggle()
            }}
            disabled={result?.isLoading || resultReceivable?.isLoading}
            color="primary"
            outline
            block
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={
              !amount || result.isLoading || resultReceivable?.isLoading
            }
            onClick={handleSubmit}
            color="primary"
            block
          >
            Simpan
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalAdjustPayable
