import CustomButton from 'components/Buttons/CustomButton'
import PMBSelect from 'components/PMBSelect'
import { itemUnitList } from 'constants'
import { useEffect, useState } from 'react'
import { Trash2 } from 'react-feather'
import { Card, Input } from 'reactstrap'
import InventoryCodeInput from '../Input/InventoryCodeInput'

const TransformModal = ({
  notes,
  setNotes,
  transformItems,
  setTransformItems,
  itemsList = [],
  onUpdateStock,
  isLoading,
}) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  // HANDLE UNIT OPTIONS
  const unitOptions = itemUnitList?.map((item) => ({
    value: item?.title,
    label: item?.title,
  }))

  // HANDLE ITEM CHANGE
  const handleItemChange = (index, key, value) => {
    setTransformItems((prev) => {
      const updatedItems = [...prev]
      updatedItems[index][key] = value
      return updatedItems
    })
  }

  // HANDLE ADD ITEM
  const handleAddItem = () => {
    setTransformItems((prev) => [
      ...prev,
      {
        ownerCode: null,
        amount: 0,
        itemId: null,
        description: '',
        unit: unitOptions?.[0]?.value,
      },
    ])
  }

  // HANDLE FOR REMOVE BASED ON INDEX
  const handleRemoveItem = (index) => {
    setTransformItems((prev) => prev.filter((_, i) => i !== index))
  }

  // HANDLE FOR DISABLED ADD AND SAVE
  useEffect(() => {
    const isDisabled = transformItems.some(
      (item) => item.amount === 0 || item.amount === '' || item.itemId === null,
    )

    setIsSaveDisabled(isDisabled)
  }, [transformItems, notes, isLoading, unitOptions])

  // FORMAT AMOUNT
  const formatAmount = (value) => {
    if (!value) return ''

    const numericValue = value
      .replace(/[^\d,]/g, '')
      .replace(/\.+/g, '')
      .replace(/,+/g, ',')

    const parts = numericValue.split(',')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    if (parts[1]) {
      parts[1] = parts[1].substring(0, 2)
    }

    return parts.join(',')
  }

  return (
    <div>
      {transformItems?.map((item, index) => (
        <Card
          className="mb-3 mt-3 p-3 border-0 shadow-sm"
          key={index}
          style={{
            border: 'none',
          }}
        >
          {/* INVENTORY CODE COLUMN */}
          <div className="mb-3">
            <label>Kode Inventori (opsional)</label>
            <InventoryCodeInput
              value={item?.ownerCode || ''}
              onChange={(code) => handleItemChange(index, 'ownerCode', code)}
              isFocus={index === transformItems?.length - 1}
            />
          </div>
          {/* END INVENTORY CODE COLUMN */}

          {/* RESULT COLUMN */}
          <div className="mb-3">
            <label>
              Hasil<span style={{ color: 'red' }}>*</span>
            </label>
            <PMBSelect
              value={itemsList.find((opt) => opt.value === item.itemId) || ''}
              onChange={(selected) =>
                handleItemChange(index, 'itemId', selected.value)
              }
              options={itemsList}
              placeholder="Pilih item"
            />
          </div>
          {/* END RESULT COLUMN */}

          {/* AMOUNT AND UNIT */}
          <div className="d-flex justify-content-between">
            <div className="me-2" style={{ flex: 1 }}>
              <label>
                Jumlah<span style={{ color: 'red' }}>*</span>
              </label>
              <Input
                style={{
                  height: '42px',
                }}
                type="text"
                value={item.amount}
                onChange={(e) => {
                  const rawValue = e.target.value
                  const formattedValue = formatAmount(rawValue)
                  handleItemChange(index, 'amount', formattedValue)
                }}
                placeholder="Masukkan jumlah"
              />
            </div>

            <div style={{ flex: 1 }}>
              <label>
                Satuan<span style={{ color: 'red' }}>*</span>
              </label>
              <PMBSelect
                value={unitOptions.find((opt) => opt.value === item.unit) || ''}
                onChange={(selected) =>
                  handleItemChange(index, 'unit', selected.value)
                }
                options={unitOptions}
                placeholder="Pilih satuan"
              />
            </div>
          </div>
          {/* END AMOUNT AND UNIT */}

          {/* HANDLE BUTTON REMOVE */}
          {transformItems?.length > 1 && (
            <div className="d-flex justify-content-end align-center mt-3">
              <CustomButton
                outline
                color="danger"
                size="sm"
                onClick={() => handleRemoveItem(index)}
              >
                <Trash2 size={15} className="gap-2" />
              </CustomButton>
            </div>
          )}
          {/* END HANDLE BUTTON REMOVE */}
        </Card>
      ))}

      {/* NOTES */}
      <Card
        className="mb-3 mt-3 p-3 shadow-sm"
        style={{
          border: 'none',
        }}
      >
        <div className="mb-3">
          <label>Catatan</label>
          <Input
            type="textarea"
            style={{
              minHeight: 100,
            }}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Masukkan catatan jika ada..."
          />
        </div>
      </Card>
      {/* END NOTES */}

      {/* ADD AND SAVE */}
      <div>
        <CustomButton
          className="mb-3"
          disabled={isLoading || isSaveDisabled}
          onClick={handleAddItem}
          outline
          block
          style={{
            borderColor: '#D0D5DD',
            color: '#344054',
            fontSize: 14,
            padding: 10,
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = 'transparent'
            e.target.style.color = '#344054'
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = 'transparent'
            e.target.style.color = '#344054'
          }}
        >
          Tambah Hasil
        </CustomButton>

        <CustomButton
          disabled={isLoading || isSaveDisabled}
          onClick={onUpdateStock}
          block
          style={{
            backgroundColor: '#006386',
            fontSize: 14,
            padding: 10,
          }}
        >
          Simpan
        </CustomButton>
      </div>
      {/* END ADD AND SAVE */}
    </div>
  )
}

export default TransformModal
