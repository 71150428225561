import CheckIcon from 'assets/icons/check-circle-success.svg'
import axios from 'axios'
import { uploadFile } from 'features/private-views/sell-commodity/sellCommodityAction'
import { useEffect, useState } from 'react'
import { AlertCircle, X } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Card, CardBody, CardTitle, Container, Form } from 'reactstrap'
import { apiEndpoint } from '../../../utility/Utils'
import Total from '../Total'
import { setTotal } from '../commodityPurchaseSlice'
import ModalPassword from '../confirmPayment/ModalPassword'
import ModalReceive from '../confirmPayment/ModalReceive'

const ConfirmCashPayment = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const location = useLocation()
  const dataState = location.state
  const { token } = useSelector((e) => e.auth)
  const [userData, setUserData] = useState({})
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [dataProfile, setDataProfile] = useState({})
  const [isOpenModalReceive, setIsOpenModalReceive] = useState(false)
  const [fileNames, setFileNames] = useState([])
  const [files, setFiles] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const toggleModalReceive = () => {
    setIsOpenModalReceive(!isOpenModalReceive)
  }
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const handleSubmit = () => {
    setUserData((prev) => {
      return {
        ...prev,
        proofOfPayment: files.map((e) => e.fileUrl),
        useProofOfPayment: true,
      }
    })
    toggleModal()
  }

  const getProfile = async () => {
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/profile`,
        { headers: { Authorization: `Bearer ${token}` } },
      )
      setDataProfile(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    setUserData(userData)
    getProfile()
    dispatch(setTotal(dataState?.totalAmount))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="w-100 mt-3">
      <ModalPassword
        toggle={toggleModal}
        isOpen={isOpenModal}
        dataProfile={dataProfile}
        userData={userData}
        dataState={dataState}
        isCash
      />
      <ModalReceive
        toggle={toggleModalReceive}
        isOpen={isOpenModalReceive}
        handleSubmit={handleSubmit}
      />
      <Container fluid className="custom-container">
        <p className="fw-600 py-2">Pembayaran tunai atau sudah bayar</p>

        <Card className="p-2">
          <CardBody>
            <div className="mb-5">
              <div className="mb-3 d-flex justify-content-between">
                <span className="fw-bold">Daftar Pembelian</span>
                <>
                  {' '}
                  {userData?.alreadyReceived ? (
                    <div className="text-success-700 rounded bg-success-50 p-1">
                      {' '}
                      <small className="fw-500 mx-1">
                        <img src={CheckIcon} alt="check-icon" /> Barang sudah
                        diterima
                      </small>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </div>
              {dataState?.item?.map((e, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex flex-row gap-3 align-items-center mb-3"
                  >
                    <img
                      src={e?.comodity?.img ?? e?.comodity?.linkFoto}
                      className="rounded float-left"
                      style={{ width: '60px', height: '60px' }}
                      alt="komoditas"
                    />
                    <div className="d-flex flex-column">
                      <span>
                        {e?.comodity?.label} {e?.amount} {e?.unit}
                      </span>
                      <span style={{ fontWeight: 'bold' }}>
                        Rp
                        {intl.formatNumber(
                          dataState?.vat?.enableVAT
                            ? (String(e?.priceAfterVAT)?.includes('.') ||
                              String(e?.priceAfterVAT)?.includes(',')
                                ? Number(
                                    e?.priceAfterVAT
                                      ?.replace?.(/\./g, '')
                                      ?.replace?.(/\,/g, '.')
                                      ?.slice?.(0, 15),
                                  )
                                : e?.priceAfterVAT) *
                                (String(e?.amount)?.includes('.') ||
                                String(e?.amount)?.includes(',')
                                  ? Number(
                                      e?.amount
                                        ?.replace?.(/\./g, '')
                                        ?.replace?.(/\,/g, '.')
                                        ?.slice?.(0, 15),
                                    )
                                  : e?.amount)
                            : (String(e?.price)?.includes('.') ||
                              String(e?.price)?.includes(',')
                                ? Number(
                                    e?.price
                                      ?.replace?.(/\./g, '')
                                      ?.replace?.(/\,/g, '.')
                                      ?.slice?.(0, 15),
                                  )
                                : e?.price) *
                                (String(e?.amount)?.includes('.') ||
                                String(e?.amount)?.includes(',')
                                  ? Number(
                                      e?.amount
                                        ?.replace?.(/\./g, '')
                                        ?.replace?.(/\,/g, '.')
                                        ?.slice?.(0, 15),
                                    )
                                  : e?.amount),
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
            <Card className="border-0 shadow-sm">
              <CardBody>
                <CardTitle tag="h6" className="fw-bold">
                  Ringkasan Pembelian
                </CardTitle>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <span>Harga Komoditas</span>
                    <span>
                      Rp
                      {intl.formatNumber(
                        dataState?.vat?.enableVAT
                          ? dataState?.item?.reduce(
                              (sum, e) => sum + e.priceAfterVAT,
                              0,
                            )
                          : dataState?.totalAmount,
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                    </span>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <span style={{ fontWeight: 'bold' }}>Total Transaksi</span>
                    <span style={{ fontWeight: 'bold' }}>
                      Rp
                      {intl.formatNumber(dataState?.totalAmount, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
        <Card className="mt-4">
          <CardBody>
            <CardTitle style={{ fontWeight: 'bold' }} tag="h6" className="mb-2">
              Bukti pembayaran
            </CardTitle>
            <small>
              Upload bukti pembayaran berupa foto kwitansi atau screenshot
              transfer
            </small>
            <Form
              encType="multipart/form-data"
              className="flex-centered border rounded"
              style={{ overflow: 'hidden' }}
            >
              <fieldset className="fieldset">
                <input
                  className="input-file"
                  id="transactions"
                  name="transactions"
                  onChange={async (e) => {
                    setErrMsg('')
                    let newFiles = e.target.files
                    if (newFiles.length > 3) {
                      setErrMsg('Maksimal 3 file yang dapat diunggah')
                      return
                    }
                    let name = []
                    for (let i = 0; i < e.target.files.length; i++) {
                      name.push({
                        id: i,
                        fileName: e.target.files[i].name,
                      })
                      const file = await dispatch(uploadFile(e.target.files[i]))
                      if (file.error) {
                        setErrMsg(file?.payload)
                      } else {
                        setFiles((prevValues) => {
                          return [
                            ...prevValues,
                            {
                              id: i,
                              fileUrl: file?.payload?.url,
                            },
                          ]
                        })
                      }
                    }
                    setFileNames(name)
                  }}
                  type="file"
                  accept=".png, .jpeg, .jpg"
                  multiple
                />
                <label htmlFor="transactions" className="d-flex">
                  Upload
                </label>
              </fieldset>
              <div className="w-100" style={{ padding: '10px 14px' }}>
                {fileNames?.length > 0
                  ? fileNames?.map((e, i) => (
                      <span className="ms-3 badge-line" key={i}>
                        {e.fileName}
                        <X
                          size={16}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setErrMsg('')
                            const filteredFileNames = fileNames.filter(
                              (file) => file.id !== e.id,
                            )
                            const filteredFiles = files.filter(
                              (file) => file.id !== e.id,
                            )
                            setFileNames(filteredFileNames)
                            setFiles(filteredFiles)
                          }}
                        />
                      </span>
                    ))
                  : 'Belum ada file'}
              </div>
            </Form>
            {errMsg && (
              <small className="text-danger">
                <AlertCircle size={15} /> {errMsg}
              </small>
            )}
          </CardBody>
        </Card>
      </Container>
      <Total
        isDisable={files?.length < 1}
        handleClick={toggleModalReceive}
        text="Selesaikan Transaksi"
      />
    </div>
  )
}

export default ConfirmCashPayment
