import { createApiSlice } from 'app/createApiSlice'

export const dashboardApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'dashboard/hooks',
  endpoints(builder) {
    return {
      fetchSaldo: builder.query({
        query() {
          return `/api/v1/bussines/saldo`
        },
        transformErrorResponse({ data, status }) {
          return {
            data,
            status,
          }
        },
      }),
      fetchTransaction: builder.query({
        query(args) {
          const { type, size, page, status, payment } = args
          return {
            url: '/api/v2/bussines/transaction',
            params: { type, size: size, page, status, payment },
          }
        },
      }),
      fetchProfile: builder.query({
        query() {
          return `/api/v1/bussines/profile`
        },
      }),
      fetchNotif: builder.query({
        query(page = 0) {
          return `/api/v1/bussines/notification?page=${page}`
        },
      }),
      fetchTransactionById: builder.query({
        query({ id }) {
          return {
            url: `/api/v2/bussines/transaction`,
            params: { id },
          }
        },
      }),
      fetchTransactionNote: builder.query({
        query({ type, tradeshortcutId, escrowpaymentId }) {
          const params = { type }

          if (tradeshortcutId) params.tradeshortcutId = tradeshortcutId
          if (escrowpaymentId) params.escrowpaymentId = escrowpaymentId

          return {
            url: `/api/v1/bussines/transaction/note`,
            params,
          }
        },
      }),
      createTransactionNote: builder.mutation({
        query(body) {
          const { tradeshortcutId, escrowpaymentId, buyerNote, sellerNote } =
            body

          const data = {}
          if (tradeshortcutId) {
            data.tradeshortcutId = tradeshortcutId
          } else if (escrowpaymentId) {
            data.escrowpaymentId = escrowpaymentId
          }

          if (buyerNote) data.buyerNote = buyerNote
          if (sellerNote) data.sellerNote = sellerNote

          return {
            url: `/api/v1/bussines/transaction/note`,
            method: 'POST',
            body: data,
          }
        },
      }),
      rejectTransaction: builder.mutation({
        query({ id }) {
          return {
            url: `/api/v1/bussines/transaction/reject/${id}`,
            method: 'POST',
          }
        },
      }),
    }
  },
})

export const {
  useFetchSaldoQuery,
  useFetchTransactionQuery,
  useFetchProfileQuery,
  useFetchNotifQuery,
  useFetchTransactionByIdQuery,
  useFetchTransactionNoteQuery,
  useCreateTransactionNoteMutation,
  useRejectTransactionMutation,
} = dashboardApiSlice
