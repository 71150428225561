import { Card, CardText } from 'reactstrap'
import { formatDateOnlyPMB } from 'utils/formatDate'
import { newFormatThousandSeparator } from 'utils/formatNumber'

const DetailModal = ({ selectedStock }) => {
  return (
    <>
      <div>
        <Card className="basic-card border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Kode inventori</CardText>
          <CardText>{selectedStock?.ownerCode ?? '-'}</CardText>
        </Card>
        <Card className="basic-card border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Komoditas</CardText>
          <CardText>{selectedStock?.item?.nameItem ?? '-'}</CardText>
        </Card>
        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Stok komoditas</CardText>
          <CardText>
            {newFormatThousandSeparator(selectedStock?.amount)}{' '}
            {selectedStock?.unit || selectedStock?.item?.unit}
          </CardText>
        </Card>
        {selectedStock?.userValue?.unitValue !== 0 &&
          selectedStock?.userValue?.unitValue !== null && (
            <Card className="basic-card mt-3 border-0 shadow-sm">
              <CardText className="no-padding fw-semibold">
                Harga Satuan
              </CardText>
              <CardText>
                Rp
                {newFormatThousandSeparator(
                  selectedStock?.userValue?.unitValue,
                )}
              </CardText>
            </Card>
          )}
        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Lokasi</CardText>
          {selectedStock?.district ||
          selectedStock?.subDistrict ||
          selectedStock?.city ||
          selectedStock?.province ? (
            <CardText>
              {selectedStock?.district || selectedStock?.subDistrict}
              {selectedStock?.district || selectedStock?.subDistrict ? ',' : ''}
              {selectedStock?.city || selectedStock?.province}
            </CardText>
          ) : (
            <CardText>-</CardText>
          )}
        </Card>
        {/* <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Gudang</CardText>
          <CardText>{selectedStock?.warehouse?.name}</CardText>
        </Card> */}
        <Card
          className="basic-card mt-3 border-0 shadow-sm d-flex"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <CardText className="no-padding fw-semibold">
              Grup inventori
            </CardText>
            <CardText>{selectedStock?.group?.label ?? '-'}</CardText>
          </div>
        </Card>

        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Foto komoditas</CardText>
          <img
            src={selectedStock?.item?.linkFoto}
            style={{
              width: 60,
              height: 60,
              marginBottom: 5,
              paddingTop: 5,
              borderRadius: 99,
            }}
          />
        </Card>
        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">
            Tanggal pembuatan komoditas
          </CardText>
          <CardText className="fw-semibold">
            {formatDateOnlyPMB(selectedStock?.createdAt)}
          </CardText>
        </Card>
        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">
            Catatan tambahan
          </CardText>
          <CardText className="fw-semibold">
            {selectedStock?.description ? selectedStock?.description : '-'}
          </CardText>
        </Card>
      </div>
    </>
  )
}

export default DetailModal
