import lockIcon from 'assets/icons/lock-icon.png'
import CustomButton from 'components/Buttons/CustomButton'
import useGetTrxTalanginQuery from 'hooks/useGetTrxTalanginQuery'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Eye, EyeOff } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap'
import { postRollover } from './talanginInvoiceAction'
import {
  usePostCheckPassMutation,
  usePostPayTalanginMutation,
} from './talanginInvoiceApiSlice'
import { setJustRolledOver } from './talanginInvoiceSlice'

const ModalPasswordInvoice = ({
  isOpen,
  toggle,
  toggleSidebar,
  row,
  isRollover = false,
  amountToPay,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const talanginTable = useSelector((s) => s.talanginTable)
  const [postCheckPass, result] = usePostCheckPassMutation()
  const { refetch } = useGetTrxTalanginQuery({
    ...talanginTable,
  })
  const [postPayTalangin, resultPay] = usePostPayTalanginMutation()
  const { detailRollover, selectedTenor } = useSelector(
    (s) => s.talanginInvoice,
  )
  const { ownerData } = useSelector((s) => s.dashboard)
  const [inputVisibility, setInputVisibility] = useState(false)

  const handleSubmit = async () => {
    try {
      const res = await postCheckPass({ password: password }).unwrap()
      if (res.success === 1 && res.tempToken) {
        if (isRollover) {
          const res1 = await dispatch(
            postRollover({
              tempToken: res.tempToken,
              loanDays: selectedTenor?.loanDays,
              creditRequested: selectedTenor?.creditRequested2,
              userpinjamanId: detailRollover?.id,
            }),
          )
          if (res1.error) {
            throw res
          } else {
            navigate('/tagihan-talangin')
            refetch()
            toggle()
            dispatch(setJustRolledOver(detailRollover?.id))
            enqueueSnackbar('Perpanjangan tempo pembayaran berhasil.', {
              variant: 'success',
            })
            setPassword('')
          }
        } else {
          const res2 = await postPayTalangin({
            tempToken: res?.tempToken,
            userpinjamanId: row?.id,
            partialAmount: amountToPay,
            phonenumber: ownerData?.phoneNumber,
          }).unwrap()
          if (res2) {
            toggle()
            toggleSidebar()
            refetch()
            enqueueSnackbar('Pembayaran tagihan talangin selesai.', {
              variant: 'success',
            })
            setPassword('')
          }
        }
      }
    } catch (error) {
      enqueueSnackbar(`${error?.payload ?? error?.data?.message}`, {
        variant: 'error',
      })
    }
  }

  const renderIcon = () => {
    const size = 14
    if (inputVisibility === false) {
      return <Eye size={size} />
    } else {
      return <EyeOff size={size} />
    }
  }

  return (
    <>
      <Modal
        className="p-5 w-100"
        isOpen={isOpen}
        toggle={() => {
          toggle()
          setPassword('')
        }}
        centered
      >
        <ModalBody>
          <div className="mb-4 w-100">
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                alt="password"
                src={lockIcon}
                style={{ width: '48px', height: '48px' }}
              />
            </div>
            <h6 className="text-center mb-3" style={{ fontWeight: 'bold' }}>
              Masukkan password untuk melanjutkan
            </h6>
            <FormGroup>
              <Label>Password</Label>
              <InputGroup>
                <Input
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  type={inputVisibility === false ? 'password' : 'text'}
                  value={password}
                />
                <InputGroupText
                  onClick={() => setInputVisibility(!inputVisibility)}
                  style={{ cursor: 'pointer' }}
                >
                  {renderIcon()}
                </InputGroupText>
              </InputGroup>
            </FormGroup>
          </div>
          <div className="d-flex justify-content-between gap-3 w-100">
            <CustomButton
              onClick={() => {
                toggle()
                setPassword('')
              }}
              block
              outline
              color="secondary"
            >
              Batal
            </CustomButton>
            <CustomButton
              onClick={() => {
                handleSubmit()
              }}
              block
              color="primary"
              disabled={!password || result?.isLoading || resultPay?.isLoading}
              type="submit"
            >
              Bayar
            </CustomButton>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalPasswordInvoice
