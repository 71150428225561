import { useSnackbar } from 'notistack'
import { useRef, useState } from 'react'
import { FilePlus } from 'react-feather'
import { Card, CardText, Col, Row, Spinner } from 'reactstrap'
import { useUploadRepoDocMutation } from '../commodityRepoApiSlice'
import CardPDF from '../components/CardPDF'
import RepoCommodityForm from './RepoCommodityForm'

const SubmissionRepo = () => {
  const [upload, { isLoading }] = useUploadRepoDocMutation()
  const { enqueueSnackbar } = useSnackbar()

  const fileInputRef = useRef(null)
  const [fileNames, setFileNames] = useState([])
  const [filesUpload, setFilesUpload] = useState([])
  const [errorMessage, setErrorMessage] = useState([])
  const [invoiceLinks, setInvoiceLinks] = useState([])

  const handleFileChange = async (e) => {
    let tempFiles = []

    if (fileNames.length + e.target.files.length > 3) {
      enqueueSnackbar('Dokumen melebihi batas maksimum unggahan', {
        variant: 'error',
      })
      return
    }

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i]

      const isDuplicate = fileNames.some(
        (existingFile) =>
          existingFile.name === file.name && existingFile.size === file.size,
      )

      if (isDuplicate) {
        enqueueSnackbar(
          `File dengan nama "${file?.name}" sudah diunggah sebelumnya.`,
          {
            variant: 'error',
          },
        )
        continue
      }

      const tempFile = { id: Date.now() + i, name: file.name, size: file.size }
      tempFiles.push({ id: Date.now() + i, file })
      setFileNames((prev) => [...prev, tempFile])

      try {
        let data = await upload(file).unwrap()
        setInvoiceLinks((prev) => [
          ...prev,
          {
            ...data,
            id: tempFile.id,
          },
        ])
        setFilesUpload((prev) => [...prev, tempFile])
      } catch (error) {
        console.error(error)
        enqueueSnackbar(`Gagal mengunggah file ${file.name}: ${error}`, {
          variant: 'error',
        })
      }
    }

    e.target.value = null
  }

  const handleDeleteFile = (index) => {
    setFileNames((prev) => prev.filter((_, i) => i !== index))
    setFilesUpload((prev) => prev.filter((_, i) => i !== index))
    setInvoiceLinks((prev) => prev.filter((_, i) => i !== index))
  }

  return (
    <>
      <Card
        body
        className="mb-3 mt-0 border-0 shadow-sm"
        style={{ padding: 32 }}
      >
        <CardText className="text-md fw-bold">Dokumen Repo</CardText>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="application/pdf"
          disabled={isLoading}
        />
        <div
          className="d-flex flex-column text-wrap w-auto"
          onClick={() =>
            fileNames.length > 2
              ? console.log('')
              : fileInputRef.current.click()
          }
          style={{
            cursor:
              fileNames.length > 2 || isLoading ? 'not-allowed' : 'pointer',
          }}
        >
          <div
            className="border border-1 rounded mb-1"
            style={{ padding: '12px 20px' }}
          >
            <FilePlus
              size={18}
              className="me-2"
              style={{
                color:
                  fileNames.length > 2 || isLoading ? '#D0D5DD' : '#101828',
              }}
            />
            <span
              className="text-sm fw-bold"
              style={{
                color:
                  fileNames.length > 2 || isLoading ? '#D0D5DD' : '#101828',
              }}
            >
              {isLoading ? (
                <>
                  <Spinner size="sm" className="ms-2" /> Uploading ...
                </>
              ) : (
                'Unggah Dokumen PO, Invoice, Atau Yang Berkaitan Dengan Repo'
              )}
            </span>
          </div>
          <span className="text-sm">
            Jenis file yang bisa berupa PDF. Maksimal 3 dokumen
          </span>
        </div>

        <Row>
          {fileNames?.map((data, i) => {
            return (
              <Col key={i} lg={4} md={6} sm={12} className="my-1">
                <CardPDF
                  name={data?.name}
                  callBack={() => handleDeleteFile(i)}
                  fileIndex={data?.id}
                />
              </Col>
            )
          })}
        </Row>
      </Card>
      <RepoCommodityForm invoiceLinks={invoiceLinks} />
    </>
  )
}

export default SubmissionRepo
