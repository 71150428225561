import IPhoto from 'assets/icons/Pak-Tani.png'
import 'assets/scss/flatpickr/flatpickr.scss'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import PMBPagination from 'components/PMBPagination'
import PMBSelect from 'components/PMBSelect'
import PMBTabSwitcher from 'components/PMBTabSwitcher'
import { TableComponent as DataTable } from 'components/TableComponent'
import { Can } from 'context/Acl'
import { getWarehouse } from 'features/public-views/manageStock/API/manageStockAction'
import { getActionWording } from 'helpers'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { Calendar, ChevronDown, Eye, Send } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, Col, Spinner } from 'reactstrap'
import { formatDatePMB } from 'utils/formatDate'
import { URL_FORM_TRANSFER_INVENTORY } from 'utils/pathUrl'
import { BUSINESS_TRANSFER_INVENTORY, MANAGE } from 'utils/subjectActions'
import SidebarTfInventory from './SidebarTfInventory'
import { useFetchInventoryTransferQuery } from './transferInventoryApiSlice'
import { setFetchOption, toggleSidebar } from './transferInventorySlice'

const TransferInventory = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { tag } = useSelector((s) => s.transferInventory)
  const { profile } = useSelector((s) => s.dashboard)
  let [page, setPage] = useState({ page: tag.page })
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null)
  const [activeTab, setActiveTab] = useState('in')
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('desc')
  const date = new Date()
  const y = date.getFullYear()
  const m = date.getMonth()
  const firstDay = new Date(y, m, 1)
  const lastDay = new Date(y, m, date.getDate())
  const savedFilters =
    JSON?.parse(localStorage?.getItem('transferInventoryFilters')) || {}
  const initialDate = savedFilters?.selectedDate
    ? savedFilters.selectedDate.map((d) => new Date(d))
    : [firstDay, lastDay]
  const [selectedDate, setSelectedDate] = useState(initialDate)

  const { warehouses } = useSelector((s) => s.manageStock)

  const { data, isLoading, isFetching, refetch } =
    useFetchInventoryTransferQuery(
      {
        page: currentPage ?? 0,
        size: rowsPerPage ?? 10,
        sortby: sortColumn,
        sortdir: sortDirection,
        type: activeTab,
        ...(tag?.receiverwarehouseId !== undefined && {
          receiverwarehouseId: tag?.receiverwarehouseId,
        }),
        ...(tag?.senderwarehouseId !== undefined && {
          senderwarehouseId: tag?.senderwarehouseId,
        }),
        ...(tag?.status && {
          status: tag?.status,
        }),
        start: moment(selectedDate[0]).format('YYYY-MM-DD'),
        end: moment(selectedDate[1]).format('YYYY-MM-DD'),
      },
      { skip: !page },
    )

  const statusOptions = [
    { label: 'Semua Status', value: 1 },
    {
      label: 'Menunggu konfirmasi penerima',
      value: 2,
    },
    { label: 'Diterima', value: 3 },
    { label: 'Ditolak', value: 4 },
  ]

  const columns = [
    {
      name: <span>Tanggal</span>,
      sortable: true,
      sortField: 'createdAt',
      cell: (row) => formatDatePMB(row?.createdAt, row?.createdAt),
    },
    {
      name: <span>Nama Inventori</span>,
      grow: 2,
      cell: (row) => (
        <div className="d-flex flex-column py-2">
          <span className="flex-row">
            {row?.labelOuter ??
              row?.warehousetransferitem?.[0]?.label ??
              row?.warehousetransferitem?.[0]?.inventory?.item?.nameItem}
          </span>
          {!row?.labelOuter && (
            <span className="text-primary">
              {intl.formatNumber(
                row?.warehousetransferitem?.[0]?.amount ??
                  row?.warehousetransferitem?.[0]?.inventory?.amount,
                {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                },
              )}{' '}
              {row?.warehousetransferitem?.[0]?.unit ??
                row?.warehousetransferitem?.[0]?.inventory?.unit}
            </span>
          )}

          {row?.labelOuter ? (
            <>
              {row?.warehousetransferitem?.length && (
                <div className="bg-gray-100 badge rounded-pill my-1">
                  <span className="text-dark fw-medium">
                    +{' '}
                    {intl.formatNumber(row?.warehousetransferitem?.length, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}{' '}
                    inventori lainnya
                  </span>
                </div>
              )}
            </>
          ) : (
            <>
              {row?.warehousetransferitem?.length > 1 && (
                <div className="bg-gray-100 badge rounded-pill my-1">
                  <span className="text-dark fw-medium">
                    +{' '}
                    {intl.formatNumber(row?.warehousetransferitem?.length - 1, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}{' '}
                    inventori lainnya
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      name: <span>Gudang Pengirim</span>,
      grow: 2,
      cell: (row) => {
        return (
          <div
            className="d-flex flex-column gap-1 py-2"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <span>{row?.warehousesender?.name}</span>
            {row?.sender?.name ? (
              <div className="d-flex gap-1 align-items-center">
                <img
                  src={row?.sender?.profilePic ?? IPhoto}
                  className={`rounded-circle ${profile?.userId === row?.sender?.userId ? 'border border-2 border-success' : ''}`}
                  alt="Pengirim"
                  width={20}
                  height={20}
                />
                <span className="text-gray-500">{row?.sender?.name}</span>
              </div>
            ) : (
              <div style={{ minHeight: '24px' }}></div>
            )}
          </div>
        )
      },
    },
    {
      name: <span>Gudang Tujuan</span>,
      grow: 2,
      sortable: true,
      sortField: 'warehousereceiver.name',
      cell: (row) => {
        return (
          <div
            className="d-flex flex-column gap-1 py-2"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <span>{row?.warehousereceiver?.name}</span>
            {row?.receiver?.name ? (
              <div className="d-flex gap-1 align-items-center">
                <img
                  src={row?.receiver?.profilePic ?? IPhoto}
                  className={`rounded-circle ${profile?.userId === row?.receiver?.userId ? 'border border-2 border-success' : ''}`}
                  alt="Penerima"
                  width={20}
                  height={20}
                />
                <span className="text-gray-500">{row?.receiver?.name}</span>
              </div>
            ) : (
              <div style={{ minHeight: '24px' }}></div>
            )}
          </div>
        )
      },
    },
    {
      name: <span>Status</span>,
      grow: 1,
      cell: (row) => {
        if (row?.approvedAt) {
          return (
            <div className="badge rounded-pill bg-success-50 text-success-700 px-2">
              Diterima
            </div>
          )
        } else if (row?.rejectedAt) {
          return (
            <div className="badge rounded-pill bg-danger-50 text-danger-700 px-2">
              Ditolak
            </div>
          )
        } else {
          return (
            <div className="badge rounded-pill bg-warning-50 text-warning-700 px-2">
              Menunggu konfirmasi penerima
            </div>
          )
        }
      },
    },
    {
      name: <span>Keterangan</span>,
      minWidth: '200px',
      cell: (row) => {
        return (
          <div
            className="bg-gray-100 badge rounded-pill px-2"
            style={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              textAlign: 'center',
              maxWidth: '100%',
            }}
          >
            <span className="text-dark fw-medium text-capitalize">
              {getActionWording(row?.action, row)}
            </span>
          </div>
        )
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-3">
            <Eye
              size={20}
              color="#475467"
              className="button"
              onClick={() => {
                dispatch(toggleSidebar())
                setSelectedRow(row)
              }}
            />
          </div>
        )
      },
    },
  ]

  const handleSort = (column, direction) => {
    setSortColumn(column.sortField)
    setSortDirection(direction === 'asc' ? 'desc' : 'asc')
  }

  const handleCallback = () => {
    refetch({})
  }

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey)
    setCurrentPage(0)
    setRowsPerPage(10)
    dispatch(
      setFetchOption({
        page: 0,
        sortby: sortColumn,
        sortdir: sortDirection,
        type: tabKey,
        size: tag?.size,
      }),
    )
  }

  const handleFilterChange = (value, type) => {
    dispatch(
      setFetchOption({
        page: 0,
        [type]: value,
      }),
    )
  }

  const tabs = [
    { key: 'in', label: 'Inventori Masuk' },
    { key: 'out', label: 'Inventori Keluar' },
  ]

  useEffect(() => {
    dispatch(getWarehouse({ isForFilter: true, isAdd: true }))
  }, [])

  useEffect(() => {
    if (savedFilters) {
      const { selectedDate, senderwarehouseId, receiverwarehouseId, status } =
        savedFilters

      const isSenderWarehouseValid = warehouses?.newData?.some(
        (w) => w?.id === senderwarehouseId,
      )
      const isReceiverWarehouseValid = warehouses?.newData?.some(
        (w) => w?.id === receiverwarehouseId,
      )

      setSelectedDate(
        selectedDate?.map((date) => new Date(date)) || [firstDay, lastDay],
      )

      dispatch(
        setFetchOption({
          senderwarehouseId: isSenderWarehouseValid
            ? senderwarehouseId
            : undefined,
          receiverwarehouseId: isReceiverWarehouseValid
            ? receiverwarehouseId
            : undefined,
          status: status || 1,
        }),
      )
    }
  }, [warehouses])

  useEffect(() => {
    const filtersToSave = {
      selectedDate: selectedDate?.map((d) => d?.toISOString()),
      senderwarehouseId: tag?.senderwarehouseId,
      receiverwarehouseId: tag?.receiverwarehouseId,
      status: tag?.status,
    }
    localStorage.setItem(
      'transferInventoryFilters',
      JSON?.stringify(filtersToSave),
    )
  }, [selectedDate, tag.senderwarehouseId, tag.receiverwarehouseId, tag.status])

  useEffect(() => {
    refetch()
  }, [activeTab, refetch])

  return (
    <div className="w-100 px-3">
      <SidebarTfInventory data={selectedRow} handleCallback={handleCallback} />
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs breadCrumbActive={'Transfer Inventori'} />
      </div>
      <div className="d-flex flex-column gap-2 mb-2">
        <Card className="border-0 shadow-sm">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5>Transfer Inventori</h5>
              <Can I={MANAGE} this={BUSINESS_TRANSFER_INVENTORY}>
                <CustomButton
                  onClick={() => navigate(URL_FORM_TRANSFER_INVENTORY)}
                  color="primary"
                >
                  <Send size={15} /> Kirim Inventori
                </CustomButton>
              </Can>
            </div>
            <div className="my-4">
              <PMBTabSwitcher
                activeTab={activeTab}
                tabs={tabs}
                onTabChange={handleTabChange}
              />
            </div>
            <DataTable
              columns={columns}
              data={data?.items}
              progressPending={isLoading || isFetching}
              persistTableHead
              sortServer
              defaultSortFieldId={1}
              defaultSortAsc={false}
              progressComponent={<Spinner />}
              sortIcon={<ChevronDown />}
              onSort={handleSort}
              pagination
              paginationServer
              paginationComponent={(props) => (
                <PMBPagination
                  rowCount={data?.totalItems || 0}
                  currentPage={currentPage}
                  rowsPerPage={rowsPerPage}
                  setCurrentPage={setCurrentPage}
                  setRowsPerPage={setRowsPerPage}
                  props={props}
                />
              )}
              subHeader
              subHeaderComponent={
                <div className="w-100 d-flex flex-wrap flex-md-row flex-column">
                  {/* Tanggal */}
                  <Col className="mb-3 me-3" style={{ maxWidth: '300px' }}>
                    <p className="mb-2">Tanggal</p>
                    <div
                      className="d-flex align-items-center justify-content-center border rounded"
                      style={{
                        padding: '2px 6px',
                      }}
                    >
                      <Calendar size={17} className="ms-3" />
                      <Flatpickr
                        options={{
                          mode: 'range',
                          dateFormat: 'd-m-Y',
                          defaultDate: selectedDate,
                          onClose: (dates) => {
                            setSelectedDate(dates)
                            dispatch(
                              setFetchOption({
                                start: moment(dates[0]).format('YYYY-MM-DD'),
                                end: moment(dates[1]).format('YYYY-MM-DD'),
                              }),
                            )
                          },
                        }}
                        className="form-control flat-picker bg-transparent border-0 shadow-none"
                      />
                    </div>
                  </Col>

                  {/* Gudang Pengirim */}
                  <Col className="mb-3 me-3" style={{ maxWidth: '300px' }}>
                    <p className="mb-2">Gudang Pengirim</p>
                    <PMBSelect
                      style={{ width: '100%' }}
                      placeholder="Semua Gudang"
                      options={warehouses?.newData}
                      onChange={(e) =>
                        handleFilterChange(e?.id, 'senderwarehouseId')
                      }
                      value={warehouses?.newData?.find(
                        (e) => e.id === tag.senderwarehouseId,
                      )}
                    />
                  </Col>

                  {/* Gudang Tujuan */}
                  <Col className="mb-3 me-3" style={{ maxWidth: '300px' }}>
                    <p className="mb-2">Gudang Tujuan</p>
                    <PMBSelect
                      style={{ width: '100%' }}
                      placeholder="Semua Gudang"
                      options={warehouses?.newData}
                      onChange={(e) =>
                        handleFilterChange(e?.id, 'receiverwarehouseId')
                      }
                      value={warehouses?.newData?.find(
                        (e) => e.id === tag.receiverwarehouseId,
                      )}
                    />
                  </Col>

                  {/* Status */}
                  <Col className="mb-3" style={{ maxWidth: '300px' }}>
                    <p className="mb-2">Status</p>
                    <PMBSelect
                      style={{ width: '100%' }}
                      placeholder="Semua Status"
                      options={statusOptions}
                      onChange={(e) => handleFilterChange(e?.value, 'status')}
                      value={statusOptions?.find((e) => e.value === tag.status)}
                    />
                  </Col>
                </div>
              }
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default TransferInventory
