import { ReactComponent as IcBtn } from 'assets/icons/ic-btn-tukar-uang-usaha.svg'
import CustomButton from 'components/Buttons/CustomButton'
import { FolderMinus } from 'react-feather'
import { Card, CardText, UncontrolledTooltip } from 'reactstrap'
import { formatDateOnlyPMB } from 'utils/formatDate'
import { newFormatThousandSeparator } from 'utils/formatNumber'

export default function DetailModal({
  selectedStock,
  onSetGroup,
  onMoveOutGroup,
  onMovePack,
}) {
  return (
    <>
      <div>
        <Card className="basic-card border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Kode inventori</CardText>
          <CardText>{selectedStock?.ownerCode ?? '-'}</CardText>
        </Card>
        <Card className="basic-card border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Komoditas</CardText>
          <CardText>{selectedStock?.item?.nameItem ?? '-'}</CardText>
        </Card>
        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Stok komoditas</CardText>
          <CardText>
            {newFormatThousandSeparator(selectedStock?.amount)}{' '}
            {selectedStock?.unit || selectedStock?.item?.unit}
          </CardText>
        </Card>
        {selectedStock?.userValue?.unitValue !== 0 &&
          selectedStock?.userValue?.unitValue !== null && (
            <Card className="basic-card mt-3 border-0 shadow-sm">
              <CardText className="no-padding fw-semibold">
                Harga Satuan
              </CardText>
              <CardText>
                Rp
                {newFormatThousandSeparator(
                  selectedStock?.userValue?.unitValue,
                )}
              </CardText>
            </Card>
          )}
        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Lokasi</CardText>
          {selectedStock?.district ||
          selectedStock?.subDistrict ||
          selectedStock?.city ||
          selectedStock?.province ? (
            <CardText>
              {selectedStock?.district || selectedStock?.subDistrict}
              {selectedStock?.district || selectedStock?.subDistrict ? ',' : ''}
              {selectedStock?.city || selectedStock?.province}
            </CardText>
          ) : (
            <CardText>-</CardText>
          )}
        </Card>
        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Gudang</CardText>
          <CardText>{selectedStock?.warehouse?.name}</CardText>
        </Card>
        <Card
          className="basic-card mt-3 border-0 shadow-sm d-flex"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <CardText className="no-padding fw-semibold">
              Grup inventori
            </CardText>
            <CardText>{selectedStock?.group?.label ?? '-'}</CardText>
          </div>
          <div>
            {selectedStock?.group?.label ? (
              <>
                <CustomButton
                  className="btn-icon ml-1"
                  size="sm"
                  outline
                  color="danger"
                  id="deleteBtn"
                  onClick={onMoveOutGroup}
                >
                  <FolderMinus size={14} />
                </CustomButton>
                <UncontrolledTooltip placement="bottom" target="deleteBtn">
                  Keluarkan Inventori
                </UncontrolledTooltip>
                <CustomButton
                  className="btn-icon ml-1"
                  size="sm"
                  outline
                  id="moveBtn"
                  color="primary"
                  style={{ marginLeft: 10 }}
                  onClick={onMovePack}
                >
                  <IcBtn width={15} height={15} />
                </CustomButton>
                <UncontrolledTooltip placement="bottom" target="moveBtn">
                  Pindahkan
                </UncontrolledTooltip>
              </>
            ) : (
              <CustomButton
                onClick={() => {
                  onSetGroup()
                }}
                size="sm"
                color="primary"
                style={{
                  borderRadius: 8,
                  paddingRight: 18,
                  paddingLeft: 18,
                }}
              >
                + Grupkan
              </CustomButton>
            )}
          </div>
        </Card>

        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">Foto komoditas</CardText>
          <img
            src={selectedStock?.item?.linkFoto}
            style={{
              width: 60,
              height: 60,
              marginBottom: 5,
              paddingTop: 5,
              borderRadius: 99,
            }}
          />
        </Card>
        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">
            Tanggal pembuatan komoditas
          </CardText>
          <CardText className="fw-semibold">
            {formatDateOnlyPMB(selectedStock?.createdAt)}
          </CardText>
        </Card>
        <Card className="basic-card mt-3 border-0 shadow-sm">
          <CardText className="no-padding fw-semibold">
            Catatan tambahan
          </CardText>
          <CardText className="fw-semibold">
            {selectedStock?.description ? selectedStock?.description : '-'}
          </CardText>
        </Card>
      </div>
    </>
  )
}
