import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  filters: {
    selectedYearAnnual: null,
    selectedMonthYear: new Date(),
  },
}

export const insightListSlice = createAppSlice({
  name: 'insightList',
  initialState,
  reducers: (create) => ({
    setFilters: create.reducer((state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
    }),
  }),
})

export const { setFilters } = insightListSlice.actions

export default insightListSlice.reducer
