import CardPDF from 'features/private-views/commodity-repo/components/CardPDF'
import { useSnackbar } from 'notistack'
import { useRef, useState } from 'react'
import { FilePlus } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import { uploadFile } from '../sellCommodityAction'
import {
  setOrdersCustomTrx,
  setOrdersNote,
  setRemoveDoc,
} from '../sellCommoditySlice'
import './style.scss'

const InfoDocument = () => {
  const [fileNames, setFileNames] = useState([])
  const fileInputRef = useRef(null)
  const dispatch = useDispatch()
  const { orders } = useSelector((s) => s.sellCommodity)
  const [isLoading, setIsLoading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const handleFileChange = async (e) => {
    setIsLoading(true)
    const newFiles = []

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i]
      const tempFile = { id: Date.now() + i, fileName: file.name }

      // CHECK FOR DUPLICATE FILE NAME
      const isDuplicate = fileNames.some(
        (existingFile) =>
          existingFile.fileName === file.name &&
          existingFile.fileSize === file.size,
      )
      if (isDuplicate) {
        enqueueSnackbar(
          `File dengan nama "${file?.name}" sudah diunggah sebelumnya.`,
          {
            variant: 'error',
          },
        )
        continue
      }
      // END CHECK FOR DUPLICATE FILE NAME

      try {
        await dispatch(uploadFile(file)).unwrap()
        newFiles.push(tempFile)
      } catch (error) {
        enqueueSnackbar(`Gagal mengunggah file ${file.name}: ${error}`, {
          variant: 'error',
        })
      }
    }

    setFileNames((prev) => [...prev, ...newFiles])
    setIsLoading(false)
    e.target.value = null
  }

  return (
    <Card className="border-0 shadow-sm">
      <CardBody className="p-4">
        <Row>
          <Col sm="12">
            <CardTitle tag="h5">Informasi Lainnya</CardTitle>
            <FormGroup className="pt-3">
              <Label>Nomor transaksi (opsional)</Label>
              <Input
                id="customNoTrx"
                name="customNoTrx"
                placeholder="Masukkan nomor transaksi"
                value={orders?.order?.transaction_number}
                onChange={(e) => dispatch(setOrdersCustomTrx(e.target.value))}
              />
              <small className="text-muted">
                Silakan tambahkan jika Anda memiliki penomoran transaksi sendiri
              </small>
            </FormGroup>
            <FormGroup>
              <Label>Catatan ke pembeli (opsional)</Label>
              <Input
                id="notes"
                name="notes"
                placeholder="Masukkan catatan jika ada"
                type="textarea"
                style={{ minHeight: '128px' }}
                value={orders?.order?.notes}
                onChange={(e) => dispatch(setOrdersNote(e.target.value))}
              />
            </FormGroup>
            <Label>Dokumen tambahan (opsional)</Label>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept=".png, .jpeg, .jpg, .pdf, .xlsx, .xls, .doc, .docx"
              multiple
              disabled={isLoading}
            />
            <div
              className="d-flex flex-column text-wrap w-auto"
              onClick={() => fileInputRef.current.click()}
              style={{
                cursor: isLoading ? 'not-allowed' : 'pointer',
              }}
            >
              <div
                className="border border-1 rounded mb-1"
                style={{ padding: '12px 20px' }}
              >
                <FilePlus
                  size={18}
                  className="me-2"
                  style={{
                    color: isLoading ? '#D0D5DD' : '#101828',
                  }}
                />
                <span
                  className="text-sm fw-bold"
                  style={{
                    color: isLoading ? '#D0D5DD' : '#101828',
                  }}
                >
                  {isLoading ? (
                    <>
                      <Spinner size="sm" className="ms-2" /> Uploading ...
                    </>
                  ) : (
                    'Unggah Dokumen'
                  )}
                </span>
              </div>
              <span className="text-sm text-muted">
                Jenis file yang bisa berupa .png, .jpeg, .jpg, .pdf, .xlsx,
                .xls, .doc, .docx
              </span>
            </div>

            {/* SHOWING UPLOADED IMAGE */}
            <Row className="mt-4">
              {fileNames?.map((data, index) => {
                return (
                  <Col key={index} lg={4} md={6} sm={12} className="my-1">
                    <CardPDF
                      name={data?.fileName}
                      callBack={() => {
                        const updatedFileNames = fileNames.filter(
                          (_, i) => i !== index,
                        )
                        setFileNames(updatedFileNames)
                        dispatch(setRemoveDoc({ id: index }))
                      }}
                      fileIndex={index}
                    />
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default InfoDocument
