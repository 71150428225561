import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'

const getInventoryMembers = createAsyncThunk(
  'manage/inventoryMembers',
  async (
    { page, size, search, sortBy, asc, groupId, warehouseId, excludeId, all },
    { rejectWithValue, getState },
  ) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/inventorymembers?page=${page}&size=${size ?? 10}`
      if (search) {
        url += `&search=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy}&sortdir=${asc}`
      }
      // if (warehouseId) {
      //   url += `&warehouseId=${warehouseId}`
      // }
      if (groupId) {
        url += `&groupId=${groupId}`
      }
      if (excludeId) {
        url += `&excludeId=${excludeId}`
      }
      if (all) {
        url += `&all=${all}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getPackingDetail = createAsyncThunk(
  'manage/packingDetailMembers',
  async ({ page, search, sortBy, asc, id }, { rejectWithValue, getState }) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/inventorygroupsmembers/${id}?page=${page}&size=10`
      if (search) {
        url += `&search=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy}&sortdir=${asc}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getGroupInventory = createAsyncThunk(
  'manage/groupInventoryMembers',
  async (
    { page, size, search, sortBy, asc, warehouseId },
    { rejectWithValue, getState },
  ) => {
    try {
      let url = `${apiEndpoint}/api/v1/bussines/inventorygroupsmembers?page=${page}&size=${size ?? 10}`
      if (search) {
        url += `&s=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy}&sortdir=${asc}`
      }
      if (warehouseId) {
        url += `&warehouseId=${warehouseId}`
      }

      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(url, config)

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const fetchInventoryGroups = createAsyncThunk(
  'manage/fetchInventoryGroupsMembers',
  async (
    { page = 0, size, search, sortBy, asc, parentGroupId },
    { rejectWithValue, getState },
  ) => {
    try {
      const { auth } = getState()
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      let url = `${apiEndpoint}/api/v1/bussines/inventorygroupsmembers?page=${page}&size=${size ?? 10}`

      if (search) {
        url += `&s=${search}`
      }
      if (sortBy) {
        url += `&sortby=${sortBy ?? 'updatedAt'}&sortdir=${asc ?? 'desc'}`
      }
      if (parentGroupId) {
        url += `&parentGroupId=${parentGroupId}`
      }

      const { data } = await axios.get(url, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export {
  fetchInventoryGroups,
  getGroupInventory,
  getInventoryMembers,
  getPackingDetail,
}
