import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { setOrdersCustomTrx, setOrdersNote } from '../purchaseCommoditySlice'

const NoteInfo = () => {
  const dispatch = useDispatch()
  const { orders } = useSelector((state) => state.purchaseCommmodity)

  const handleChange = (e) => {
    dispatch(setOrdersNote(e.target.value))
  }

  const handleChangeCustomNumber = (e) => {
    dispatch(setOrdersCustomTrx(e.target.value))
  }

  return (
    <Card body className="custom-container m-0 m-auto border-0 shadow-sm">
      <CardBody className="p-4">
        <Row>
          <Col>
            <CardTitle tag="h5" className="mb-4">
              Informasi Lainnya
            </CardTitle>
            <FormGroup>
              <Label>Nomor transaksi (opsional)</Label>
              <Input
                id="customNoTrx"
                name="customNoTrx"
                placeholder="Masukkan nomor transaksi"
                value={orders?.order?.transaction_number}
                onChange={(e) => handleChangeCustomNumber(e)}
              />
              <small className="text-muted">
                Silakan tambahkan jika Anda memiliki penomoran transaksi sendiri
              </small>
            </FormGroup>
            <FormGroup>
              <Label>Catatan ke penjual (opsional)</Label>
              <Input
                id="notes"
                name="notes"
                placeholder="Masukkan catatan jika ada"
                type="textarea"
                style={{ minHeight: '128px' }}
                value={orders?.order?.notes}
                onChange={(e) => handleChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default NoteInfo
