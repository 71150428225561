import { createAppSlice } from 'app/createAppSlice'
import {
  fetchInventoryGroups,
  getGroupInventory,
  getPackingDetail,
} from './manageStockAction'

export const initialState = {
  loading: false,
  groupsInventory: null,
  detail: null,
  warehouses: null,
  warehousesAdd: null,
  defaultWarehouse: null,
  itemsInventory: [],
}

export const inventoryMembersSlice = createAppSlice({
  name: 'inventoryMembers',
  initialState,
  reducers: (create) => ({}),
  extraReducers: (builder) => {
    builder
      .addCase(getGroupInventory.pending, (state) => {
        state.loading = true
      })
      .addCase(getGroupInventory.fulfilled, (state, { payload }) => {
        state.loading = false
        state.groupsInventory = payload
      })
      .addCase(getGroupInventory.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getPackingDetail.pending, (state) => {
        state.loading = true
      })
      .addCase(getPackingDetail.fulfilled, (state, { payload }) => {
        state.loading = false
        state.detail = payload
      })
      .addCase(getPackingDetail.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(fetchInventoryGroups.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchInventoryGroups.fulfilled, (state, action) => {
        state.loading = false
        state.groupsInventory = action.payload
      })
      .addCase(fetchInventoryGroups.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  },
})
export const {
  resetAlert,
  addRole,
  logout,
  login,
  loginLoading,
  loginReject,
  toggleAlert,
  setAlertMsg,
  setInitAlert,
  toggleModal,
  setLoading,
  setToken,
} = inventoryMembersSlice.actions
export default inventoryMembersSlice.reducer
