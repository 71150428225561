import ICDefaultPhoto from 'assets/icons/default-ava-profile-user.svg'
import ICPhone from 'assets/icons/ic-mobile.png'
import ICUangBelanja from 'assets/icons/uang-belanja-wallet.png'
import ICTalangin from 'assets/icons/uang-usaha.png'
import { Edit2, Plus } from 'react-feather'

import { Button, Card, CardBody, Col, Row } from 'reactstrap'

const TransactionInformation = ({
  transaction,
  transactionNoteData,
  isBuyer,
  isCreator,
  ownerUserId,
  sellerUserId,
  buyerUserId,
  toggleNoteModal,
  setIsNoteModalOpenEdit,
}) => {
  return (
    <Card className="shadow-sm border-0 mb-3">
      <CardBody>
        <h6 className="fw-semibold mb-3">Informasi transaksi</h6>
        {transaction?.orderDetail?.poNumber && (
          <Row className="mb-2">
            <Col xs="6" className="fw-light text-sm">
              No Transaksi
            </Col>
            <Col xs="6" className="fw-semibold text-sm">
              {transaction?.orderDetail?.poNumber ?? '-'}
            </Col>
          </Row>
        )}
        {transaction?.orderDetail?.transaction_number && (
          <Row className="mb-2">
            <Col xs="6" className="fw-light text-sm">
              No Transaksi (custom)
            </Col>
            <Col
              xs="6"
              className="fw-semibold text-sm"
              style={{
                wordBreak: 'break-word',
              }}
            >
              {transaction?.orderDetail?.transaction_number ?? '-'}
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <Col xs="6" className="fw-light text-sm">
            {isBuyer ? 'Penjual' : 'Pembeli'}
          </Col>
          <Col xs="6" className="fw-semibold text-sm">
            {isBuyer ? (
              <div className="d-flex gap-2 align-items-center">
                <img
                  src={transaction?.sellerOrBuyAvatar ?? ICDefaultPhoto}
                  className="rounded-circle"
                  alt="pak tani"
                  width={20}
                  height={20}
                />
                <span
                  className="text-capitalize"
                  style={{
                    wordBreak: 'break-word',
                  }}
                >
                  {transaction?.sellerDetail?.fullName}
                </span>
              </div>
            ) : (
              <div className="d-flex gap-2 align-items-center">
                <img
                  src={transaction?.sellerOrBuyAvatar ?? ICDefaultPhoto}
                  className="rounded-circle"
                  alt="pak tani"
                  width={20}
                  height={20}
                />
                <span className="text-capitalize">
                  {transaction?.buyerDetail?.fullName}
                </span>
              </div>
            )}
          </Col>
        </Row>
        {(ownerUserId === buyerUserId || ownerUserId === sellerUserId) &&
          isCreator && (
            <Row className="mb-2">
              <Col xs="6" className="fw-light text-sm">
                Dibuat oleh
              </Col>
              <Col xs="6" className="fw-semibold text-sm">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={transaction?.requesterAvatar ?? ICDefaultPhoto}
                    alt="ava"
                    width={20}
                    height={20}
                    className="rounded-circle"
                  />
                  <span
                    style={{
                      wordBreak: 'break-word',
                    }}
                  >
                    {transaction?.requesterName
                      ? transaction?.requesterName
                      : isBuyer
                        ? transaction?.buyerDetail?.fullName
                        : transaction?.sellerDetail?.fullName}
                  </span>

                  {!transaction?.orderDetail?.source && (
                    <img src={ICPhone} alt="Mobile" width={24} height={24} />
                  )}
                </div>
              </Col>
            </Row>
          )}
        <Row className="mb-2">
          <Col xs="6" className="fw-light text-sm">
            Status
          </Col>
          <Col xs="6" className="text-sm">
            {(() => {
              const statusMap = {
                selesai: 'bg-success-50 text-success',
                'menunggu proses penjual': 'bg-warning-50 text-warning-700',
                'menunggu approval admin': 'bg-warning-50 text-warning-700',
                diproses: 'bg-primary-50 text-primary',
                dibatalkan: 'bg-danger-50 text-danger-700',
                'menunggu pembayaran pembeli': 'bg-warning-50 text-warning-700',
              }

              const status = transaction?.status?.toLowerCase()
              const badgeClass =
                statusMap[status] || 'bg-secondary-50 text-secondary'

              return (
                <div
                  className={`badge rounded-pill ${badgeClass} px-2`}
                  style={{
                    wordBreak: 'break-word',
                  }}
                >
                  {transaction?.status || '-'}
                </div>
              )
            })()}
          </Col>
        </Row>

        <Row className="mb-2">
          <Col xs="6" className="fw-light text-sm">
            Metode Pembayaran
          </Col>
          <Col xs="6" className="text-sm d-flex justify-content-start">
            <div
              className={`badge rounded-pill ${
                !transaction?.creditRequested
                  ? 'bg-primary-50'
                  : 'bg-warning-50'
              } d-flex align-items-center gap-1 px-2`}
              style={{ width: 'auto', display: 'inline-flex' }}
            >
              <img
                width={12}
                height={12}
                src={!transaction?.creditRequested ? ICUangBelanja : ICTalangin}
                alt={
                  !transaction?.creditRequested ? 'Uang Belanja' : 'Talangin'
                }
              />
              <span
                className={`${
                  !transaction?.creditRequested ? 'text-primary' : 'text-dark'
                }`}
                style={{
                  wordBreak: 'break-word',
                }}
              >
                {!transaction?.creditRequested ? 'Uang Belanja' : 'Talangin'}
              </span>
            </div>
          </Col>
        </Row>

        <Row className="mb-2 text-sm">
          <Col xs="6" className="fw-light">
            Catatan ke penjual
          </Col>
          <Col
            xs="6"
            className="fw-semibold text-sm"
            style={{
              wordBreak: 'break-word',
            }}
          >
            {transaction?.orderDetail?.notes ??
              transaction?.orderDetail?.note ??
              '-'}
          </Col>
        </Row>

        <Row className="mb-2 text-sm">
          <Col xs="6" className="fw-light">
            Catatan transaksi{' '}
            {!transactionNoteData?.disabled &&
              (transactionNoteData?.buyerNote !== null ||
                transactionNoteData?.sellerNote !== null) && (
                <Edit2
                  size={12}
                  cursor="pointer"
                  onClick={() => {
                    toggleNoteModal()
                    setIsNoteModalOpenEdit(true)
                  }}
                />
              )}
          </Col>
          <Col
            xs="6"
            className="fw-semibold text-sm"
            style={{
              wordBreak: 'break-word',
            }}
          >
            {!transactionNoteData?.buyerNoteHide
              ? (transactionNoteData?.buyerNote ?? '-')
              : (transactionNoteData?.sellerNote ?? '-')}
          </Col>
        </Row>

        {!transactionNoteData?.disabled &&
          transactionNoteData?.buyerNote === null &&
          transactionNoteData?.sellerNote === null && (
            <Button
              outline
              color="primary"
              size="sm"
              className="w-100 mt-3"
              onClick={toggleNoteModal}
            >
              <Plus size={16} className="me-2" /> Tambah Catatan Transaksi
            </Button>
          )}
      </CardBody>
    </Card>
  )
}

export default TransactionInformation
