import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'

const getBalance = createAsyncThunk(
  'dashboard/balance',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/saldo`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getProfile = createAsyncThunk(
  'dashboard/profile',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/profile`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getPricing = createAsyncThunk(
  'dashboard/pricing',
  async ({ escrowpaymentId }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/disbursementpricing?escrowpaymentId=${escrowpaymentId}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getDetailProfile = createAsyncThunk(
  'dashboard/profile/detail',
  async ({ id, type }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v2/bussines/transaction?id=${id}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const postConfirmTransaction = createAsyncThunk(
  'dashboard/release',
  async (
    { payload, isChangeInstantPayment },
    { rejectWithValue, getState },
  ) => {
    console.log({ payload, isChangeInstantPayment })
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      if (isChangeInstantPayment) {
        const res = await axios.post(
          `${apiEndpoint}/api/v1/bussines/transaction/update-instant-payment`,
          payload,
          config,
        )
        return res.data
      } else {
        const res = await axios.post(
          `${apiEndpoint}/api/v1/bussines/transaction/release`,
          payload,
          config,
        )
        return res.data
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

// Export the functions
export {
  getBalance,
  getDetailProfile,
  getPricing,
  getProfile,
  postConfirmTransaction,
}
