import axios from 'axios'
import InputPhoneNumber from 'components/InputPhoneNumber'
import { uploadFile } from 'features/private-views/sell-commodity/sellCommodityAction'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { AlertCircle, Calendar, X } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Badge,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import {
  BUSINESS_TRANSACTION_PURCHASE,
  MAKE,
  MANAGE,
} from 'utils/subjectActions'
import pakTani from '../../assets/icons/Pak Tani.png'
import { apiEndpoint } from '../../utility/Utils'
import { setNumberStep } from './commodityPurchaseSlice'
import ComodityForm from './ComodityForm'
import ModalUnverifSeller from './ModalUnverifSeller'
import Total from './Total'

const AddComodity = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useSelector((e) => e.auth)
  const { profile } = useSelector((e) => e.dashboard)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [dataSeller, setDataSeller] = useState('')
  const [userData] = useState({})
  const [isSet, setIsSet] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [errMsgPhone, setErrMsgPhone] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [alreadyReceived, setAlreadyReceived] = useState(false)
  const [fileNames, setFileNames] = useState([])
  const [files, setFiles] = useState([])
  const [savedContacts, setSavedContacts] = useState([])
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format('YYYY-MM-DD'),
  )

  const [disable, setDisable] = useState(false)
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const makePurchase = useAbilityAccess({
    action: MAKE,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })

  const managePurchase = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })

  const handleCheckNumber = async (phone, sellerData) => {
    try {
      setErrMsgPhone('')
      if (phone && sellerData?.phoneNumber !== phone) {
        const { data } = await axios.get(
          `${apiEndpoint}/api/v1/bussines/transaction/checkuser?search=${phone}&type=BUY`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (data?.status === 'REGISTERED') {
          setDataSeller(data)
        } else {
          setDataSeller('')
          toggleModal()
        }
      }
    } catch (error) {
      console.log(error)
      if (!error.response) {
        setErrMsgPhone('Silahkan cek koneksi internet Anda.')
      } else {
        setErrMsgPhone(error.response.data.message)
      }
    }
  }

  const getProfile = async () => {
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const handleFinished = async (response) => {
    try {
      if (response) {
        const updatedDataComodities = response?.dataComodities?.map((item) => ({
          ...item,
          price: item.price === '' ? 0 : item.price,
        }))

        const allPricesAreZero = updatedDataComodities.every(
          (item) => item.price === 0,
        )

        const obj = {
          ...response,
          dataComodities: updatedDataComodities,
          dataSeller: dataSeller,
          alreadyReceived: alreadyReceived,
          files: files.map((e) => e.fileUrl),
          fileNames: fileNames,
          trxDate: selectedDate,
        }
        localStorage.setItem('userData', JSON.stringify(obj))
        if (
          dataSeller?.status === 'REGISTERED' &&
          dataSeller?.profileStatus === 'VERIFIED' &&
          (dataSeller?.isLogin || alreadyReceived) &&
          !allPricesAreZero
        ) {
          const obj = {
            ...response,
            dataComodities: updatedDataComodities,
            dataSeller: dataSeller,
            alreadyReceived: alreadyReceived,
            files: files.map((e) => e.fileUrl),
            fileNames: fileNames,
            trxDate: selectedDate,
          }
          localStorage.setItem('userData', JSON.stringify(obj))
          setIsSet(false)
          navigate('/transaksi-pembelian-pembayaran')
        } else {
          const dataProfile = await getProfile()
          const obj = {
            userId: dataProfile?.ownerData?.userId,
            item: response?.dataComodities?.map((e) => {
              return {
                SKU: e?.comodity?.sku ?? e?.comodity?.SKU,
                nameItem: e?.comodity?.label,
                unit: e?.unit?.value,
                amount: Number(
                  e?.quantity
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/,/g, '.')
                    ?.slice?.(0, 15),
                ),
                price:
                  e?.price === ''
                    ? 0
                    : Number(
                        e?.price
                          ?.replace(/\./g, '')
                          ?.replace(/,/g, '.')
                          ?.slice(0, 15),
                      ),
                imageUrl: e?.comodity?.img ?? e?.comodity?.linkFoto,
              }
            }),
            order: {
              type: 'BUY',
              note: response?.notes,
              transaction_number: response?.customNumber,
              autoRelease: alreadyReceived,
              escrowFiles: files.map((e) => e.fileUrl),
            },
            buyer: {
              fullName: dataProfile?.ownerData?.name,
              phoneNumber: dataProfile?.ownerData?.phoneNumber,
              userId: dataProfile?.ownerData?.userId,
            },
            seller: {
              fullName: dataSeller?.fullName,
              phoneNumber: dataSeller?.phoneNumber,
              bankCode: dataSeller?.bankCode,
              bankAccountName: dataSeller?.bankAccountName,
              bankAccountNumber: dataSeller?.bankAccountNumber,
              isLogin: dataSeller?.isLogin ?? false,
              ...(dataSeller?.status === 'REGISTERED' && {
                userId: dataSeller?.userId,
              }),
            },
            trxDate: selectedDate,
          }
          const res = await axios.post(
            `${apiEndpoint}/api/v1/bussines/transaction/buy`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          if (res.status === 200) {
            const obj = {
              ...response,
              dataComodities: updatedDataComodities,
              dataSeller: dataSeller,
              trxId: res?.data?.id,
              alreadyReceived: alreadyReceived,
              files: files.map((e) => e.fileUrl),
              fileNames: fileNames,
              trxDate: selectedDate,
            }
            localStorage.setItem('userData', JSON.stringify(obj))
            if (allPricesAreZero) {
              navigate('/transaksi-pembelian-sukses', {
                state: {
                  title: 'Transaksi berhasil!',
                  desc: 'Transaksi Anda telah selesai dibuat',
                },
              })
            } else {
              navigate('/transaksi-pembelian-pembayaran')
            }
          }
        }
      } else {
        setIsSet(false)
      }
    } catch (error) {
      setIsSet(false)
      setErrMsg(error?.response?.data?.message)
    }
  }

  const handleFinishedCheckNumber = (resp) => {
    if (resp) {
      setDataSeller({
        ...resp,
        profileStatus: 'UNVERFIED',
        status: 'UNREGISTERED',
        bankLogo: resp?.bankData?.logo,
        bankCode: resp?.bankData?.code,
        phoneNumber: phoneNumber,
      })
      toggleModal()
    }
  }

  const handleCbData = (resp) => {
    try {
      const checkUnits = resp?.dataComodities?.some((i) => {
        return (
          !i.comodity ||
          i.quantity < 1 ||
          !i.unit ||
          (i.price?.length && i.totalPrice < 50000)
        )
      })
      setDisable(checkUnits)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(setNumberStep(1))
  }, [dispatch])

  useEffect(() => {
    if (profile?.userId) {
      const saved = JSON.parse(
        localStorage.getItem(`savedContacts-${profile?.userId}`),
      )
      setSavedContacts(saved ?? [])
    }
  }, [profile])

  return (
    <div>
      <ModalUnverifSeller
        toggle={toggleModal}
        isOpen={isOpenModal}
        handleFinished={handleFinishedCheckNumber}
      />
      <Container fluid className="custom-container">
        <div className="d-flex flex-column gap-4">
          <Card className="border-0 shadow-sm">
            <CardBody className="p-4">
              <CardTitle tag="h5">Pembelian</CardTitle>
              <FormGroup className="mt-4">
                <Label>Tanggal Transaksi</Label>
                <div className="d-flex align-items-center justify-content-center border rounded">
                  <Calendar size={17} className="ms-3" />
                  <Flatpickr
                    options={{
                      locale: Indonesian,
                      dateFormat: 'd-M-Y',
                      defaultDate: new Date(),
                      maxDate: new Date(),
                      onClose: (selectedDate, dateStr, instance) => {
                        setSelectedDate(
                          moment(selectedDate[0]).format('YYYY-MM-DD'),
                        )
                      },
                    }}
                    className="form-control flat-picker bg-transparent border-0 shadow-none"
                  />
                </div>
              </FormGroup>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleCheckNumber(phoneNumber, dataSeller)
                }}
              >
                <FormGroup>
                  <Label>Beli dari</Label>
                  <InputGroup>
                    <InputPhoneNumber
                      placeholder="Masukkan nomor handphone penjual"
                      value={phoneNumber}
                      setValue={(e) => {
                        setErrMsgPhone('')
                        setErrMsg('')
                        setPhoneNumber(e)
                        setDataSeller(null)
                      }}
                      name="phoneNumber"
                      id="phoneNumber"
                    />
                    <InputGroupText
                      onClick={() => handleCheckNumber(phoneNumber, dataSeller)}
                      className="bg-transparent button"
                    >
                      Cek
                    </InputGroupText>
                  </InputGroup>
                  <span className="text-xs">
                    Contoh: 0813xxxx atau 62813xxxx
                  </span>
                </FormGroup>
              </Form>
              {errMsgPhone && (
                <small className="text-danger">
                  <AlertCircle size={15} /> {errMsgPhone}
                </small>
              )}
              {dataSeller ? (
                <>
                  <CardText tag="h6" className="text-sm fw-semibold">
                    Informasi penjual
                  </CardText>
                  <Row>
                    <Col>
                      <Card
                        className="border-0 shadow-sm"
                        style={{ maxHeight: 68 }}
                      >
                        <CardBody>
                          <div className="flex-centered justify-content-between">
                            <div className="flex-centered gap-1 ">
                              <img
                                src={dataSeller?.profileImg ?? pakTani}
                                alt="ava"
                                width={36}
                                height={35}
                                className="rounded-circle"
                              />
                              <span className="text-sm fw-semibold">
                                {dataSeller?.fullName}
                              </span>
                            </div>
                            <Badge
                              size="sm"
                              disabled
                              className={
                                dataSeller?.profileStatus === 'VERIFIED'
                                  ? 'badge-verify'
                                  : 'badge-warning'
                              }
                            >
                              {dataSeller?.profileStatus === 'VERIFIED'
                                ? 'Terverifikasi'
                                : 'Belum Terverifikasi'}
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card
                        className="border-0 shadow-sm"
                        style={{ maxHeight: 68 }}
                      >
                        <CardBody>
                          <div className="d-flex justify-content-between flex-column">
                            <div className="flex-centered justify-content-start gap-1">
                              <img
                                src={dataSeller?.bankLogo}
                                alt="ava"
                                width={18}
                                height={18}
                              />
                              <span style={{ fontSize: 14 }}>
                                {dataSeller?.bankAccountNumber}
                              </span>
                            </div>
                            <div
                              className="fw-semibold"
                              style={{ fontSize: 14 }}
                            >
                              {dataSeller?.bankAccountName}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : (
                <div className="d-flex gap-2">
                  {savedContacts?.map((e, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          setPhoneNumber(e?.phoneNumber)
                          handleCheckNumber(e?.phoneNumber, dataSeller)
                        }}
                        className="rounded p-3 bg-gray-50 hover-100 pointer"
                      >
                        {e?.fullName}
                      </div>
                    )
                  })}{' '}
                </div>
              )}
            </CardBody>
          </Card>
          <ComodityForm
            title={'Barang yang dibeli'}
            handleFinished={handleFinished}
            isSet={isSet}
            userData={userData}
            handleCbData={handleCbData}
          />
          {dataSeller?.profileStatus === 'VERIFIED' &&
          dataSeller?.status === 'REGISTERED' &&
          (!makePurchase || managePurchase) &&
          !dataSeller?.isLogin ? (
            <Card className="border-0 shadow-sm">
              <CardBody className="p-4">
                <Input
                  type="checkbox"
                  name="barangDiterima"
                  onChange={(e) => {
                    if (alreadyReceived) {
                      setAlreadyReceived(false)
                      setFiles([])
                      setFileNames([])
                    } else {
                      setAlreadyReceived(true)
                    }
                  }}
                  checked={alreadyReceived}
                />{' '}
                Barang sudah diterima sebelum transaksi ini dibuat
                {alreadyReceived ? (
                  <>
                    {' '}
                    <span className="d-flex mt-3">
                      Bukti barang sudah diterima{' '}
                      <span className="text-danger">*</span>
                    </span>
                    <Form
                      encType="multipart/form-data"
                      className="flex-centered border rounded"
                      style={{ overflow: 'hidden' }}
                    >
                      <fieldset className="fieldset">
                        <input
                          className="input-file"
                          id="transactions"
                          name="transactions"
                          onChange={async (e) => {
                            setErrMsg('')
                            let newFiles = e.target.files
                            if (newFiles.length > 3) {
                              setErrMsg('Maksimal 3 file yang dapat diunggah')
                              return
                            }
                            let name = []
                            for (let i = 0; i < e.target.files.length; i++) {
                              name.push({
                                id: i,
                                fileName: e.target.files[i].name,
                              })
                              const file = await dispatch(
                                uploadFile(e.target.files[i]),
                              )
                              if (file.error) {
                                setErrMsg(file?.payload)
                              } else {
                                setFiles((prevValues) => {
                                  return [
                                    ...prevValues,
                                    {
                                      id: i,
                                      fileUrl: file?.payload?.url,
                                    },
                                  ]
                                })
                              }
                            }
                            setFileNames(name)
                          }}
                          type="file"
                          accept=".png, .jpeg, .jpg"
                          multiple
                        />
                        <label htmlFor="transactions">Upload</label>
                      </fieldset>
                      <div className="w-100" style={{ padding: '10px 14px' }}>
                        {fileNames?.length > 0
                          ? fileNames?.map((e, i) => (
                              <span className="ms-3 badge-line" key={i}>
                                {e.fileName}
                                <X
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setErrMsg('')
                                    const filteredFileNames = fileNames.filter(
                                      (file) => file.id !== e.id,
                                    )
                                    const filteredFiles = files.filter(
                                      (file) => file.id !== e.id,
                                    )
                                    setFileNames(filteredFileNames)
                                    setFiles(filteredFiles)
                                  }}
                                />
                              </span>
                            ))
                          : 'Belum ada file'}
                      </div>
                    </Form>
                  </>
                ) : (
                  <></>
                )}
              </CardBody>
            </Card>
          ) : (
            <></>
          )}

          {errMsg && (
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          )}
        </div>
      </Container>
      <Total
        text="Buat Transaksi"
        handleClick={() => {
          setIsSet(true)
        }}
        amount={userData?.dataComodities?.reduce(
          (sum, e) => sum + e.totalPrice,
          0,
        )}
        isDisable={
          !dataSeller || !userData || disable || alreadyReceived
            ? files?.length === 0
            : false
        }
      />
    </div>
  )
}

export default AddComodity
