import LoadingAnimation from 'components/LoadingAnimation'
import { useCallback, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { CardText, CardTitle, Input, Modal } from 'reactstrap'
import { getItemsInventory } from '../../API/manageStockAction'

export default function ItemModal({ isOpen, onClose, onSelectItem }) {
  const dispatch = useDispatch()
  const { itemsInventory } = useSelector((s) => s.manageStock)
  const [filteredCommodity, setFilteredCommodity] = useState(itemsInventory)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!filteredCommodity.length) {
      setFilteredCommodity(itemsInventory)
    }
  }, [itemsInventory])

  useEffect(() => {
    if (isOpen) {
      fetchItemsInventory()
    }
  }, [isOpen])

  const fetchItemsInventory = useCallback(async () => {
    setLoading(true)
    try {
      await dispatch(getItemsInventory()).unwrap()
    } catch (err) {
      console.error('Failed to fetch items inventory', err)
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  const onSearchCommodity = (value) => {
    if (!value) {
      setFilteredCommodity(itemsInventory)
    } else {
      let newList = itemsInventory.filter((com) =>
        com?.nameItem?.toLowerCase().includes(value.toLowerCase()),
      )
      setFilteredCommodity(newList)
    }
  }

  const onSelectItemStock = (data) => {
    onSelectItem(data)
    onCloseModal()
  }

  const onCloseModal = () => {
    setFilteredCommodity(itemsInventory)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} centered autoFocus={false}>
      <div className="regular-padding border-bottom d-flex justify-content-between align-items-center">
        <CardTitle className="fw-semibold">Pilih Komoditas</CardTitle>
        <X className="button" onClick={onCloseModal} />
      </div>
      <div className="regular-padding primary-bg-25">
        <CardText style={{ fontSize: 14 }}>
          Pilih komoditas yang ingin dicatat.
        </CardText>
      </div>
      <div className="regular-padding">
        <Input
          autoFocus={true}
          placeholder="Cari komoditas"
          autoComplete="off"
          name="dob"
          id="dob"
          onChange={(text) => onSearchCommodity(text.target.value)}
          disabled={loading}
        />
      </div>
      {loading && !filteredCommodity?.length ? (
        <div className="d-flex justify-content-center mb-2">
          <LoadingAnimation color="primary" />
        </div>
      ) : (
        <div className="regular-padding-horizontal small-scrollable-container">
          {filteredCommodity?.map((el, i) => {
            return (
              <div
                key={`${i}-${el?.nameItem}}`}
                className="regular-padding-bottom border-bottom regular-padding-top button"
                onClick={() => onSelectItemStock(el)}
              >
                <CardText>{el?.nameItem}</CardText>
              </div>
            )
          })}
        </div>
      )}
    </Modal>
  )
}
