import image404 from 'assets/images/404.png'
import imageTransparent from 'assets/images/transparent.png'
import './Thumbnail.css'
import { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { RotateCcw, RotateCw } from 'react-feather'

const Thumbnail = ({ src, className, userId, ...rest }) => {
  // ** States
  const [imgSrc, setImgSrc] = useState(null)
  const [modalShow, setModalVisibility] = useState(false)
  const [rotation, setRotation] = useState(0)

  const toggleModal = () => setModalVisibility(!modalShow)

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  return (
    <>
      <Modal isOpen={modalShow} className="modal-lg modal-dialog-centered">
        <ModalHeader toggle={toggleModal}>
          <Button
            className="btn-icon"
            size="sm"
            outline
            onClick={() => setRotation((prev) => (prev - 90) % 360)}
          >
            <RotateCcw size={14} />
          </Button>
          <Button
            className="btn-icon"
            size="sm"
            outline
            onClick={() => setRotation((prev) => (prev + 90) % 360)}
          >
            <RotateCw size={14} />
          </Button>
        </ModalHeader>
        <ModalBody className="text-center">
          <img
            alt="img"
            className="img-fluid imageModalBody"
            style={{ transform: `rotate(${rotation}deg)` }}
            src={imgSrc?.length > 0 ? imgSrc : image404}
            onContextMenu={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          />
        </ModalBody>
      </Modal>

      <img
        {...rest}
        alt="img"
        className={className + ' cursor-pointer'}
        src={
          imgSrc == null ? imageTransparent : imgSrc === '' ? image404 : imgSrc
        }
        onContextMenu={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onClick={() => setModalVisibility(true)}
      />
    </>
  )
}

export default Thumbnail
