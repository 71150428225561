import IPhoto from 'assets/icons/Pak-Tani.png'
import LoadingAnimation from 'components/LoadingAnimation'
import { TableComponent as DataTable } from 'components/TableComponent'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Card, CardBody, CardText } from 'reactstrap'
import {
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  READ,
} from 'utils/subjectActions'
import SidebarDashboard from '../SidebarDashboard'
import BtnMakeTransaction from './BtnMakeTransaction'

import ICUangBelanja from 'assets/icons/uang-belanja-wallet.png'
import ICTalangin from 'assets/icons/uang-usaha.png'
import PMBPagination from 'components/PMBPagination'
import useGetTrxDashboard from 'hooks/useGetTrxDashboard'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { formatDatePMB } from 'utils/formatDate'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { URL_DETAIL_TRANSACTION } from 'utils/pathUrl'
import { setFetchOption, toggleSidebarFilter } from '../dashboardSlice'
import SidebarFilter from '../SidebarFilter'
import HeaderTable from './HeaderTable'

const TableDashboard = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isOpenSidebarFilter, tag } = useSelector((s) => s.dashboard)

  const abilityBuy = useAbilityAccess({
    action: READ,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })

  const abilitySell = useAbilityAccess({
    action: READ,
    subject: BUSINESS_TRANSACTION_SELL,
  })

  const [isOpen, setIsOpen] = useState(false)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [isRefetch, setIsRefetch] = useState(false)

  const handleFilter = (data) => {
    setIsRefetch(true)
  }

  const handleCallback = () => {
    setIsRefetch(true)
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar)
  }

  const trx = useGetTrxDashboard(
    { ...tag },
    {
      skip: abilitySell && !abilityBuy,
    },
  )

  const columns = [
    {
      name: <span>Tanggal transaksi</span>,
      cell: (row) => formatDatePMB(row?.createdAt, row?.createdAt),
    },
    {
      name: <span>{tag?.type === 'SELL' ? 'Pembeli' : 'Penjual'}</span>,
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            {tag?.type === 'BUY' && (
              <>
                <img
                  src={row?.sellerOrBuyAvatar ?? IPhoto}
                  className="rounded-circle"
                  alt="pak tani"
                  width={20}
                  height={20}
                />
                <span>{row.sellerDetail?.fullName}</span>
              </>
            )}
            {tag?.type === 'SELL' && (
              <>
                <img
                  src={row?.sellerOrBuyAvatar ?? IPhoto}
                  className="rounded-circle"
                  alt="pak tani"
                  width={20}
                  height={20}
                />
                <span>{row.buyerDetail?.fullName}</span>
              </>
            )}
          </div>
        )
      },
    },
    {
      name: <span>Komoditas</span>,
      cell: (row) => (
        <div className="d-flex flex-column py-2">
          <span className="flex-row">{row?.itemDetail?.[0]?.nameItem}</span>
          <span className="text-primary">
            {intl.formatNumber(row?.itemDetail?.[0]?.amount, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}{' '}
            {row?.itemDetail?.[0]?.unit} x Rp
            {intl.formatNumber(row?.itemDetail?.[0]?.price, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
          {row?.itemDetail?.length > 1 ? (
            <div className="bg-gray-100 badge rounded-pill my-1">
              <span className="text-dark fw-medium">
                +{' '}
                {intl.formatNumber(row?.itemDetail?.length - 1, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}{' '}
                komoditas lainnya
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      name: (
        <div
          style={{
            textAlign: 'right',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          Total Transaksi
        </div>
      ),
      cell: (row) => (
        <div className="d-flex justify-content-end w-100">
          <span>Rp{newFormatThousandSeparator(row?.totalAmount, 0)}</span>
        </div>
      ),
    },
    {
      name: <span>Status</span>,
      minWidth: '200px',
      cell: (row) => {
        if (row.status?.toLowerCase() === 'selesai') {
          return (
            <div
              className="badge rounded-pill bg-success-50 text-success px-2"
              style={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center',
                maxWidth: '100%',
              }}
            >
              {row.status}
            </div>
          )
        }

        if (row.status?.toLowerCase() === 'menunggu proses penjual') {
          return (
            <div
              className="badge rounded-pill bg-warning-50 text-warning-700 px-2"
              style={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center',
                maxWidth: '100%',
              }}
            >
              {row.status}
            </div>
          )
        }
        if (row.status?.toLowerCase() === 'menunggu approval admin') {
          return (
            <div
              className="badge rounded-pill bg-warning-50 text-warning-700 px-2"
              style={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center',
                maxWidth: '100%',
              }}
            >
              {row.status}
            </div>
          )
        }

        if (row.status?.toLowerCase() === 'diproses') {
          return (
            <div
              className="badge rounded-pill bg-primary-50 text-primary px-2"
              style={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center',
                maxWidth: '100%',
              }}
            >
              {row.status}
            </div>
          )
        }

        if (row.status?.toLowerCase() === 'dibatalkan') {
          return (
            <div
              className="badge rounded-pill bg-danger-50 text-danger-700 px-2"
              style={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center',
                maxWidth: '100%',
              }}
            >
              {row.status}
            </div>
          )
        }
        if (row.status?.toLowerCase() === 'menunggu pembayaran pembeli') {
          return (
            <div
              className="badge rounded-pill bg-warning-50 text-warning-700 px-2"
              style={{
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center',
                maxWidth: '100%',
              }}
            >
              {row.status}
            </div>
          )
        }
      },
    },
    {
      name: <span>Metode pembayaran</span>,
      minWidth: '180px',
      cell: (row) => (
        <div
          className={`badge rounded-pill ${!row?.creditRequested ? 'bg-primary-50' : 'bg-warning-50'} px-2 py-1`}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            maxWidth: '100%',
          }}
        >
          <img
            width={16}
            height={16}
            src={!row?.creditRequested ? ICUangBelanja : ICTalangin}
            alt={!row?.creditRequested ? 'Uang Belanja' : 'Talangin'}
            style={{
              verticalAlign: 'middle',
            }}
          />
          <span
            className={`${!row?.creditRequested ? 'text-primary' : 'text-dark fw-bold'}`}
            style={{
              lineHeight: '1.2',
            }}
          >
            {!row?.creditRequested ? 'Uang Belanja' : 'Talangin'}
          </span>
        </div>
      ),
    },
    {
      name: '',
      selector: (row) => (
        <>
          <div
            className="fw-600"
            onClick={() => {
              // toggleSidebar()
              // setSelectedRow(row)
              navigate(`${URL_DETAIL_TRANSACTION}/${row?.id}?type=${tag?.type}`)
            }}
            style={{ cursor: 'pointer' }}
          >
            Lihat Detail
          </div>
        </>
      ),
      center: true,
    },
  ]

  useEffect(() => {
    if (isRefetch) {
      trx?.refetch()
      setIsRefetch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetch])

  return (
    <>
      <SidebarFilter
        handleFilter={handleFilter}
        open={isOpenSidebarFilter}
        toggle={() => dispatch(toggleSidebarFilter())}
      />
      <SidebarDashboard
        isOpen={isOpenSidebar}
        toggle={toggleSidebar}
        toggleModal={toggle}
        row={selectedRow}
        isOpenModal={isOpen}
        handleCallback={handleCallback}
        options={tag}
      />
      <Card className="border-0 shadow-sm">
        <CardBody className="py-0 px-3 d-flex justify-content-between mt-4">
          <CardText tag="h4">Transaksi</CardText>
          <BtnMakeTransaction />
        </CardBody>
        <CardBody>
          {(abilityBuy || abilitySell) && (
            <DataTable
              columns={columns}
              data={trx?.data?.items}
              progressPending={trx?.isLoading || trx?.isFetching}
              progressComponent={<LoadingAnimation />}
              pagination
              paginationServer
              paginationComponent={(props) => (
                <PMBPagination
                  rowCount={trx?.data?.totalItems || 0}
                  currentPage={tag?.page}
                  rowsPerPage={tag?.size}
                  setCurrentPage={(e) => {
                    dispatch(
                      setFetchOption({
                        page: e,
                      }),
                    )
                  }}
                  setRowsPerPage={(e) => {
                    dispatch(
                      setFetchOption({
                        size: e,
                      }),
                    )
                  }}
                  props={props}
                />
              )}
              subHeader={true}
              subHeaderAlign={'center'}
              subHeaderComponent={
                <HeaderTable
                  abilityBuy={abilityBuy}
                  abilitySell={abilitySell}
                  type={tag?.type}
                />
              }
            />
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default TableDashboard
