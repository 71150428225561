import { createAppSlice } from 'app/createAppSlice'
import { setSession } from 'utils/setSession'
import { LOCALSTORAGE_STATE } from 'utils/tags'

export const initialState = {
  loading: false,
  error: null,
  message: null,
  msg: null,
  token: null,
  alreadychangepass: null,
  abilityRole: [],
  role: null,
  isError: false,
  session: null,
  userNotPartnership: false,
  isOpenAlert: false,
  alertMsg: '',
  isOpenModal: false,
}

export const loginSlice = createAppSlice({
  name: 'auth',
  initialState,
  reducers: (create) => ({
    resetAlert: create.reducer((state) => {
      state.isError = false
      state.error = null
    }),

    addRole: create.reducer((state, { payload }) => {
      state.role = payload
    }),

    toggleAlert: create.reducer((state, { payload }) => {
      state.isOpenAlert = payload
    }),

    setAlertMsg: create.reducer((state, { payload }) => {
      state.alertMsg = payload
    }),

    setInitAlert: create.reducer((state, { payload }) => {
      state.alertMsg = ''
      state.isOpenAlert = false
    }),

    toggleModal: create.reducer((state, { payload }) => {
      state.isOpenModal = !state.isOpenModal
    }),

    setLoading: create.reducer((state, { payload }) => {
      state.loading = !state.loading
    }),

    logout: create.reducer((state) => {
      state.loading = false
      state.abilityRole = []
      state.alreadychangepass = null
      state.message = null
      state.msg = null
      state.role = null
      state.token = null
      state.error = null
      state.isError = false
      state.session = null
      state.userNotPartnership = false
      for (let i = 0; i < LOCALSTORAGE_STATE.length; i++) {
        const element = LOCALSTORAGE_STATE[i]
        localStorage.removeItem(element)
      }
    }),

    login: create.reducer((state, { payload }) => {
      state.loading = false
      state.abilityRole = payload.abilityRole
      state.alreadychangepass = parseInt(payload.alreadychangepass)
      state.message = payload.message
      state.msg = payload.msg
      state.role = payload.role
      state.token = payload.token
      state.error = null
      state.isError = false
      state.session = setSession()
      state.userNotPartnership = payload.userNotPartnership
      state.serviceProvision = payload.serviceProvision
    }),

    loginLoading: create.reducer((state) => {
      Object.assign(state, { ...initialState, loading: true })
    }),

    loginReject: create.reducer((state, { payload }) => {
      Object.assign(state, {
        ...initialState,
        error: payload?.data?.message,
        isError: true,
        loading: false,
      })
    }),

    setToken: create.reducer((state, { payload }) => {
      state.token = payload?.token
      state.abilityRole = payload.abilityRole
      state.session = setSession()
    }),
  }),
  extraReducers: (builder) => {},
})
export const {
  resetAlert,
  addRole,
  logout,
  login,
  loginLoading,
  loginReject,
  toggleAlert,
  setAlertMsg,
  setInitAlert,
  toggleModal,
  setLoading,
  setToken,
} = loginSlice.actions
export default loginSlice.reducer
