import warningRed from 'assets/icons/warning-red.svg'
import CustomButton from 'components/Buttons/CustomButton'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch } from 'react-redux'
import { CardText, CardTitle, Modal } from 'reactstrap'
import {
  manageGroupForGroup,
  removeItemPacking,
} from '../../API/manageStockAction'

export default function ModalRemoveItemPacking({
  isOpen,
  onClose,
  data,
  setIsFetch,
  type,
}) {
  const dispatch = useDispatch()
  const [errMsg, setErrMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const normalizedData = Array.isArray(data) ? data : [data]
      const groupedData = normalizedData.reduce(
        (acc, currentItem) => {
          if (currentItem?.parentGroupId) {
            acc.group.push(currentItem)
          } else {
            acc.inventory.push(currentItem)
          }
          return acc
        },
        { group: [], inventory: [] },
      )

      if (groupedData.group.length > 0) {
        const groupPayload = {
          id: groupedData.group[0]?.parentGroupId,
          actionType: 'delete',
          payload: { removedGroupIds: groupedData.group.map((e) => e?.id) },
        }
        const res = await dispatch(manageGroupForGroup(groupPayload)).unwrap()
        if (res?.error) {
          throw res
        }
        enqueueSnackbar('Grup berhasil dikeluarkan.', { variant: 'success' })
      }

      if (groupedData.inventory.length > 0) {
        const removedInventoryIds = groupedData.inventory.map((e) => e?.id)
        const obj = {
          id: groupedData.inventory[0]?.groupId,
          removedInventoryIds,
        }
        const res = await dispatch(removeItemPacking(obj)).unwrap()
        if (res?.error) {
          throw res
        }
        enqueueSnackbar('Inventori berhasil dikeluarkan.', {
          variant: 'success',
        })
      }

      setIsFetch('inventory')
      onClose()
    } catch (error) {
      setErrMsg(error?.message ?? error?.payload)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setErrMsg('')
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <div className="regular-padding-horizontal small-scrollable-container small-padding-vertical">
        <img
          alt="Warning"
          src={warningRed}
          style={{ width: 50, height: 50, marginTop: 10, marginBottom: 10 }}
          className="button small-margin-right"
        />
        <CardTitle className="fw-semibold">{`Keluarkan ${type === 'group' ? 'Group' : 'inventori'}`}</CardTitle>
        <CardText>
          {`${type === 'group' ? 'Group' : 'Inventori'} akan dikeluarkan dari grup. Apakah Anda yakin?`}
        </CardText>
      </div>
      {errMsg && (
        <div className="mb-2">
          <small className="text-danger">
            <AlertCircle size={15} /> {errMsg}
          </small>
        </div>
      )}
      <div className="regular-padding-horizontal regular-padding-bottom d-flex justify-content-between mt-2">
        <CustomButton
          onClick={onClose}
          outline
          className="fw-semibold"
          style={{ width: '49%', borderColor: '#D0D5DD', color: '#344054' }}
        >
          Batal
        </CustomButton>
        <CustomButton
          onClick={handleSubmit}
          disabled={loading}
          className="fw-semibold"
          style={{
            width: '49%',
            backgroundColor: '#D92D20',
            borderColor: '#F97066',
          }}
        >
          Keluarkan
        </CustomButton>
      </div>
    </Modal>
  )
}
