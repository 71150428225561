import warningRed from 'assets/icons/warning-red.svg'
import CustomButton from 'components/Buttons/CustomButton'
import { CardText, CardTitle, Modal } from 'reactstrap'

export default function ConfirmRemoveMultipleModal({
  isOpen,
  onClose,
  onRemoveStock,
  selectedInventoryList,
  disabled,
  data,
  onRemoveGroup,
}) {
  const onRemoveGroupOrInventory = async () => {
    const withParentGroupId = data.filter(
      (item) => item.parentGroupId !== undefined,
    )
    const withoutParentGroupId = data.filter(
      (item) => item.parentGroupId === undefined,
    )

    try {
      await Promise.all([
        withParentGroupId.length &&
          onRemoveGroup(withParentGroupId.map((item) => item.id)),
        withoutParentGroupId.length &&
          onRemoveStock(withoutParentGroupId?.map((item) => item.id)),
      ])
    } catch (error) {
      console.error('Terjadi kesalahan', error)
    }
  }

  return (
    <Modal isOpen={isOpen} centered>
      <div className="regular-padding-horizontal small-scrollable-container small-padding-vertical">
        <img
          src={warningRed}
          style={{ width: 50, height: 50, marginTop: 10, marginBottom: 10 }}
          className="button small-margin-right"
        />
        <CardTitle className="fw-semibold">{`Hapus ${selectedInventoryList?.length} stok terpilih?`}</CardTitle>
        <CardText className="mt-2" style={{ color: '#475467' }}>
          Stok yang sudah dihapus tidak dapat dikembalikan.
        </CardText>
      </div>
      <div className="regular-padding-horizontal regular-padding-bottom d-flex justify-content-between mt-2">
        <CustomButton
          onClick={onClose}
          outline
          className="fw-semibold btn-cancel"
          style={{ width: '49%', borderColor: '#D0D5DD', color: '#344054' }}
        >
          Batal
        </CustomButton>
        <CustomButton
          onClick={onRemoveGroupOrInventory}
          disabled={disabled}
          className="fw-semibold"
          style={{
            width: '49%',
            backgroundColor: '#D92D20',
            borderColor: '#F97066',
          }}
        >
          Hapus
        </CustomButton>
      </div>
    </Modal>
  )
}
