import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import { useEffect, useState } from 'react'
import { AlertCircle, Eye, EyeOff } from 'react-feather'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap'
import lockIcon from '../../../assets/icons/lock-icon.png'
import { apiEndpoint } from '../../../utility/Utils'

const ModalPassword = ({
  isOpen,
  toggle,
  dataProfile,
  userData,
  dataState,
  selectedPricing,
  isCash,
}) => {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const { token } = useSelector((e) => e.auth)
  const [inputVisibility, setInputVisibility] = useState(false)
  const { profile } = useSelector((e) => e.dashboard)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/checkpasswordtrx`,
        { password: password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (data?.success === 1 && data.tempToken) {
        if (userData?.trxId) {
          const obj = {
            tempToken: data.tempToken,
            id: userData?.trxId,
            ...(dataState?.talangin && {
              loanDays: dataState?.selectedTenor?.loanDays,
              creditRequested: dataState?.dataTenor?.creditRequested,
            }),
            ...(isCash &&
              userData?.useProofOfPayment && {
                useProofOfPayment: userData?.useProofOfPayment,
              }),
            ...(isCash &&
              userData?.useProofOfPayment && {
                proofOfPayment: userData?.proofOfPayment,
              }),
          }
          const res = await axios.post(
            `${apiEndpoint}/api/v2/bussines/transaction/payment`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          if (res.status === 200) {
            localStorage.removeItem('userData')
            handleSavedContacts(userData?.dataSeller)
            setLoading(false)
            navigate('/transaksi-pembelian-sukses')
          }
        } else {
          const obj = {
            tempToken: data.tempToken,
            userId: dataProfile?.ownerData?.userId,
            item: userData?.dataComodities?.map((e) => {
              return {
                SKU: e?.comodity?.sku ?? e?.comodity?.SKU,
                nameItem: e?.comodity?.label,
                unit: e?.unit?.value,
                amount: Number(
                  e.quantity
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/\,/g, '.')
                    ?.slice?.(0, 15),
                ),
                price: Number(
                  e.price
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/\,/g, '.')
                    ?.slice?.(0, 15),
                ),
                imageUrl: e?.comodity?.img ?? e?.comodity?.linkFoto,
              }
            }),
            order: {
              type: 'BUY',
              transaction_number: userData?.customNumber,
              note: userData?.notes,
              ...(userData?.alreadyReceived && {
                autoRelease: userData?.alreadyReceived,
              }),
              ...(userData?.files.length > 0 && {
                escrowFiles: userData?.files,
              }),
              ...(selectedPricing && {
                useDisbursementpricingId: selectedPricing?.id,
              }),
              ...(isCash &&
                userData?.useProofOfPayment && {
                  useProofOfPayment: userData?.useProofOfPayment,
                }),
              ...(isCash &&
                userData?.useProofOfPayment && {
                  proofOfPayment: userData?.proofOfPayment,
                }),
            },
            buyer: {
              fullName: dataProfile?.ownerData?.name,
              phoneNumber: dataProfile?.ownerData?.phoneNumber,
              userId: dataProfile?.ownerData?.userId,
            },
            seller: {
              fullName: userData?.dataSeller?.fullName,
              phoneNumber: userData?.dataSeller?.phoneNumber,
              bankCode: userData?.dataSeller?.bankCode,
              bankAccountName: userData?.dataSeller?.bankAccountName,
              bankAccountNumber: userData?.dataSeller?.bankAccountNumber,
              isLogin: userData?.dataSeller?.isLogin ?? false,
              ...(userData?.dataSeller?.status === 'REGISTERED' && {
                userId: userData?.dataSeller?.userId,
              }),
            },
            ...(dataState?.talangin && {
              loanDays: dataState?.selectedTenor?.loanDays,
              creditRequested: dataState?.dataTenor?.creditRequested,
            }),
            trxDate: userData?.trxDate,
          }
          const res = await axios.post(
            `${apiEndpoint}/api/v2/bussines/transaction/buy`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          if (res.status === 200) {
            if (selectedPricing) {
              localStorage.setItem(
                'PMB_isInstant',
                JSON.stringify(selectedPricing),
              )
            } else {
              localStorage.removeItem('PMB_isInstant')
            }
            localStorage.removeItem('userData')
            setLoading(false)
            handleSavedContacts(userData?.dataSeller)
            if (isCash) {
              navigate('/transaksi-pembelian-sukses', {
                state: {
                  title: 'Transaksi berhasil dibuat!',
                  desc: 'Setelah penjual melakukan konfirmasi, transaksi akan selesai otomatis.',
                },
              })
            } else {
              navigate('/transaksi-pembelian-sukses')
            }
          }
        }
      } else {
        toggle()
      }
    } catch (error) {
      setLoading(false)
      setErrMsg(
        error?.response?.data?.message ??
          error?.response?.data?.meta?.message ??
          error?.message,
      )
      console.log(error)
    }
  }

  const renderIcon = () => {
    const size = 14
    if (inputVisibility === false) {
      return <Eye size={size} />
    } else {
      return <EyeOff size={size} />
    }
  }

  const handleSavedContacts = (e) => {
    const saved =
      JSON.parse(localStorage.getItem(`savedContacts-${profile?.userId}`)) ?? []
    let newListComodities = [...saved]

    const updatedOptions = newListComodities.filter(
      (item) => item.userId !== e.userId,
    )

    updatedOptions.unshift(e)

    if (updatedOptions.length > 5) {
      const removedItem = updatedOptions.pop()

      newListComodities = {
        removedItem,
      }
    }

    localStorage.setItem(
      `savedContacts-${profile?.userId}`,
      JSON.stringify(updatedOptions),
    )
  }

  useEffect(() => {
    if (!isOpen) {
      setErrMsg('')
    }
  }, [isOpen])

  return (
    <>
      <Modal className="p-5" isOpen={isOpen} toggle={toggle} centered>
        <ModalBody>
          <div className="w-100">
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                alt="password"
                src={lockIcon}
                style={{ width: '48px', height: '48px' }}
              />
            </div>
            <h6 className="text-center mb-3" style={{ fontWeight: 'bold' }}>
              Masukkan password untuk melanjutkan
            </h6>
            <FormGroup>
              <Label>Password</Label>
              <InputGroup>
                <Input
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setErrMsg('')
                  }}
                  type={inputVisibility === false ? 'password' : 'text'}
                  autoComplete="off"
                  className="passwd"
                  invalid={errMsg ? true : false}
                />
                <InputGroupText
                  onClick={() => setInputVisibility(!inputVisibility)}
                  style={{ cursor: 'pointer' }}
                  className={`${errMsg ? 'icon-step-2 step-2-invalid' : 'icon-step-2'}`}
                >
                  {renderIcon()}
                </InputGroupText>
              </InputGroup>
              {errMsg && (
                <FormGroup>
                  <small className="text-danger">
                    <AlertCircle size={15} /> {errMsg}
                  </small>
                </FormGroup>
              )}
            </FormGroup>
          </div>

          <div className="d-flex justify-content-between gap-3 w-100">
            <CustomButton
              onClick={toggle}
              disabled={loading}
              block
              outline
              color="secondary"
            >
              Batal
            </CustomButton>
            <CustomButton
              onClick={() => {
                handleSubmit()
              }}
              block
              color="primary"
              disabled={!password || loading}
              type="submit"
            >
              Bayar
            </CustomButton>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalPassword
