import { ArrowRight } from 'react-feather'
import { Button, Card, CardBody } from 'reactstrap'
import { newFormatThousandSeparator } from 'utils/formatNumber'

const DetailOrderSection = ({ transaction, setIsOpenSidebar }) => {
  return (
    <Card className="shadow-sm border-0 mb-3">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="fw-semibold mb-3">Rincian pesanan</h6>
        </div>

        {transaction?.itemDetail?.map((item) => (
          <div className="mb-2">
            <p className="mb-1">
              <span className="text-sm">{item?.nameItem ?? '-'}</span>
              {item?.inventoryOwnerCode && (
                <span className="text-sm" style={{ color: '#667085' }}>
                  ({item?.inventoryOwnerCode})
                </span>
              )}

              <span className="float-end fw-semibold">
                Rp
                {newFormatThousandSeparator(
                  Number(item?.price ?? 0) * Number(item?.amount ?? 0),
                  0,
                )}
              </span>
            </p>
            <p className="text-sm text-primary">
              {newFormatThousandSeparator(Number(item?.amount ?? 0))}{' '}
              <span className="text-capitalize">{item?.unit} </span>x Rp
              {newFormatThousandSeparator(Number(item?.price ?? 0), 0)}
            </p>
          </div>
        ))}
        <hr />
        <h6>
          Total{' '}
          <strong className="float-end fw-semibold">
            Rp
            {newFormatThousandSeparator(
              Number(transaction?.totalAmount ?? 0),
              0,
            )}
          </strong>
        </h6>
        <hr />
        {transaction?.escrowpaymentId && (
          <Button
            color="primary"
            outline
            className="mt-1"
            onClick={() => setIsOpenSidebar(true)}
          >
            <ArrowRight size={16} className="me-1" />
            Lihat Detail Pembayaran
          </Button>
        )}
      </CardBody>
    </Card>
  )
}

export default DetailOrderSection
