import ICThunderDisable from 'assets/icons/thunder-disable.svg'
import ICThunder from 'assets/icons/thunder-icon.svg'
import CustomButton from 'components/Buttons/CustomButton'
import LoadingV2 from 'components/LoadingV2'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import IconChoosePayment from '../../../../assets/icons/icon-choose-payment.svg'
import {
  getDetailProfile,
  getPricing,
  postConfirmTransaction,
} from '../dashboardAction'

const ModalChoosePayment = ({
  isOpen,
  toggle,
  transaction,
  refetchTransactionById,
  isChangeInstantPayment,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { pricing, balance, loading } = useSelector((s) => s.dashboard)

  const [selectedPricing, setSelectedPricing] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (
      isOpen &&
      transaction &&
      (transaction?.status === 'Diproses' ||
        (transaction?.status === 'Selesai' && isChangeInstantPayment)) &&
      !transaction?.orderDetail?.autoRelease &&
      !transaction?.orderDetail?.useProofOfPayment
    ) {
      dispatch(getPricing({ escrowpaymentId: transaction?.escrowpaymentId }))
    }

    if (isOpen && transaction) {
      dispatch(
        getDetailProfile({
          id: transaction?.id,
          type: transaction?.orderDetail?.type,
        }),
      )
    }
  }, [isOpen, transaction, dispatch])

  if (loading) <LoadingV2 />

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      const payload = {
        escrowPaymentId: transaction?.escrowpaymentId,
        useDisbursementpricingId: transaction?.orderDetail?.useProofOfPayment
          ? 0
          : (selectedPricing ?? pricing?.items?.find((e) => e?.fee === 0)?.id),
      }

      const res = await dispatch(
        postConfirmTransaction({ payload, isChangeInstantPayment }),
      )

      if (res?.payload?.status === 200 || res?.payload?.id) {
        toggle()
        refetchTransactionById()

        enqueueSnackbar(
          `${isChangeInstantPayment ? 'Opsi pembayaran berhasil diubah.' : 'Konfirmasi barang sampai berhasil. Transaksi selesai.'}`,
          {
            variant: 'success',
          },
        )
      } else {
        throw res
      }
    } catch (error) {
      console.warn(error)
      setIsLoading(false)
      enqueueSnackbar(
        error?.payload ?? error?.message ?? error?.error?.message,
        {
          variant: 'error',
        },
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      className="p-4"
      centered
      isOpen={isOpen}
      toggle={() => {
        toggle()
      }}
      autoFocus={false}
    >
      <ModalBody>
        <img src={IconChoosePayment} loading="lazy" alt="icon" />
        <h6 className="fw-bold mt-3">Opsi pembayaran ke penjual</h6>
        <p className="text-sm">
          Sebelum konfirmasi barang sampai, silakan pilih opsi pembayaran ke
          penjual terlebih dahulu.
        </p>
        <div className="flex-centered justify-content-between gap-1 mb-3">
          <div
            className="d-flex flex-column gap-3"
            style={{
              maxHeight: '470px',
              overflowY: 'auto',
            }}
          >
            {pricing?.items
              ?.filter(
                (e) =>
                  !(isChangeInstantPayment && e?.fee === 0 && !e?.originalFee),
              )
              ?.map((e, i) => {
                return (
                  <div key={i}>
                    <Card
                      style={{
                        backgroundColor:
                          selectedPricing === e?.id
                            ? '#ECFAFF'
                            : e?.disable
                              ? '#F9FAFB'
                              : '',

                        cursor: !e?.disable && 'pointer',
                      }}
                      className={
                        selectedPricing === e?.id ? 'border-primary' : ''
                      }
                      onClick={() => {
                        if (!e?.disable) {
                          setSelectedPricing(e?.id)
                        }
                      }}
                    >
                      <CardBody className="pb-0">
                        <div className="d-flex flex-row gap-2">
                          <div>
                            <img
                              src={e?.disable ? ICThunderDisable : ICThunder}
                              alt="kilat"
                              width={40}
                              height={40}
                            />
                          </div>
                          <div>
                            {/* HANDLE STRIKEOUT */}
                            <span style={{ fontWeight: '500' }}>
                              {e?.fee !== 0 && 'Instan'} {e?.title}{' '}
                              {e?.originalFee !== undefined &&
                              e?.originalFee !== null &&
                              e?.originalFee > e?.fee ? (
                                <>
                                  <span
                                    style={{
                                      textDecoration: 'line-through',
                                      color: '#667085',
                                    }}
                                  >
                                    Rp
                                    {newFormatThousandSeparator(
                                      Number(e?.originalFee),
                                      0,
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: '5px',
                                      fontWeight: 'bold',
                                      color: 'black',
                                    }}
                                  >
                                    Rp
                                    {newFormatThousandSeparator(
                                      Number(e?.fee),
                                      0,
                                    )}
                                  </span>
                                </>
                              ) : (
                                <span>
                                  Rp
                                  {newFormatThousandSeparator(
                                    Number(e?.fee),
                                    0,
                                  )}
                                </span>
                              )}
                            </span>
                            {/* END HANDLE STRIKEOUT */}

                            <p
                              style={{
                                color:
                                  selectedPricing === e?.id ? '#006386' : '',
                              }}
                            >
                              {e?.disable ? (
                                <span style={{ color: '#B42318' }}>
                                  {e?.disableReason}
                                </span>
                              ) : (
                                <span>{e.description}</span>
                              )}
                            </p>
                          </div>
                          <Input
                            type="radio"
                            disabled={e?.disable}
                            checked={selectedPricing === e?.id}
                            onChange={() => {
                              if (!e?.disable) {
                                setSelectedPricing(e?.id)
                              }
                            }}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                )
              })}
          </div>
        </div>

        <div>
          {pricing?.items?.some((e) => e?.fee > balance?.uangBelanja) && (
            <div className="mb-4">
              <CustomButton
                outline
                block
                color="primary"
                onClick={() => navigate('/topup')}
                disabled={isLoading}
              >
                Tambah Uang Belanja
              </CustomButton>
            </div>
          )}
        </div>

        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              toggle()
            }}
            className="btn-cancel"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={!selectedPricing || isLoading}
            color="primary"
            block
            onClick={handleConfirm}
          >
            {isLoading && <Spinner size="sm" className="me-2" />} Konfirmasi
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalChoosePayment
