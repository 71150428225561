import { createAppSlice } from 'app/createAppSlice'
import {
  FILTER_CATCHES_LIST_OPTIONS,
  PROVINCES_OPTIONS,
  WPP_OPTIONS,
} from 'constants'

const currentYear = new Date().getFullYear()
const startDateDefault = new Date(currentYear, 0, 1)
const endDateDefault = new Date(currentYear, 11, 31)
const WPPOptions = [{ value: '', label: 'Semua' }, ...WPP_OPTIONS]

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  isOpenSidebarFilter: false,
  isOpenModalApprove: false,
  isOpenModalEdit: false,
  filters: {
    status: FILTER_CATCHES_LIST_OPTIONS[0],
    province: PROVINCES_OPTIONS[0],
    selectedDate: [startDateDefault, endDateDefault],
    selectedWPP: WPPOptions[0],
  },
}

export const catchesListSlice = createAppSlice({
  name: 'catchesList',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size ?? 10
      state.tag.page = payload.page
    }),
    setToggleSidebarFilter: create.reducer((state, { payload }) => {
      state.isOpenSidebarFilter = !state.isOpenSidebarFilter
    }),
    setIsOpenModalApprove: create.reducer((state, { payload }) => {
      state.isOpenModalApprove = !state.isOpenModalApprove
    }),
    setIsOpenModalEdit: create.reducer((state, { payload }) => {
      state.isOpenModalEdit = !state.isOpenModalEdit
    }),
    setFilters: create.reducer((state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
    }),
  }),
})

export const {
  setFetchOption,
  setToggleSidebarFilter,
  setIsOpenModalApprove,
  setIsOpenModalEdit,
  setFilters,
} = catchesListSlice.actions

export default catchesListSlice.reducer
