import PMBTabSwitcher from 'components/PMBTabSwitcher'
import { useEffect, useState } from 'react'
import { Filter } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { setFetchOption, toggleSidebarFilter } from '../dashboardSlice'

const HeaderTable = ({ type, abilityBuy, abilitySell }) => {
  const dispatch = useDispatch()
  const { tag } = useSelector((s) => s.dashboard)
  const [activeTab, setActiveTab] = useState(
    type || (abilityBuy ? 'BUY' : abilitySell ? 'SELL' : null),
  )

  useEffect(() => {
    if (!type) {
      dispatch(
        setFetchOption({
          type: abilityBuy ? 'BUY' : abilitySell ? 'SELL' : '',
        }),
      )
    }
  }, [type, abilityBuy, abilitySell, dispatch])

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey)
    dispatch(
      setFetchOption({
        type: tabKey,
      }),
    )
  }

  const tabs = [
    { key: 'BUY', label: 'Pembelian' },
    { key: 'SELL', label: 'Penjualan' },
  ].filter(
    (tab) =>
      (tab.key === 'BUY' && abilityBuy) || (tab.key === 'SELL' && abilitySell),
  )

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center mb-1">
        {tabs.length > 0 && (
          <div className="my-1">
            <PMBTabSwitcher
              activeTab={activeTab}
              tabs={tabs}
              onTabChange={handleTabChange}
            />
          </div>
        )}
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => dispatch(toggleSidebarFilter())}
        >
          <div className="d-flex gap-2 align-items-center">
            <Filter size={20} />
            Filter
            {tag?.payment || tag?.status ? (
              <div
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '2px',
                }}
                className="text-primary bg-primary-50"
              >
                <span>
                  {tag?.status && tag?.payment
                    ? '2'
                    : tag?.payment || tag?.status
                      ? '1'
                      : ''}
                </span>
              </div>
            ) : null}
          </div>
        </Button>
      </div>
    </div>
  )
}

export default HeaderTable
