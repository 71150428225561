import CustomButton from 'components/Buttons/CustomButton'
import PMBModalTraceability from 'components/PMBModalTraceability'
import { useState } from 'react'
import QRCode from 'react-qr-code'
import { Card, CardText } from 'reactstrap'

const IDStockModal = ({ isOpen, onClose, selectedStock }) => {
  const [selectedProductId, setSelectedProductId] = useState(null)
  const [isOpenModalTraceability, setIsOpenModalTraceability] = useState(false)

  const inventoryUrl = process.env.REACT_APP_INVENTORY_URL
  let url = `${inventoryUrl}?productId=${selectedStock?.traceCode}`

  const onOpenCommodityOnWeb = () => {
    window.open(url, '_blank')
  }

  const onCopyCommodityLink = () => {
    navigator.clipboard.writeText(url)
  }

  const handleOpenCloseModalTraceability = (productId = null) => {
    setSelectedProductId(productId)
    setIsOpenModalTraceability((prev) => !prev)
  }

  return (
    <>
      <PMBModalTraceability
        isOpen={isOpenModalTraceability}
        toggle={() => handleOpenCloseModalTraceability()}
        productId={selectedProductId}
      />

      <div className="d-flex align-items-center justify-content-center">
        <div
          style={{
            width: '90%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Card className="small-padding mt-4 mb-4 border-0 shadow-sm">
            <div>
              <QRCode value={url} />
            </div>
            <CustomButton
              block
              style={{
                borderColor: '#2A7D9A',
                backgroundColor: '#F3FEFF',
                color: 'black',
                alignItems: 'center',
                marginRight: 10,
                marginTop: 15,
                paddingTop: 10,
                paddingBottom: 10,
              }}
              className="text-align-center"
              // onClick={onOpenCommodityOnWeb}
            >
              <CardText className="text-align-center" style={{ fontSize: 12 }}>
                Scan QR code di atas dari handphone Anda.
              </CardText>
            </CustomButton>
            {/* <CustomButton
              id="tooltipCopy"
              block
              style={{
                borderColor: '#D0D5DD',
                backgroundColor: 'white',
                color: 'black',
                alignItems: 'center',
                marginRight: 10,
              }}
              className="text-align-center mt-3"
              onClick={onOpenCommodityOnWeb}
            >
              <CardText
                className="text-align-center fw-semibold"
                style={{ fontSize: 12 }}
              >
                Buka Link
              </CardText>
            </CustomButton> */}
            <CustomButton
              // id="tooltipCopy"
              block
              style={{
                borderColor: '#D0D5DD',
                backgroundColor: 'white',
                color: 'black',
                alignItems: 'center',
                marginRight: 10,
              }}
              className="text-align-center mt-3"
              onClick={() =>
                handleOpenCloseModalTraceability(selectedStock?.traceCode)
              }
            >
              <CardText
                className="text-align-center fw-semibold"
                style={{ fontSize: 12 }}
              >
                Buka Keterlacakan
              </CardText>
            </CustomButton>
            {/* <UncontrolledTooltip
              target={'tooltipCopy'}
              placement="bottom"
              trigger="click"
              autohide={true}
            >
              Berhasil disalin
            </UncontrolledTooltip> */}
          </Card>
        </div>
      </div>
    </>
  )
}

export default IDStockModal
