import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Card, Col, List } from 'reactstrap'

import { Clipboard, Menu, PlusCircle } from 'react-feather'
import { REPO_KOMODITAS, REPO_KOMODITAS_LIST, URL_406 } from 'utils/pathUrl'

import IcSuccessTrx from 'assets/images/success-trx.png'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import SidebarDetails from 'components/SidebarDetails'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { useLocalStorageTable as storage } from 'hooks/useLocalStorageTable'
import { BUSINESS_AUTOREPO, MANAGE } from 'utils/subjectActions'
import { useGetRepoListQuery } from './commodityRepoApiSlice'

const CommodityRepo = () => {
  const nav = useNavigate()
  const location = useLocation()
  const [successTrx, setSuccessTrx] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)

  const { options } = storage('auto_repo_table')
  const repoList = useGetRepoListQuery(options)

  const ability = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_AUTOREPO,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (!ability) {
    return <Navigate to={URL_406} replace={true} />
  }

  const handleSidebarClick = (path) => {
    nav(path)
    setIsOpenSidebar(false)
  }

  const isMobile = screenWidth < 1000

  return (
    <div
      className="d-flex w-100"
      style={{ height: '100vh', overflow: 'hidden' }}
    >
      {successTrx ? (
        <Col>
          <Card
            className="m-0 m-auto d-flex justify-content-center align-items-center mt-3 gap-3 p-5 border-0 shadow-sm"
            style={{ maxWidth: '640px' }}
          >
            <img src={IcSuccessTrx} alt="success" width={280} height={182} />
            <div className="text-sm text-center">
              Pengajuan Repo kamu telah berhasil dan sedang di pelajari oleh Tim
              kami. Setelah selesai kami akan memberitahu kamu segera.
            </div>
            <CustomButton
              onClick={() => {
                nav(REPO_KOMODITAS, { replace: true })
                repoList.refetch()
                setSuccessTrx(false)
              }}
              color="primary"
              className="w-50"
            >
              Kembali ke Repo Komoditas
            </CustomButton>
          </Card>
        </Col>
      ) : (
        <>
          {!isMobile ? (
            <div
              className="sidebar bg-white border border-left-0 me-3 p-0"
              style={{
                width: '17%',
                height: '100vh',
                flexShrink: 0,
                overflow: 'hidden',
              }}
            >
              <div className="w-100">
                <List type="unstyled" className="my-1">
                  <li
                    className={`text-sm menu-categories-approval ${
                      location.pathname === REPO_KOMODITAS ? 'active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => nav(REPO_KOMODITAS)}
                  >
                    <PlusCircle size={16} className="ms-2 me-1" />
                    <span>Repo Komoditas</span>
                  </li>
                  <li
                    className={`text-sm menu-categories-approval ${
                      location.pathname === REPO_KOMODITAS_LIST ? 'active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => nav(REPO_KOMODITAS_LIST)}
                  >
                    <Clipboard size={16} className="ms-2 me-1" />
                    <span>{`Daftar Pengajuan Repo (${
                      repoList?.data?.totalItems ?? 0
                    })`}</span>
                  </li>
                </List>
              </div>
            </div>
          ) : (
            <SidebarDetails
              noHeader
              bodyClassName="p-0"
              open={isOpenSidebar}
              headerClassName="mb-1"
              contentClassName="pt-0"
              isNavbar
              toggleSidebar={() => setIsOpenSidebar(!isOpenSidebar)}
              title={''}
              noClose
            >
              <List type="unstyled" className="py-3">
                <List type="unstyled" className="my-1">
                  <li
                    className={`text-sm menu-categories-approval ${
                      location.pathname === REPO_KOMODITAS ? 'active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSidebarClick(REPO_KOMODITAS)}
                  >
                    <PlusCircle size={16} className="ms-2 me-1" />
                    <span>Repo Komoditas</span>
                  </li>
                  <li
                    className={`text-sm menu-categories-approval ${
                      location.pathname === REPO_KOMODITAS_LIST ? 'active' : ''
                    }`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSidebarClick(REPO_KOMODITAS_LIST)}
                  >
                    <Clipboard size={16} className="ms-2 me-1" />
                    <span>{`Daftar Pengajuan Repo (${
                      repoList?.data?.totalItems ?? 0
                    })`}</span>
                  </li>
                </List>
              </List>
            </SidebarDetails>
          )}

          <div
            className="content me-3 no-scrollbar"
            style={{
              flex: 1,
              height: '100vh',
              overflowY: 'auto',
              scrollbarWidth: 'none',
              paddingBottom: '20px',
              paddingLeft: isMobile && '14px',
            }}
          >
            <div className="d-flex align-items-start gap-2 pt-3">
              {isMobile && (
                <Menu
                  size={24}
                  className="cursor-pointer me-2"
                  onClick={() => setIsOpenSidebar(!isOpenSidebar)}
                />
              )}
              <BreadCrumbs breadCrumbActive={'Repo'} />
            </div>
            <Outlet context={[setSuccessTrx]} />
          </div>
        </>
      )}
    </div>
  )
}

export default CommodityRepo
