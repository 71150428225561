import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiEndpoint } from 'configs'
import { API_CONTENT_TUTORIAL, API_GROUP_LIST } from 'utils/apiList'

const getLearningReportList = createAsyncThunk(
  'learningReport/content',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/${API_GROUP_LIST}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getLearningContentList = createAsyncThunk(
  'learningReport/list',
  async ({ page, size, sortby, sortdir }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.get(
        `${apiEndpoint}/${API_CONTENT_TUTORIAL}?page=${page}&size=${size}${sortby ? `&sortby=${sortby}` : ''}${sortdir ? `&sortdir=${sortdir}` : ''}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getUserWatch = createAsyncThunk(
  'learningReport/list',
  async (userId, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/tutorial-watch?userId=${userId}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export { getLearningContentList, getLearningReportList, getUserWatch }
