import { createAppSlice } from 'app/createAppSlice'
import { FILTER_WHISTLE_BLOWING_OPTIONS } from 'constants'

const initialState = {
  tag: {
    size: 10,
    page: 0,
  },
  isOpenModalAssign: false,
  filters: {
    status: FILTER_WHISTLE_BLOWING_OPTIONS[0],
  },
}

export const whistleBlowingsListSlice = createAppSlice({
  name: 'whistleBlowingsList',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag.size = payload.size
      state.tag.page = payload.page
    }),
    setIsOpenModalAssign: create.reducer((state, { payload }) => {
      state.isOpenModalAssign = !state.isOpenModalAssign
    }),
    setFilters: create.reducer((state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
    }),
  }),
})

export const { setFetchOption, setIsOpenModalAssign, setFilters } =
  whistleBlowingsListSlice.actions

export default whistleBlowingsListSlice.reducer
