import change from 'assets/icons/change.svg'
import merge from 'assets/icons/merge.svg'

export const SECTIONSBCATRANSFER = [
  {
    title: 'Menggunakan ATM BCA',
    show: false,
    content: [
      'Masukkan kartu ATM dan PIN BCA kamu.',
      'Pilih menu “Transaksi Lainnya”.',
      'Pilih menu “Transfer”.',
      'Pilih “Ke Rek BCA”.',
      'Masukkan nomor rekening tujuan.',
      'Masukkan nominal transfer.',
      'Konfirmasi transfer.',
    ],
  },
  {
    title: 'Menggunakan BCA Mobile',
    show: false,
    content: [
      'Login ke akun BCA mobile kamu.',
      'Pilih menu “m-Transfer”.',
      'Pilih “Transfer Antar Rekening”',
      `Jika belum terdaftar, silakan daftarkan nomor rekeningnya terlebih dahulu.`,
      'Masukkan nominal transfer.',
      'Klik “Lanjut” dan selesaikan transfer.',
    ],
  },
  {
    title: 'Menggunakan myBCA',
    show: false,
    content: [
      'Login ke akun my BCA kamu.',
      'Pilih menu “Transaksi”.',
      'Pilih submenu “Transfer”.',
      'Masukkan nomor rekening tujuan.',
      'Masukkan nominal transfer.',
      'Klik “Lanjut”.',
      'Pastikan data transfer sudah benar, lalu pilih “Ya” untuk memverifikasi transaksi.',
      'Tunggu notifikasi transaksi berhasil.',
    ],
  },
  {
    title: 'Menggunakan KlikBCA',
    show: false,
    content: [
      'Kunjungi website ini KlikBCA.',
      'Login ke akun KlikBCA kamu.',
      'Pilih menu “Transfer Dana”.',
      'Pilih “Transfer ke Rekening BCA”.',
      'Pilih nomor rekening dari daftar, daftarkan jika belum.',
      'Masukkan nominal transfer.',
      'Aktifkan KeyBCA, masukkan pin dan tekan angka 2.',
      'Masukkan 8 digit dari Respon KeyBCA APPLI 2.',
      'Klik Lanjutkan.',
      'Tunggu informasi rekening tujuan.',
      'Nyalakan KeyBCA kembali.',
      'Klik angka 1.',
      'Tunggu 8 digit di kotak Respon KeyBCA APPLI 1.',
      'Pilih "Kirim".',
    ],
  },
]

export const menusStock2 = [
  {
    id: 5,
    title: 'Ubah Bentuk',
    // icon: 'vegetables',
    screen: '/manage-stock/edit',
    type: 'edit',
    editType: 'general',
    modal: 'edit',
    // iconType: 'img',
    img: change,
  },
]

export const menuStock = [
  {
    id: 3,
    title: 'Pisah',
    icon: 'copy',
    screen: '/manage-stock/split',
    modal: 'split',
    iconType: 'icon',
    icon: 'split',
    editType: 'split',
  },
  {
    id: 4,
    title: 'Gabung',
    icon: 'merge',
    screen: '/manage-stock/merge',
    modal: 'merge',
    iconType: 'img',
    img: merge,
    editType: 'merge',
  },
  {
    id: 5,
    title: 'Ubah',
    icon: 'vegetables',
    screen: '/manage-stock/edit',
    type: 'edit',
    editType: 'update',
    modal: 'edit',
    iconType: 'img',
    img: change,
  },
]

export const sortStock = [
  {
    id: 1,
    title: 'Nama stok (A-Z)',
    type: 'name',
    mode: 'asc',
  },
  {
    id: 2,
    title: 'Nama stok (Z-A)',
    type: 'name',
    mode: 'desc',
  },
  {
    id: 3,
    title: 'Stok terkecil',
    type: 'amount',
    mode: 'asc',
  },
  {
    id: 4,
    title: 'Stok terbesar',
    type: 'amount',
    mode: 'desc',
  },
]

export const itemUnitList = [
  {
    id: 1,
    title: 'Kg',
  },
  {
    id: 2,
    title: 'Pcs',
  },
  {
    id: 3,
    title: 'Buah',
  },
  {
    id: 4,
    title: 'Kardus',
  },
  {
    id: 5,
    title: 'Karung',
  },
  {
    id: 6,
    title: 'Ikat',
  },
  {
    id: 7,
    title: 'Peti',
  },
  {
    id: 8,
    title: 'Kotak',
  },
  {
    id: 9,
    title: 'Pak',
  },
  {
    id: 10,
    title: 'Lusin',
  },
  {
    id: 11,
    title: 'Liter',
  },
  {
    id: 12,
    title: 'Ekor',
  },
  {
    id: 13,
    title: 'Sak',
  },
]

export const WPP_OPTIONS = [
  { value: 'WPP-RI 571', label: 'WPP-RI 571' },
  { value: 'WPP-RI 572', label: 'WPP-RI 572' },
  { value: 'WPP-RI 573', label: 'WPP-RI 573' },
  { value: 'WPP-RI 711', label: 'WPP-RI 711' },
  { value: 'WPP-RI 712', label: 'WPP-RI 712' },
  { value: 'WPP-RI 713', label: 'WPP-RI 713' },
  { value: 'WPP-RI 714', label: 'WPP-RI 714' },
  { value: 'WPP-RI 715', label: 'WPP-RI 715' },
  { value: 'WPP-RI 716', label: 'WPP-RI 716' },
]

export const CERTIFICATION_OPTIONS = [
  { value: 'In FIP', label: 'In FIP' },
  { value: 'MSC Certified', label: 'MSC Certified' },
  { value: 'In Asssessment', label: 'In Asssessment' },
  { value: 'Fairtrade Certified', label: 'Fairtrade Certified' },
  { value: 'CPIB Certified', label: 'CPIB Certified' },
]

export const VESSEL_OTHER_STATUS_OPTIONS = [
  { value: 'nameChangedStatus', label: 'Name change' },
  { value: 'radioStatus', label: 'Radio' },
  { value: 'vmsChange', label: 'VMS Change' },
  { value: 'sharkPolicy', label: 'Shark policy' },
  { value: 'trackerStatus', label: 'Tracker' },
  { value: 'iuuList', label: 'IUU list' },
  { value: 'flagStateChange', label: 'Flag state change' },
  { value: 'codeOfConduct', label: 'Code of conduct' },
  { value: 'eLogBook', label: 'E-logbook' },
  { value: 'sticker', label: 'Sticker' },
]

export const FILTER_CATCHES_LIST_OPTIONS = [
  { value: '', label: 'Semua' },
  { value: 'approved', label: 'Terverifikasi' },
  { value: 'unapproved', label: 'Belum Terverifikasi' },
]

export const PROVINCES_OPTIONS = [
  { value: '', label: 'Semua' },
  { value: 'ACEH', label: 'ACEH' },
  { value: 'BALI', label: 'BALI' },
  { value: 'BANTEN', label: 'BANTEN' },
  { value: 'DI YOGYAKARTA', label: 'DI YOGYAKARTA' },
  { value: 'DKI JAKARTA', label: 'DKI JAKARTA' },
  { value: 'GORONTALO', label: 'GORONTALO' },
  { value: 'JAWA TENGAH', label: 'JAWA TENGAH' },
  { value: 'JAWA TIMUR', label: 'JAWA TIMUR' },
  { value: 'KALIMANTAN TIMUR', label: 'KALIMANTAN TIMUR' },
  { value: 'MALUKU', label: 'MALUKU' },
  { value: 'MALUKU UTARA', label: 'MALUKU UTARA' },
  { value: 'NUSA TENGGARA BARAT', label: 'NUSA TENGGARA BARAT' },
  { value: 'NUSA TENGGARA TIMUR', label: 'NUSA TENGGARA TIMUR' },
  { value: 'PAPUA', label: 'PAPUA' },
  { value: 'PAPUA BARAT', label: 'PAPUA BARAT' },
  { value: 'SULAWESI BARAT', label: 'SULAWESI BARAT' },
  { value: 'SULAWESI SELATAN', label: 'SULAWESI SELATAN' },
  { value: 'SULAWESI TENGAH', label: 'SULAWESI TENGAH' },
  { value: 'SULAWESI TENGGARA', label: 'SULAWESI TENGGARA' },
  { value: 'SULAWESI UTARA', label: 'SULAWESI UTARA' },
  { value: 'SUMATERA BARAT', label: 'SUMATERA BARAT' },
  { value: 'SUMATERA UTARA', label: 'SUMATERA UTARA' },
]

export const STATUS_TYPE = {
  ASSIGNED: 'ASSIGNED',
  RESOLVED: 'RESOLVED',
  UNRESOLVED: 'UNRESOLVED',
}

export const monthsOptions = [
  { value: '01', label: 'Januari' },
  { value: '02', label: 'Februari' },
  { value: '03', label: 'Maret' },
  { value: '04', label: 'April' },
  { value: '05', label: 'Mei' },
  { value: '06', label: 'Juni' },
  { value: '07', label: 'Juli' },
  { value: '08', label: 'Agustus' },
  { value: '09', label: 'September' },
  { value: '10', label: 'Oktober' },
  { value: '11', label: 'November' },
  { value: '12', label: 'Desember' },
]

export const wppOptions = [
  { value: '', label: 'Semua' },
  { value: '571', label: 'WPP 571' },
  { value: '572', label: 'WPP 572' },
  { value: '573', label: 'WPP 573' },
  { value: '711', label: 'WPP 711' },
  { value: '712', label: 'WPP 712' },
  { value: '713', label: 'WPP 713' },
  { value: '714', label: 'WPP 714' },
  { value: '715', label: 'WPP 715' },
  { value: '716', label: 'WPP 716' },
  { value: '717', label: 'WPP 717' },
  { value: '718', label: 'WPP 718' },
]

export const filterDate = [
  {
    id: 1,
    name: 'Semua tanggal',
  },
  {
    id: 2,
    name: 'Hari ini',
  },
  {
    id: 3,
    name: '7 hari terakhir',
  },
  {
    id: 4,
    name: '30 hari terakhir',
  },
  {
    id: 5,
    name: 'Pilih tanggal sendiri',
  },
]

export const filterTransaction = [
  {
    id: 1,
    name: 'Tambah Uang Belanja',
    filterKey:
      '0020,8020,0023,9020,topupDepositsByIdentity,setUserDepositsDirectly,sendLenderDepositsToUser,setLenderDepositsDirectly,0021,0022',
  },
  {
    id: 2,
    name: 'Pembayaran Tempo',
    filterKey: '1030,1051',
  },
  {
    id: 3,
    name: 'Biaya Perpanjangan',
    filterKey: '1031',
  },
  {
    id: 4,
    name: 'Denda Keterlambatan',
    filterKey: '1032',
  },
  {
    id: 5,
    name: 'Pengembalian Dana',
    filterKey: '9045,1053,1054,1055',
  },
  {
    id: 6,
    name: 'Pembayaran Utang',
    filterKey: '8030',
  },
  {
    id: 7,
    name: 'Biaya Bayar Instan',
    filterKey: '8040',
  },
  {
    id: 8,
    name: 'Pengembalian Bayar Instan',
    filterKey: '8042',
  },
  {
    id: 9,
    name: 'Pembayaran Tunai',
    filterKey: '1040,1050,1052',
  },
  {
    id: 10,
    name: 'PPN',
    filterKey: '1056,1058',
  },
  {
    id: 11,
    name: 'Penyesuaian Harga',
    filterKey: '1057,1059',
  },
]

export const FILTER_WHISTLE_BLOWING_OPTIONS = [
  { value: undefined, label: 'Semua' },
  { value: 'open', label: 'Open' },
  { value: 'resolved', label: 'Resolved' },
]

export const AP2HI_YEAR_START = 2024
