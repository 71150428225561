import PMBSelect from 'components/PMBSelect'
import SidebarDetails from 'components/SidebarDetails'
import {
  FILTER_CATCHES_LIST_OPTIONS,
  PROVINCES_OPTIONS,
  WPP_OPTIONS,
  wppOptions,
} from 'constants'
import { useState } from 'react'
import { Calendar } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useDispatch } from 'react-redux'
import { Button, FormGroup, Label } from 'reactstrap'
import { setFilters } from '../catchesListSlice'

const SidebarFilter = ({
  open,
  toggle,
  handleFilter,
  selectedDate,
  setSelectedDate,
  selectedWPP,
  setCurrentPage,
  setRowsPerPage,
}) => {
  const dispatch = useDispatch()

  const [tempSelectedDate, setTempSelectedDate] = useState(selectedDate)
  const [tempSelectedWPP, setTempSelectedWPP] = useState(selectedWPP)

  const currentYear = new Date().getFullYear()
  const startDateDefault = new Date(currentYear, 0, 1)
  const endDateDefault = new Date(currentYear, 11, 31)
  const WPPOptions = [{ value: '', label: 'Semua' }, ...WPP_OPTIONS]

  const handleReset = () => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setTempSelectedWPP({ value: '', label: 'Semua' })
    setTempSelectedDate([startDateDefault, endDateDefault])

    // Reset filters in Redux
    dispatch(
      setFilters({
        status: FILTER_CATCHES_LIST_OPTIONS[0],
        province: PROVINCES_OPTIONS[0],
        selectedDate: [startDateDefault, endDateDefault],
        selectedWPP: wppOptions[0],
      }),
    )

    handleFilter()
  }

  const handleSubmit = () => {
    setCurrentPage(0)
    setRowsPerPage(10)

    setSelectedDate(tempSelectedDate)

    // Update Redux filters
    dispatch(
      setFilters({
        selectedDate: tempSelectedDate,
        selectedWPP: tempSelectedWPP,
      }),
    )

    toggle()
  }

  return (
    <SidebarDetails
      open={open}
      size="lg"
      title="Filter"
      headerClassName="mb-1 justify-content-between"
      contentClassName="py-0"
      toggleSidebar={toggle}
    >
      <div
        style={{ minHeight: '90vh' }}
        className="d-flex flex-column justify-content-between"
      >
        <div className="my-4">
          {/* Date Range Filter */}
          <FormGroup>
            <Label>Tanggal Mulai & Akhir</Label>
            <div className="d-flex align-items-center justify-content-center border rounded">
              <Calendar size={17} className="ms-3" />
              <Flatpickr
                options={{
                  mode: 'range',
                  dateFormat: 'd-m-Y',
                  defaultDate: tempSelectedDate,
                  onClose: (selectedDates) => {
                    setTempSelectedDate(selectedDates)
                  },
                }}
                className="form-control flat-picker bg-transparent border-0 shadow-none"
              />
            </div>
          </FormGroup>

          {/* WPP Filter */}
          <FormGroup>
            <Label className="text-sm">WPP-RI</Label>
            <PMBSelect
              value={tempSelectedWPP}
              onChange={(e) => setTempSelectedWPP(e)}
              options={[{ value: '', label: 'Semua' }, ...WPP_OPTIONS]}
              placeholder="Pilih WPP"
            />
          </FormGroup>
        </div>

        {/* Buttons */}
        <div className="d-flex gap-2">
          <Button onClick={handleReset} color="primary" outline block>
            Reset Filter
          </Button>
          <Button onClick={handleSubmit} color="primary" block>
            Terapkan
          </Button>
        </div>
      </div>
    </SidebarDetails>
  )
}

export default SidebarFilter
