import { HistoryLine } from 'components'
import LoadingV2 from 'components/LoadingV2'
import PMBPagination from 'components/PMBPagination'
import BreadCrumbs from 'components/breadcrumbs'
import { parseExtraData } from 'helpers'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { getLogs } from '../API/ap2hiActions'
import { SidebarLogs } from '../Components'
import './style.css'

export default function Logs() {
  const dispatch = useDispatch()
  const [modal, setModal] = useState({
    detail: false,
  })
  const [logsList, setLogsList] = useState([])
  const [selectedLog, setSelectedLog] = useState({})
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = useState(false)
  const [containerHeight, setContainerHeight] = useState('65vh')

  useEffect(() => {
    const updateHeight = () => {
      const newHeight = '65vh'
      setContainerHeight(newHeight)
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)

    return () => {
      window.removeEventListener('resize', updateHeight)
    }
  }, [])

  useEffect(() => {
    if (currentPage !== null && rowsPerPage !== null) {
      setLoading(true)
      fetchLogs(currentPage, rowsPerPage)
    }
  }, [currentPage, rowsPerPage])

  const fetchLogs = (page, size, search, sortBy, asc) => {
    setLoading(true)
    dispatch(
      getLogs({
        page: page,
        size: size,
        search: search,
        sortBy: sortBy,
        asc: asc,
      }),
    )
      .then((res) => {
        const logs = res?.payload?.items?.map((item) => {
          if (item.extraData) {
            item.parsedExtraData = parseExtraData(item?.extraData)
          }
          return item
        })
        setCurrentPage(res?.payload?.currentPage)
        setTotalPages(res?.payload?.totalPages)
        setLogsList(logs)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Log History" />
      </div>

      <Card className="mb-3 border-0 shadow-sm">
        <CardBody>
          <div>
            <h5 className="fw-semibold mb-3">Log History</h5>
          </div>
          <div
            style={{
              height: containerHeight,
              overflow: 'hidden',
            }}
          >
            <div
              className="hide-scrollbar"
              style={{
                height: '100%',
                overflowY: 'auto',
              }}
            >
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '100%' }}
                >
                  <LoadingV2 />
                </div>
              ) : (
                <div className="mt-2 container-fluid">
                  {Array.isArray(logsList) && logsList.length > 0 ? (
                    <HistoryLine logs={logsList} showBorder={false} />
                  ) : (
                    <p className="my-3 fw-semibold">
                      Tidak terdapat catatan yang tersedia.
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>

          {logsList?.length > 0 && (
            <PMBPagination
              rowCount={totalPages || 0}
              currentPage={currentPage}
              rowsPerPage={rowsPerPage}
              setCurrentPage={(page) => {
                setLoading(true)
                setCurrentPage(page)
              }}
              setRowsPerPage={(rows) => {
                setLoading(true)
                setRowsPerPage(rows)
              }}
            />
          )}
        </CardBody>
      </Card>
      {renderModal()}
    </div>
  )

  function renderModal() {
    return (
      <SidebarLogs
        isOpen={modal?.detail}
        toggle={() => setModal({ detail: false })}
        logs={[selectedLog]}
      />
    )
  }
}
