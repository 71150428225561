import { createAppSlice } from 'app/createAppSlice'
import { getLearningReportList } from './learningReportAction'

const initialState = {
  reportList: [],
  tag: {
    size: 10,
    page: 0,
  },
  tagUsers: {},
}

export const learningReportSlice = createAppSlice({
  name: 'learningReport',
  initialState,
  reducers: (create) => ({
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag = { ...state.tag, ...payload }
    }),
    setFetchOptionUser: create.reducer((state, { payload }) => {
      const { userId, options } = payload
      state.tagUsers[userId] = {
        ...state.tagUsers[userId],
        ...options,
      }
    }),
    initTagUsers: create.reducer((state, { payload }) => {
      state.tagUsers = { ...state.tagUsers, ...payload }
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getLearningReportList.pending, (state) => {
        state.loading = true
      })
      .addCase(getLearningReportList.fulfilled, (state, { payload }) => {
        state.loading = false
        state.reportList = payload
      })
      .addCase(getLearningReportList.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})

export const { setFetchOption, setFetchOptionUser, initTagUsers } =
  learningReportSlice.actions

export default learningReportSlice.reducer
