import { menuStock } from 'constants'
import { Copy, Trash2, X } from 'react-feather'
import { Sheet } from 'react-modal-sheet'
import { CardText } from 'reactstrap'
import '../../styles.css'

const ActionModal = ({ isOpen, onClose, onSelectMenu }) => {
  return (
    <Sheet
      isOpen={isOpen}
      initialSnap={1}
      snapPoints={[400, 300, 200, 100, 0]}
      onClose={onClose}
    >
      <Sheet.Container>
        <Sheet.Content>
          <div className="small-padding border-bottom d-flex justify-content-between align-items-center">
            <CardText className="fw-semibold no-padding">Aksi Lainnya</CardText>
            <X onClick={onClose} className="button" />
          </div>
          {menuStock?.map((el, i) => {
            return (
              <div
                key={`${i}-${el?.title}`}
                className="mb-2 mt-2 pl-2 button d-flex align-items-center"
                onClick={() => onSelectMenu(el)}
              >
                {el?.iconType === 'img' ? (
                  <img
                    src={el?.img}
                    style={{ width: 15, height: 15, marginRight: 10 }}
                  />
                ) : el?.iconType === 'icon' && el?.icon === 'split' ? (
                  <Copy size={15} style={{ marginRight: 10 }} />
                ) : (
                  <Trash2 size={15} style={{ marginRight: 10 }} />
                )}
                <CardText>{el?.title}</CardText>
              </div>
            )
          })}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  )
}

export default ActionModal
