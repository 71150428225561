import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Spinner,
} from 'reactstrap'
import { useCreateTransactionNoteMutation } from '../dashboardApiSlice'

const ModalTransactionNote = ({
  open,
  transactionNoteData,
  toggle,
  handleRefetch,
  isEdit,
}) => {
  const [createTransactionNote, { isLoading: isLoadingStatusMutation }] =
    useCreateTransactionNoteMutation()

  const inputRef = useRef(null)
  const [notes, setNotes] = useState('')

  const resetState = () => {
    setNotes('')
  }

  const { enqueueSnackbar } = useSnackbar()

  // HANDLE SUBMIT
  const handleSubmit = async () => {
    try {
      const noteKey = !transactionNoteData?.buyerNoteHide
        ? 'buyerNote'
        : !transactionNoteData?.sellerNoteHide
          ? 'sellerNote'
          : null

      if (!noteKey) {
        enqueueSnackbar('Tidak ada catatan yang dapat dikirim.', {
          variant: 'error',
        })
        return
      }

      const body = {
        ...(transactionNoteData?.escrowpaymentId
          ? { escrowpaymentId: transactionNoteData?.escrowpaymentId }
          : { tradeshortcutId: transactionNoteData?.tradeshortcutId || null }),
        [noteKey]: notes,
      }

      await createTransactionNote(body).unwrap()

      enqueueSnackbar('Catatan transaksi berhasil ditambahkan!', {
        variant: 'success',
      })

      resetState()
      toggle()
      handleRefetch()
    } catch (error) {
      console.warn(error)

      const errorMessage =
        error?.data?.message ??
        error?.message ??
        'Terjadi kesalahan saat menambahkan catatan transaksi.'
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  // HANDLE AUTO FOCUS IN TEXT AREA
  useEffect(() => {
    if (open) {
      setTimeout(() => inputRef.current?.focus(), 100)
    }
  }, [open])

  // HANDLE EDIT
  useEffect(() => {
    if (open) {
      setTimeout(() => inputRef.current?.focus(), 100)
    }

    if (open && isEdit) {
      if (!transactionNoteData?.buyerNoteHide) {
        setNotes(transactionNoteData?.buyerNote ?? '')
      } else {
        setNotes(transactionNoteData?.sellerNote ?? '')
      }
    }
  }, [open, isEdit, transactionNoteData])

  return (
    <Modal
      className="p-4"
      centered
      isOpen={open}
      toggle={() => {
        toggle()
      }}
      autoFocus={false}
    >
      <ModalBody>
        <FormGroup>
          <div className="mb-2">
            <h6>Tambah Catatan Transaksi</h6>
          </div>

          <Label className="text-sm">Catatan transaksi</Label>

          <div
            style={{
              width: '100%',
              border: '1px solid #d1d5db',
              borderRadius: '4px',
            }}
          >
            <Input
              innerRef={inputRef}
              style={{
                border: 'none',
                minHeight: 128,
                borderRadius: '4px',
                padding: '8px',
                boxSizing: 'border-box',
              }}
              value={notes}
              className="border-input"
              type="textarea"
              placeholder="Masukkan catatan transaksi..."
              onChange={(e) => {
                const value = e.target.value
                if (value.trim() !== '') {
                  setNotes(value)
                } else {
                  setNotes('')
                }
              }}
            />
          </div>
        </FormGroup>

        <div className="d-flex gap-3">
          <Button
            onClick={() => {
              toggle()
              resetState()
            }}
            className="btn-cancel"
            outline
            block
          >
            Batal
          </Button>
          <Button
            disabled={isLoadingStatusMutation || notes === ''}
            color="primary"
            block
            onClick={handleSubmit}
          >
            {isLoadingStatusMutation && <Spinner size="sm" className="me-2" />}{' '}
            Simpan
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalTransactionNote
