import { AlertCircle, ChevronDown } from 'react-feather'
import { Card, CardText, Input, InputGroup, InputGroupText } from 'reactstrap'
import InventoryCodeInput from '../Input/InventoryCodeInput'

export default function SplitStockCard({
  item,
  index,
  onShowItem,
  setIndexSelectedSplitStock,
  onChangeValue,
  selectedStock,
}) {
  const handleInventoryCodeChange = (code, index) => {
    onChangeValue('ownerCode', code, index)
  }

  return (
    <Card className="small-padding border-0 shadow-sm">
      <CardText className="fw-semibold no-padding">Bagian {index + 1}</CardText>

      <div className="mt-2">
        <CardText className="no-padding">Kode inventori (opsional)</CardText>
        <InventoryCodeInput
          value={item?.ownerCode || ''}
          onChange={(text) => handleInventoryCodeChange(text, index)}
        />
      </div>

      <div className="bordered mt-2 d-flex justify-content-between align-items-center">
        <Input
          style={{ borderColor: 'white', caretColor: 'transparent' }}
          className="border-input button"
          placeholder="Pilih komoditas"
          disabled
          // onClick={() => {
          //   setIndexSelectedSplitStock(index)
          //   onShowItem('item', true)
          // }}
          value={selectedStock?.item?.nameItem}
        />
        {/* <ChevronRight color="#667085" style={{ paddingRight: 10 }} /> */}
      </div>
      <div className="d-flex justify-content-between">
        <div className="mt-2" style={{ width: '49%' }}>
          <div
            className={`${!item?.amount || item?.amount < 1 ? 'bordered-warning' : 'bordered'} d-flex justify-content-between align-items-center`}
          >
            <InputGroup>
              <Input
                value={item?.amount}
                placeholder="Jumlah"
                style={{ borderColor: 'white' }}
                onChange={(text) =>
                  onChangeValue('amount', text.target.value, index)
                }
              />
              {!item?.amount || item?.amount < 1 ? (
                <InputGroupText
                  style={{ backgroundColor: 'white', borderColor: 'white' }}
                >
                  <AlertCircle size={14} color="#F04438" />
                </InputGroupText>
              ) : null}
            </InputGroup>
          </div>
          {!item?.amount || item?.amount < 1 ? (
            <div className="mt-2">
              <CardText style={{ color: '#F04438', fontSize: 14 }}>
                Tidak boleh kosong saat memisah
              </CardText>
            </div>
          ) : null}
        </div>
        <div className="mt-2" style={{ width: '49%' }}>
          <div className="bordered d-flex justify-content-between align-items-center disabled">
            <Input
              style={{ borderColor: '#e9ecef', caretColor: 'transparent' }}
              className="border-input button"
              placeholder="Kg"
              value={item?.unit}
              disabled
            />
            <ChevronDown color="#667085" style={{ paddingRight: 10 }} />
          </div>
        </div>
      </div>
    </Card>
  )
}
