import ILDefaultAva from 'assets/icons/default-ava-profile-user.svg'
import Flatpickr from 'react-flatpickr'
import { useDispatch, useSelector } from 'react-redux'
import {
  Badge,
  Card,
  CardBody,
  CardText,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap'

// Form
import CustomButton from 'components/Buttons/CustomButton'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import { useEffect, useState } from 'react'
import { AlertCircle, Calendar } from 'react-feather'
import { emailCheck } from 'utils/emailCheck'
import ModalUnregis from '../ModalUnregis'
import { checkSellerInfo } from '../sellCommodityAction'
import {
  setInitialBuyer,
  setTrxDate,
  toggleModalUnregis,
} from '../sellCommoditySlice'

const InfoSeller = () => {
  const dispatch = useDispatch()
  const [dataSeller, setDataSeller] = useState('')
  const [savedContacts, setSavedContacts] = useState([])

  const { profile } = useSelector((s) => s.dashboard)
  const { orders } = useSelector((state) => state.sellCommodity)
  const { buyer } = orders

  const [errorMessage, setErrorMessage] = useState('')

  const handleSubmit = (e, phone) => {
    e.preventDefault()
    if (phone) {
      setErrorMessage('')
      dispatch(
        checkSellerInfo({ search: encodeURIComponent(phone), type: 'SELL' }),
      ).then((e) => {
        if (e?.payload?.profileStatus === 'UNREGISTERED') {
          dispatch(toggleModalUnregis())
        }
        const { error } = e
        if (error?.message == 'Rejected') {
          setErrorMessage(e?.payload)
        }
      })
    }
  }

  let isEmail = emailCheck(buyer?.fullName)

  useEffect(() => {
    if (profile?.userId) {
      const saved = JSON.parse(
        localStorage.getItem(`savedContacts-${profile?.userId}`),
      )
      setSavedContacts(saved ?? [])
    }
  }, [profile])

  return (
    <>
      <ModalUnregis data={dataSeller} />
      <Card className="border-0 shadow-sm">
        <CardBody className="p-4">
          <Row>
            <Col sm="12">
              <h5 className="text-md">Penjualan</h5>
              <FormGroup className="mt-4">
                <Label>Tanggal Transaksi</Label>
                <div className="d-flex align-items-center justify-content-center border rounded">
                  <Calendar size={17} className="ms-3" />
                  <Flatpickr
                    options={{
                      locale: Indonesian,
                      dateFormat: 'd-M-Y',
                      defaultDate: new Date(),
                      maxDate: new Date(),
                      onClose: (selectedDate, dateStr, instance) => {
                        dispatch(setTrxDate(selectedDate[0]))
                      },
                    }}
                    className="form-control flat-picker bg-transparent border-0 shadow-none"
                  />
                </div>
              </FormGroup>
              <Form onSubmit={(e) => handleSubmit(e, dataSeller)}>
                <FormGroup>
                  <Label for="phone" className="text-sm">
                    Jual ke
                  </Label>
                  <InputGroup>
                    <Input
                      name="phone"
                      placeholder="Masukkan nomor handphone pembeli"
                      type="text"
                      className="text-normal"
                      value={dataSeller}
                      onChange={(e) => {
                        dispatch(setInitialBuyer(null))
                        setErrorMessage('')
                        let value = e.target.value.trim()
                        if (value.startsWith('+')) {
                          value = value.slice(1)
                        }
                        setDataSeller(value)
                      }}
                    />
                    <CustomButton
                      color="transparent"
                      className="border text-normal"
                      type="submit"
                    >
                      Cek
                    </CustomButton>
                  </InputGroup>
                  <span className="text-xs">
                    Contoh: 0813xxxx atau 62813xxxx
                  </span>
                </FormGroup>
              </Form>
              {errorMessage && (
                <FormGroup>
                  <small className="text-danger">
                    <AlertCircle size={15} /> {errorMessage}
                  </small>
                </FormGroup>
              )}
            </Col>
            <Col sm="12">
              {buyer ? (
                <CardBody className="py-0">
                  <CardText tag="h6" className="text-sm fw-semibold">
                    Informasi pembeli
                  </CardText>
                  <Card>
                    <CardBody>
                      <div className="flex-centered justify-content-between">
                        <div className="flex-centered gap-1 ">
                          <img
                            src={buyer.profileImg ?? ILDefaultAva}
                            alt="ava"
                            width={36}
                            height={35}
                            className="rounded-circle"
                          />
                          <span className="text-sm fw-semibold">
                            {buyer?.fullName}
                          </span>
                        </div>
                        {isEmail ? (
                          <></>
                        ) : (
                          <Badge
                            size="sm"
                            disabled
                            className={
                              buyer?.profileStatus.toLowerCase() === 'verified'
                                ? 'badge-verify'
                                : 'badge-warning'
                            }
                          >
                            {buyer?.profileStatus}
                          </Badge>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </CardBody>
              ) : (
                <CardBody className="py-0">
                  <div className="d-flex gap-2">
                    {savedContacts?.map((e, i) => {
                      return (
                        <div
                          key={i}
                          onClick={(z) => {
                            setDataSeller(e?.phoneNumber)
                            handleSubmit(z, e?.phoneNumber)
                          }}
                          className="rounded p-3 bg-gray-50 hover-100 pointer"
                        >
                          {e?.fullName}
                        </div>
                      )
                    })}{' '}
                  </div>
                </CardBody>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default InfoSeller
