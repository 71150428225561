import BreadCrumbs from 'components/breadcrumbs'
import LoadingV2 from 'components/LoadingV2'
import PMBModalImagePreview from 'components/PMBModalImagePreview'
import PMBPagination from 'components/PMBPagination'
import PMBProgressBarExportFile from 'components/PMBProgressBarExportFile'
import PMBSearchInputGroup from 'components/PMBSearchInputGroup'
import PMBSelect from 'components/PMBSelect'
import PMBTooltip from 'components/PMBTooltip'
import { FILTER_CATCHES_LIST_OPTIONS, PROVINCES_OPTIONS } from 'constants'
import { Can } from 'context/Acl'
import { useDebounce } from 'helpers'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Check, ChevronDown, Eye, Filter } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Badge,
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap'
import { formatDateOnlyPMB } from 'utils/formatDate'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { BUSINESS_TRIPS, MANAGE } from 'utils/subjectActions'
import IconApprove from '../../../../assets/icons/icon-approve.svg'
import IconNotApprove from '../../../../assets/icons/icon-unapprove.svg'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import { useFetchCatchesListQuery } from './catchesListApiSlice'
import {
  setFilters,
  setIsOpenModalApprove,
  setToggleSidebarFilter,
} from './catchesListSlice'
import { handleDownloadCSV, handleDownloadJSON } from './DownloadReport'
import ModalApprove from './ModalApprove'
import SidebarFilter from './SidebarFilter'

const CatchesTable = () => {
  const { isOpenSidebarFilter, isOpenModalApprove, filters } = useSelector(
    (s) => s.catchesList,
  )

  const manageActionApproveBusinessTrips = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_TRIPS,
  })

  const menuItems = [
    {
      label: 'Download as CSV',
      action: () => {
        handleDownloadCSV(data, setIsDialogShow, setFirstRows, setTotalRows)
      },
    },
    // {
    //   label: 'Download as Excel',
    //   action: () => {
    //     handleDownloadExcel(data, setIsDialogShow, setFirstRows, setTotalRows)
    //   },
    // },
    {
      label: 'Download as JSON',
      action: () => {
        handleDownloadJSON(data, setIsDialogShow, setFirstRows, setTotalRows)
      },
    },
  ]

  const currentYear = new Date().getFullYear()
  const startDateDefault = new Date(currentYear, 0, 1)
  const endDateDefault = new Date(currentYear, 11, 31)

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchInput, setSearchInput] = useState('')
  const [search, setSearch] = useState('')

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [modalOpenImage, setModalOpenImage] = useState(false)
  const [imageItems, setImagesItems] = useState([])
  const [isNoDataFilterAvailable, setIsNoDataFilterAvailable] = useState(false)
  // STATE DOWNLOAD REPORT
  const [isDialogShow, setIsDialogShow] = useState(false)
  const [firstRows, setFirstRows] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [detailRow, setDetailRow] = useState(null)

  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('startfishing')
  const [sortDirection, setSortDirection] = useState('DESC')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const timeZone = 'Asia/Jakarta'

  const { data, isLoading, refetch, isFetching } = useFetchCatchesListQuery({
    page: currentPage ?? 0,
    size: rowsPerPage ?? 10,
    s: search ?? '',
    from: filters?.selectedDate[0]
      ? moment(filters?.selectedDate[0])
          .utcOffset('+07:00')
          .startOf('day')
          .format()
      : undefined,
    to: filters?.selectedDate[1]
      ? moment(filters?.selectedDate[1])
          .utcOffset('+07:00')
          .endOf('day')
          .format()
      : undefined,
    filterwpp: filters?.selectedWPP?.value?.match(/\d+/)?.[0] ?? '',
    filterapprove: filters?.status?.value ?? '',
    filterdistrict: filters?.province?.value ?? '',
    sortby: sortColumn ?? 'startfishing',
    sortdir: sortDirection ?? 'DESC',
  })

  // HANDLE LOADING FILTER
  useEffect(() => {
    if (!isFetching && data?.totalItems === 0) {
      setIsNoDataFilterAvailable(true)
    } else {
      setIsNoDataFilterAvailable(false)
    }
  }, [isFetching, data?.totalItems])

  const debouncedSearch = useDebounce((value) => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setSearch(value)
    refetch()
  }, 600)

  const handleSearchChange = (value) => {
    setSearchInput(value ?? '')
    debouncedSearch(value ?? '')
  }

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState)
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction === 'asc' ? 'ASC' : 'DESC')
  }

  const onHandleOpenImageModal = (imageUrls) => {
    setImagesItems([imageUrls])
    setModalOpenImage(true)
  }

  const columns = [
    {
      name: <span>Tanggal Perjalanan</span>,
      selector: 'startfishing',
      sortable: true,
      cell: (row) => {
        const startDate = formatDateOnlyPMB(row?.data?.startFishing)
        const endDate = formatDateOnlyPMB(row?.data?.endFishing)
        return (
          <span>
            {startDate ?? ''} - {endDate ?? ''}
          </span>
        )
      },
    },
    {
      name: <span>Kapal</span>,
      selector: 'shipname',
      sortable: true,
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            <img
              src={row?.data?.vessel?.linkImage ?? VesselNotFound}
              alt={row?.data?.vessel?.vesselName ?? 'vessel'}
              width={32}
              height={32}
              className="rounded-circle"
              onClick={() =>
                onHandleOpenImageModal(
                  row?.data?.vessel?.linkImage ?? VesselNotFound,
                )
              }
              style={{ cursor: 'pointer' }}
            />
            <span className="fw-bold text-primary m-0">
              {row?.data?.vessel?.shipName}
            </span>
          </div>
        )
      },
    },
    {
      name: <span>Nama Nelayan</span>,
      selector: 'name',
      sortable: true,
      cell: (row) => {
        return <span>{row?.profile?.name ?? ''}</span>
      },
    },
    {
      name: <span>Hasil Tangkapan</span>,
      selector: 'fishname',
      sortable: true,
      cell: (row) => {
        const fishes = row?.data?.fishes ?? []

        const firstFish = fishes[0]
        const firstFishName = firstFish?.selectedFish?.nameItem
        const firstFishCode = firstFish?.selectedFish?.attribute?.code ?? ''
        const firstFishAmount = firstFish?.amount ?? 0

        const additionalFishesCount =
          fishes?.length > 1 ? fishes?.length - 1 : 0

        const unit = fishes?.[0]?.selectedFish?.unit ?? 'kg'

        return (
          <div>
            <div className="my-1">
              <span className="fw-semibold">
                {firstFishName} <em>({firstFishCode})</em>
              </span>
            </div>
            <div className="my-1">
              <span className="text-primary">
                {newFormatThousandSeparator(firstFishAmount)} {unit}
              </span>
            </div>

            {additionalFishesCount > 0 && (
              <Badge className="badge-gray my-1">
                + {additionalFishesCount} tangkapan lainnya
              </Badge>
            )}
          </div>
        )
      },
    },
    {
      name: <span>Total Penangkapan (kg)</span>,
      cell: (row) => {
        const unit = row?.data?.fishes?.[0]?.selectedFish?.unit ?? 'kg'
        const totalAmount = row.data.fishes.reduce(
          (sum, fish) => Number(sum) + Number(fish.amount),
          0,
        )

        return (
          <span>
            {newFormatThousandSeparator(totalAmount)} {unit}
          </span>
        )
      },
    },
    {
      name: <span>WPP</span>,
      selector: 'wpp',
      sortable: true,
      width: '120px',
      cell: (row) => {
        const catchAreas = [
          ...new Set(row?.data?.fishes?.map((fish) => fish?.catchArea)),
        ].join(', ')

        return <span>{catchAreas}</span>
      },
    },
    {
      name: <span>Status</span>,
      cell: (row) => {
        // HANDLE ABILITY AND APPROVE
        if (row?.data?.approved === true) {
          return (
            <>
              <img
                src={IconApprove}
                id={`approve-${row?.id}`}
                alt={`approve-${row?.id}`}
                loading="lazy"
              />
              <PMBTooltip target={`approve-${row?.id}`} text="Terverfikasi" />
            </>
          )
        }

        return (
          <>
            <img
              src={IconNotApprove}
              id={`not-approve-${row?.id}`}
              alt={`not-approve-${row?.id}`}
              loading="lazy"
            />
            <PMBTooltip
              target={`not-approve-${row?.id}`}
              text="Belum Terverifikasi"
            />
          </>
        )
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <div className="d-flex gap-4">
            <div id={`icon-lihat-detail-${row?.id}`}>
              <Eye
                size={18}
                onClick={() => {
                  navigate(`/catches/${row?.id}`)
                }}
                style={{
                  cursor: 'pointer',
                }}
              />
            </div>
            <PMBTooltip
              target={`icon-lihat-detail-${row?.id}`}
              text="Lihat Detail"
            />

            {manageActionApproveBusinessTrips && !row?.data?.approved && (
              <>
                <div id={`icon-verifikasi-${row?.id}`}>
                  <Check
                    size={18}
                    onClick={() => {
                      dispatch(setIsOpenModalApprove())
                      setDetailRow(row)
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </div>
                <PMBTooltip
                  target={`icon-verifikasi-${row?.id}`}
                  text="Verifikasi"
                />
              </>
            )}
          </div>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
      },
    },
    rows: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
        '&:hover': {
          backgroundColor: '#F9FAFB',
        },
      },
    },
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  const handleRefetch = () => {
    refetch()
  }

  console.log({ filters })

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Daftar Perjalanan" />
      </div>

      <SidebarFilter
        open={isOpenSidebarFilter}
        handleFilter={() => {
          setCurrentPage(0)
          setRowsPerPage(10)
          dispatch(setToggleSidebarFilter())
        }}
        toggle={() => dispatch(setToggleSidebarFilter())}
        selectedDate={filters.selectedDate}
        setSelectedDate={(newDate) => {
          dispatch(setFilters({ selectedDate: newDate }))
        }}
        handleSearchChange={handleSearchChange}
        selectedWPP={filters.selectedWPP}
        setSelectedWPP={(newWPP) => {
          dispatch(setFilters({ selectedWPP: newWPP }))
        }}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
      />
      {/* MODAL APPROVE */}
      <ModalApprove
        open={isOpenModalApprove}
        toggle={() => dispatch(setIsOpenModalApprove())}
        row={detailRow}
        handleRefetch={handleRefetch}
      />

      {/* MODAL IMAGE PREVIEW */}
      <PMBModalImagePreview
        isOpen={modalOpenImage}
        toggle={() => setModalOpenImage(!modalOpenImage)}
        images={imageItems}
      />

      <Card className="mb-4 border-0 shadow-sm">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading || isFetching}
            responsive
            persistTableHead
            defaultSortFieldId={1}
            defaultSortAsc={false}
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner color="primary" />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                {isNoDataFilterAvailable
                  ? 'Tidak ada data yang sesuai dengan filter Anda.'
                  : 'Belum ada daftar perjalanan yang didaftarkan.'}
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={data?.totalItems || 0}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
                props={props}
              />
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Perjalanan</h5>

                  <Can I={MANAGE} this={BUSINESS_TRIPS}>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                      <DropdownToggle
                        caret
                        className="btn btn-primary btn-outline btn-sm"
                        color="primary"
                      >
                        Download
                      </DropdownToggle>
                      <DropdownMenu>
                        {menuItems.map((item, index) => (
                          <DropdownItem key={index} onClick={item.action}>
                            <span className="text-sm">{item.label}</span>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </Can>
                </div>

                <div className="row my-4">
                  {/* Group the inputs */}
                  <div className="col-lg-9 col-md-8 col-sm-12">
                    <div className="row g-3">
                      {/* SEARCH */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <PMBSearchInputGroup
                          label={'Cari'}
                          value={searchInput}
                          placeholder="Cari nelayan, kapal, ikan, dll..."
                          onChange={(e) => handleSearchChange(e.target.value)}
                          maxWidth="100%"
                        />
                      </div>

                      {/* STATUS */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <PMBSelect
                          title="Status"
                          value={filters?.status}
                          options={FILTER_CATCHES_LIST_OPTIONS}
                          onChange={(item) => {
                            setCurrentPage(0)
                            setRowsPerPage(10)
                            dispatch(setFilters({ status: item }))
                          }}
                          style={{ width: '100%' }}
                        />
                      </div>

                      {/* PROVINCE */}
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <PMBSelect
                          title="Provinsi"
                          value={filters?.province}
                          options={PROVINCES_OPTIONS}
                          onChange={(item) => {
                            setCurrentPage(0)
                            setRowsPerPage(10)
                            dispatch(setFilters({ province: item }))
                          }}
                          style={{ width: '100%' }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* FILTER BUTTON */}
                  <div className="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-lg-end justify-content-start align-items-center mt-3 mt-lg-4">
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        border: '1px solid #0b6e99',
                        color: '#0b6e99',
                        backgroundColor: 'white',
                        padding: '8px',
                      }}
                      size="sm"
                      outline
                      onClick={() => dispatch(setToggleSidebarFilter())}
                    >
                      <Filter size={20} />
                      Filter
                    </Button>
                  </div>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>

      {/* Progress Bar Modal */}
      <PMBProgressBarExportFile
        isDialogShow={isDialogShow}
        firstRows={firstRows}
        totalRows={totalRows}
        onCancel={() => {
          setIsDialogShow(false)
        }}
      />
    </div>
  )
}

export default CatchesTable
