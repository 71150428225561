import AmountInput from 'components/AmountInput'
import CustomButton from 'components/Buttons/CustomButton'
import PMBSelect from 'components/PMBSelect'
import { useState } from 'react'
import { CardText, FormGroup, Input, Label, Modal } from 'reactstrap'

const ConfirmRemoveInventory = ({
  isOpen,
  onClose,
  handleRemoveStock,
  disabled,
}) => {
  const actionsOption = [
    { value: 'DELETED', label: 'Pembuangan' },
    { value: 'SELL', label: 'Penjualan' },
  ]

  const [action, setAction] = useState(actionsOption[0])
  const [buyerName, setBuyerName] = useState('')
  const [price, setPrice] = useState(null)
  const [note, setNote] = useState('')

  const resetState = () => {
    setAction(actionsOption[0])
    setBuyerName('')
    setPrice(null)
    setNote('')
  }

  const handleSubmit = async () => {
    const cleanPrice = price ? Number(price.replace(/\./g, '')) : 0

    const payload = {
      action: action.value,
      buyerName: action.value === 'SELL' ? buyerName : undefined,
      price: action.value === 'SELL' ? cleanPrice : undefined,
      note: note || undefined,
    }

    await handleRemoveStock(payload)
    resetState()
  }

  const handleChangeAmount = (value) => {
    const isAlreadyFormatted = /^\d{1,3}(\.\d{3})*$/.test(value)
    if (isAlreadyFormatted) {
      setPrice(value)
      return
    }
    const numericValue = value.replace(/[^\d]/g, '')
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    setPrice(formattedValue)
  }

  const isDisabled =
    (action.value === 'SELL' && (!buyerName || !price)) || false

  return (
    <Modal isOpen={isOpen} centered>
      <div className="regular-padding-horizontal regular-padding-bottom">
        <h5 className="fw-bold my-3">Konfirmasi Hapus Stok</h5>
        <FormGroup>
          <PMBSelect
            title="Aksi"
            options={actionsOption}
            value={action}
            onChange={(selectedOption) => setAction(selectedOption)}
            placeholder="Pilih Aksi"
          />
        </FormGroup>

        {action.value === 'SELL' && (
          <>
            <FormGroup>
              <Label for="buyerName">Nama Pembeli</Label>
              <Input
                id="buyerName"
                type="text"
                value={buyerName}
                onChange={(e) => setBuyerName(e.target.value)}
                placeholder="Masukkan nama pembeli"
              />
            </FormGroup>
            <FormGroup>
              <Label for="price">Harga satuan</Label>
              <AmountInput
                name="price"
                cb={(e) => handleChangeAmount(e)}
                placeholder="Masukkan harga satuan"
              />
            </FormGroup>
          </>
        )}

        <div className="mt-4">
          <CardText className="no-padding">
            Catatan tambahan (opsional)
          </CardText>
          <div className="d-flex align-items-center">
            <div className="bordered mt-2" style={{ width: '100%' }}>
              <Input
                style={{ borderColor: 'white', height: 200 }}
                className="border-input"
                type="textarea"
                placeholder="Masukkan catatan jika ada..."
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-4">
          <CustomButton
            onClick={() => {
              onClose()
              resetState()
            }}
            outline
            className="fw-semibold btn-cancel"
            style={{ width: '49%', borderColor: '#D0D5DD', color: '#344054' }}
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={isDisabled ?? disabled}
            onClick={handleSubmit}
            className="fw-semibold"
            style={{
              width: '49%',
              backgroundColor: '#D92D20',
              borderColor: '#F97066',
            }}
          >
            Hapus
          </CustomButton>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmRemoveInventory
