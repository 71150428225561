import PMBSelect from 'components/PMBSelect'
import { TableComponent as DataTable } from 'components/TableComponent'
import Chart from 'react-apexcharts'
import { Col, Spinner } from 'reactstrap'

const AnnualTab = ({
  yearOptionsAnnual,
  selectedYearAnnual,
  dataHandline,
  dataPoleLine,
  loading,
  handleYearChangeAnnual,
  columnsAnnual,
  dataResultAnnual,
  options,
  series,
}) => {
  return (
    <div>
      <div className="d-flex flex-column flex-sm-row align-items-start justify-content-md-between">
        <Col xs="12" sm="6">
          <h5 className="mb-0">Summary Tangkapan Tahunan</h5>
        </Col>
        <Col
          xs="12"
          sm="6"
          className="my-2 mb my-sm-0 d-flex justify-content-end"
        >
          <div style={{ width: '100%', maxWidth: '300px' }}>
            <PMBSelect
              value={selectedYearAnnual}
              onChange={handleYearChangeAnnual}
              options={yearOptionsAnnual}
              isMulti={false}
              placeholder="Pilih tahun"
            />
          </div>
        </Col>
      </div>
      <div
        style={{
          overflowX: 'auto',
          width: '100%',
        }}
      >
        <Chart options={options} height={400} series={series} />
      </div>

      {/* TABLE */}
      <div style={{ marginTop: '24px' }}>
        <DataTable
          columns={columnsAnnual}
          data={dataResultAnnual}
          progressPending={loading}
          pagination={false}
          responsive
          progressComponent={
            <div style={{ margin: '24px 0px' }}>
              <Spinner color="primary" />
            </div>
          }
        />
      </div>
    </div>
  )
}

export default AnnualTab
