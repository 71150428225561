import AmountInput from 'components/AmountInput'
import PMBSelect from 'components/PMBSelect'
import { useEffect, useState } from 'react'
import { ChevronDown, MapPin } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardText, CardTitle, Input } from 'reactstrap'
import { getWarehouseForAdd } from '../../API/manageStockAction'
import UploadImageCard from '../Card/UploadImageCard'
import InventoryCodeInput from '../Input/InventoryCodeInput'

export default function AddStockModal({
  isOpen,
  onClose,
  onChangeDataStock,
  dataStock,
  onShowItem,
  onCreateStock,
  loading,
  locationBlocked,
  onChangeAmount,
  onChangeUnitValue,
  defaultWarehouse,
}) {
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [defaultWarehouseAddInventory, setDefaultWarehouseAddInventory] =
    useState(null)
  const { warehousesAdd } = useSelector((s) => s.manageStock)
  const dispatch = useDispatch()

  useEffect(() => {
    if (dataStock?.amount && dataStock?.itemId && dataStock.unit) {
      setButtonDisabled(false)
    } else {
      setButtonDisabled(true)
    }
  }, [dataStock])

  useEffect(() => {
    if (!warehousesAdd) {
      dispatch(getWarehouseForAdd({ isForFilter: true, isAdd: true }))
    }
  }, [warehousesAdd])

  // SET DEFAULT VALUE WAREHOUSE
  useEffect(() => {
    if (!defaultWarehouseAddInventory && defaultWarehouse?.id) {
      if (
        !dataStock?.warehouseId ||
        dataStock?.warehouseId !== defaultWarehouse?.id
      ) {
        setDefaultWarehouseAddInventory(defaultWarehouse?.id)
        onChangeDataStock('warehouseId', defaultWarehouse?.id)
      }
    }
  }, [
    defaultWarehouseAddInventory,
    dataStock?.warehouseId,
    defaultWarehouse?.id,
    onChangeDataStock,
  ])

  const navigate = useNavigate()

  const handleInventoryCodeChange = (code) => {
    onChangeDataStock('ownerCode', code)
  }

  return (
    <>
      <div className="regular-padding-horizontal regular-padding-top d-flex justify-content-between align-items-center">
        <CardTitle className="fw-semibold text-md">Tambah Inventori</CardTitle>
      </div>
      {locationBlocked ? (
        <div className="regular-padding-horizontal small-padding-vertical">
          <Card
            className="small-padding border-0 shadow-sm"
            style={{ backgroundColor: '#F3FEFF', borderColor: '#8ABBCC' }}
          >
            <div className="d-flex">
              <MapPin className="small-margin-right" color="#006386" />
              <CardText style={{ fontSize: 14 }}>
                Untuk mencatat lokasi dari stok yang ditambahkan, harap aktifkan
                layanan lokasi di perangkat Anda.
              </CardText>
            </div>
          </Card>
        </div>
      ) : null}

      <div className="regular-padding">
        <div>
          <CardText className="no-padding">Kode inventori (opsional)</CardText>
          <InventoryCodeInput
            value={dataStock?.ownerCode || ''}
            onChange={handleInventoryCodeChange}
            isAutoFocus
          />
        </div>
        <div className="mt-4">
          <CardText className="no-padding">
            Komoditas
            <span style={{ color: '#b42218' }}>*</span>
          </CardText>
          <div className="bordered mt-2 d-flex justify-content-between align-items-center">
            <Input
              style={{ borderColor: 'white', caretColor: 'transparent' }}
              className="border-input button"
              placeholder="Pilih komoditas"
              onClick={() => {
                onShowItem('item', true)
              }}
              value={dataStock?.nameItem}
            />
            <ChevronDown color="#667085" style={{ paddingRight: 10 }} />
          </div>
        </div>
        {dataStock?.nameItem ? (
          <div className="mt-4">
            <UploadImageCard
              onChangeDataStock={onChangeDataStock}
              uploadedImage={dataStock?.linkFoto}
            />
          </div>
        ) : null}
        <div className="d-flex justify-content-between">
          <div className="mt-4" style={{ width: '49%' }}>
            <CardText className="no-padding">
              Jumlah<span style={{ color: '#b42218' }}>*</span>
            </CardText>
            <div className="mt-2 d-flex justify-content-between align-items-center">
              <AmountInput
                name="quantity"
                cb={(e) =>
                  onChangeAmount('amount', e, onChangeDataStock, dataStock)
                }
                placeholder="Masukkan jumlah"
              />
            </div>
          </div>
          <div className="mt-4" style={{ width: '49%' }}>
            <CardText className="no-padding">
              Satuan<span style={{ color: '#b42218' }}>*</span>
            </CardText>
            <div className="bordered mt-2 d-flex justify-content-between align-items-center">
              <Input
                style={{ borderColor: 'white', caretColor: 'transparent' }}
                className="border-input button"
                placeholder="Pilih Satuan"
                value={dataStock?.unit}
                onClick={() => {
                  onShowItem('itemUnit', true)
                }}
              />
              <ChevronDown color="#667085" style={{ paddingRight: 10 }} />
            </div>
          </div>
        </div>

        <div className="mt-4 w-100">
          <CardText className="no-padding">Harga satuan (opsional)</CardText>
          <div className="mt-2 d-flex justify-content-between align-items-center">
            <AmountInput
              name="unitValue"
              cb={(e) =>
                onChangeUnitValue('unitValue', e, onChangeDataStock, dataStock)
              }
              placeholder="Masukkan harga satuan"
            />
          </div>
        </div>

        {warehousesAdd?.newData?.length > 0 ? (
          <div className="mt-4">
            <div className="d-flex align-items-center">
              <div style={{ width: '100%' }}>
                <CardText className="m-0 mb-1">Gudang</CardText>
                <PMBSelect
                  options={warehousesAdd?.newData}
                  onChange={(text) =>
                    onChangeDataStock('warehouseId', text?.id)
                  }
                  placeholder="Pilih gudang"
                  value={warehousesAdd?.newData?.find(
                    (e) => e?.id === dataStock?.warehouseId,
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="mt-4">
          <CardText className="no-padding">
            Catatan tambahan (opsional)
          </CardText>
          <div className="d-flex align-items-center">
            <div className="bordered mt-2" style={{ width: '100%' }}>
              <Input
                style={{ borderColor: 'white', height: 200 }}
                className="border-input"
                type="textarea"
                placeholder="Masukkan catatan jika ada..."
                onChange={(text) =>
                  onChangeDataStock('description', text.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="d-flex gap-3 mt-4">
          <Button
            onClick={() => {
              onClose()
              setDefaultWarehouseAddInventory(null)
            }}
            outline
            className="btn-cancel"
            block
          >
            Batal
          </Button>
          <Button
            disabled={isButtonDisabled || loading}
            onClick={onCreateStock}
            color="primary"
            outline
            block
          >
            Simpan
          </Button>
          <Button
            disabled={isButtonDisabled || loading}
            onClick={() => onCreateStock('repeat')}
            color="primary"
            block
          >
            Simpan dan Tambah
          </Button>
        </div>
      </div>
    </>
  )
}
