export const formatNumber = (number) => {
  return new Intl.NumberFormat('id-ID').format(number)
}

export const newFormatThousandSeparator = (
  amount,
  maxFractionDigits = 2,
  locale = 'id-ID',
) => {
  return new Intl.NumberFormat(locale, {
    useGrouping: 'always',
    minimumFractionDigits: 0,
    maximumFractionDigits: maxFractionDigits,
  }).format(amount ?? 0)
}
