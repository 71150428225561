import BreadCrumbs from 'components/breadcrumbs'
import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import {
  CheckCircle,
  ChevronDown,
  Eye,
  MoreVertical,
  User,
} from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  Badge,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from 'reactstrap'
import { useFetchWhistleBlowingsQuery } from './whistleBlowingsListApiSlice'
import { setFilters, setIsOpenModalAssign } from './whistleBlowingsListSlice'

import LoadingV2 from 'components/LoadingV2'
import PMBModalImagePreview from 'components/PMBModalImagePreview'
import PMBPagination from 'components/PMBPagination'
import PMBSearchInputGroup from 'components/PMBSearchInputGroup'
import PMBSelect from 'components/PMBSelect'
import PMBTooltip from 'components/PMBTooltip'
import { FILTER_WHISTLE_BLOWING_OPTIONS, STATUS_TYPE } from 'constants'
import { useDebounce } from 'helpers'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { formatDatePMB } from 'utils/formatDate'
import {
  BUSINESS_VESSELS,
  BUSINESS_WHISTLEBLOWING,
  MANAGE,
  READ,
} from 'utils/subjectActions'
import VesselNotFound from '../../../../assets/icons/vessel-not-found.png'
import ModalAssign from './ModalAssign'
import SidebarDetailWhistleBlowing from './SidebarDetailWhistleBlowing'

// HANDLE BADGE BASED ON STATUS AND HANDLE LABEL
const StatusColumn = ({ row }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case STATUS_TYPE.RESOLVED:
        return 'success'
      default:
        return 'primary'
    }
  }

  const getStatusLabel = (status) => {
    return status === STATUS_TYPE.RESOLVED ? STATUS_TYPE.RESOLVED : 'OPEN'
  }

  return (
    <Badge color={getStatusColor(row?.status)}>
      {getStatusLabel(row?.status) ?? '-'}
    </Badge>
  )
}

const WhistleBlowingsTable = () => {
  const { isOpenModalAssign, filters } = useSelector(
    (s) => s.whistleBlowingsList,
  )
  const { abilityRole } = useSelector((s) => s.auth)
  const { profile } = useSelector((s) => s.dashboard)

  const isHaveRoleWhistleBlowing = abilityRole?.some(
    (role) => role?.subject === BUSINESS_WHISTLEBLOWING,
  )

  const manageWhistleBlowingActions = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_WHISTLEBLOWING,
  })

  const manageVesselActions = useAbilityAccess({
    action: READ,
    subject: BUSINESS_VESSELS,
  })

  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchInput, setSearchInput] = useState('')
  const [search, setSearch] = useState('')
  const [modalOpenImage, setModalOpenImage] = useState(false)
  const [imageItems, setImagesItems] = useState([])
  const [dropdownMenus, setDropdownMenus] = useState({})
  const [detailRow, setDetailRow] = useState(null)
  const [statusType, setStatusType] = useState(STATUS_TYPE.ASSIGNED)
  const [isNoDataFilterAvailable, setIsNoDataFilterAvailable] = useState(false)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)

  const onHandleOpenCloseSidebar = () => {
    setIsOpenSidebar((prev) => {
      if (prev) {
        setDetailRow(null)
      }
      return !prev
    })
  }

  // SORT BY FILTER
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('DESC')

  const { data, isLoading, refetch, isFetching } = useFetchWhistleBlowingsQuery(
    {
      page: currentPage ?? 0,
      size: rowsPerPage ?? 10,
      searchvalue: search,
      assigneduserid: isHaveRoleWhistleBlowing ? undefined : profile?.userId,
      sortby: sortColumn,
      sortdir: sortDirection,
      status: filters?.status?.value,
    },
  )

  // HANDLE LOADING FILTER
  useEffect(() => {
    if (!isFetching && data?.totalItems === 0) {
      setIsNoDataFilterAvailable(true)
    } else {
      setIsNoDataFilterAvailable(false)
    }
  }, [isFetching, data?.totalItems])

  const onHandleOpenImageModal = (imageUrls, index) => {
    setImagesItems(imageUrls)
    setModalOpenImage(true)
  }

  const toggleDropdown = (id) => {
    setDropdownMenus((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const onClickAssign = (row = null) => {
    setDetailRow(row)
    dispatch(setIsOpenModalAssign())
  }

  const handleRefetch = () => {
    refetch()
  }

  // HANDLE MENU ITEMS BASED ON isHaveRoleWhistleBlowing
  const menuItems = isHaveRoleWhistleBlowing
    ? [
        {
          label: 'Assign',
          icon: <User size={16} />,
          action: (row) => {
            setStatusType(STATUS_TYPE.ASSIGNED)
            onClickAssign(row)
          },
        },
        {
          label: 'Resolve',
          icon: <CheckCircle size={16} />,
          action: (row) => {
            setStatusType(STATUS_TYPE.RESOLVED)
            onClickAssign(row)
          },
        },
      ]
    : [
        {
          label: 'Re-assign',
          icon: <User size={16} />,
          action: (row) => {
            setStatusType(STATUS_TYPE.UNRESOLVED)
            onClickAssign(row)
          },
        },
        {
          label: 'Resolve',
          icon: <CheckCircle size={16} />,
          action: (row) => {
            setStatusType(STATUS_TYPE.RESOLVED)
            onClickAssign(row)
          },
        },
      ]

  const isMenuItemDisabled = (row, item) => {
    if (row?.status === STATUS_TYPE.RESOLVED) {
      return true
    }

    if (
      isHaveRoleWhistleBlowing &&
      (item?.label?.toLocaleLowerCase() === 'assign' ||
        item?.label?.toLocaleLowerCase() === 're-assign') &&
      row?.assignedUserId !== null
    ) {
      return false
    } else if (
      isHaveRoleWhistleBlowing &&
      item?.label?.toLocaleLowerCase() === 'resolve' &&
      row?.assignedUserId !== null &&
      row?.assignedUserId !== profile?.userId
    ) {
      return true
    }

    return false
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction === 'asc' ? 'ASC' : 'DESC')
  }

  const columns = [
    {
      name: <span>Laporan Diterima</span>,
      selector: 'createdAt',
      sortable: true,
      width: '150px',
      cell: (row) => {
        return <span>{formatDatePMB(row?.createdAt, row?.createdAt)}</span>
      },
    },
    {
      name: <span>Judul Laporan</span>,
      selector: 'name',
      sortable: true,
      cell: (row) => {
        return <span>{row?.name ?? '-'}</span>
      },
    },
    {
      name: <span>Pelapor</span>,
      selector: 'reportername',
      sortable: true,
      cell: (row) => {
        return <span>{row?.user?.name ?? '-'}</span>
      },
    },
    {
      name: <span>Petugas</span>,
      selector: 'assignedname',
      sortable: true,
      cell: (row) => {
        return <span>{row?.assigned?.name ?? '-'}</span>
      },
    },
    {
      name: <span>Bukti Kejadian</span>,
      width: '120px',
      cell: (row) => {
        const imageUrls = row?.linksImage
          ? row?.linksImage?.split(',')
          : [VesselNotFound]
        const displayedImage = imageUrls[0]
        const extraImagesCount = imageUrls?.length - 1

        return (
          <div
            style={{
              position: 'relative',
              width: '50px',
              height: '50px',
            }}
          >
            <img
              src={displayedImage}
              style={{
                width: '80%',
                height: '80%',
                borderRadius: '50%',
                objectFit: 'cover',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                border: '2px solid #f0f0f0',
                transition: 'transform 0.2s',
                cursor: 'pointer',
              }}
              alt="pengaduan-image"
              loading="lazy"
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = 'scale(1.1)')
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              onClick={() => onHandleOpenImageModal(imageUrls, 0)}
            />

            {extraImagesCount > 0 && (
              <div
                style={{
                  position: 'absolute',
                  top: '0px',
                  right: '0px',
                  transform: 'translate(5%, -5%)',
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  border: '1px solid #fff',
                  cursor: 'pointer',
                }}
                onClick={() => onHandleOpenImageModal(imageUrls, 1)}
              >
                +{extraImagesCount}
              </div>
            )}
          </div>
        )
      },
    },
    {
      name: <span>Status</span>,
      width: '120px',
      selector: 'status',
      sortable: true,
      cell: (row) => <StatusColumn row={row} />,
    },
    {
      name: '',
      width: '80px',
      cell: (row) => {
        return (
          <div
            className="gap-2"
            style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}
          >
            <Eye
              size={18}
              onClick={() => {
                setDetailRow(row)
                onHandleOpenCloseSidebar()
              }}
              style={{ cursor: 'pointer' }}
              id={`tooltip-${row.id}`}
            />
            <PMBTooltip
              placement="top"
              target={`tooltip-${row.id}`}
              text={'Detail'}
            />
            {(manageWhistleBlowingActions || manageVesselActions) && (
              <Dropdown
                isOpen={dropdownMenus[row.id]}
                toggle={() => toggleDropdown(row.id)}
                direction="down"
              >
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownMenus[row.id]}
                  style={{ cursor: 'pointer' }}
                >
                  <MoreVertical size={20} />
                </DropdownToggle>
                <DropdownMenu className="p-0" container="body">
                  {menuItems.map((item, index) => (
                    <DropdownItem
                      key={index}
                      disabled={isMenuItemDisabled(row, item)}
                      className="my-1"
                      onClick={() => item.action(row)}
                    >
                      {item.icon}
                      <span className="ms-2 text-sm">{item.label}</span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        )
      },
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        color: '#667085',
        backgroundColor: '#F9FAFB',
      },
    },
    cells: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
      },
    },
    rows: {
      style: {
        marginTop: '1px',
        marginBottom: '1px',
        '&:hover': {
          backgroundColor: '#F9FAFB',
        },
      },
    },
  }

  const debouncedSearch = useDebounce((value) => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setSearch(value)
    refetch()
  }, 600)

  const onHandleSearchChange = (value) => {
    setSearchInput(value ?? '')
    debouncedSearch(value ?? '')
  }

  const handleFiltersChange = (newFilters) => {
    dispatch(setFilters(newFilters))
  }

  const onStatusChange = (item) => {
    setCurrentPage(0)
    setRowsPerPage(10)
    handleFiltersChange({ status: item })
  }

  if (isLoading) {
    return <LoadingV2 />
  }

  return (
    <div className="w-100 px-3">
      <div className="mt-3">
        <BreadCrumbs breadCrumbActive="Daftar Laporan Pengaduan" />
      </div>

      <Card className="mb-4 border-0 shadow-sm">
        {/* SIDEBAR DETAIL */}
        <SidebarDetailWhistleBlowing
          isOpenSidebar={isOpenSidebar}
          onHandleOpenCloseSidebar={onHandleOpenCloseSidebar}
          detailRow={detailRow}
        />

        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.items}
            progressPending={isLoading || isFetching}
            responsive
            persistTableHead
            defaultSortFieldId={1}
            defaultSortAsc={false}
            paginationTotalRows={data?.totalItems}
            progressComponent={
              <div style={{ margin: '100px 0px' }}>
                <Spinner color="primary" />
              </div>
            }
            sortIcon={<ChevronDown />}
            sortServer
            onSort={handleSort}
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                {isNoDataFilterAvailable
                  ? 'Tidak ada data yang sesuai dengan filter Anda.'
                  : 'Belum ada daftar pengaduan yang didaftarkan.'}
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={data?.totalItems || 0}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
                props={props}
              />
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Daftar Laporan Pengaduan</h5>
                </div>

                <div className="row my-4">
                  {/* SEARCH INPUT */}
                  <div className="col-lg-3 col-md-4 col-sm-12 mb-3 mb-md-0">
                    <PMBSearchInputGroup
                      label={'Cari'}
                      value={searchInput}
                      placeholder="Cari judul pengaduan..."
                      onChange={(e) => onHandleSearchChange(e.target.value)}
                      maxWidth="100%"
                    />
                  </div>

                  {/* STATUS */}
                  <div className="col-lg-2 col-md-4 col-sm-12">
                    <PMBSelect
                      title="Status"
                      value={filters?.status}
                      options={FILTER_WHISTLE_BLOWING_OPTIONS}
                      onChange={(item) => onStatusChange(item)}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>

      {/* MODAL IMAGE PREVIEW */}
      <PMBModalImagePreview
        isOpen={modalOpenImage}
        toggle={() => setModalOpenImage(!modalOpenImage)}
        images={imageItems}
      />

      {/* MODAL */}
      <ModalAssign
        open={isOpenModalAssign}
        row={detailRow}
        toggle={onClickAssign}
        statusType={statusType}
        onSuccess={handleRefetch}
      />
    </div>
  )
}

export default WhistleBlowingsTable
