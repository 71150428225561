import BreadCrumbs from 'components/breadcrumbs'
import PMBPagination from 'components/PMBPagination'
import { TableComponent as DataTable } from 'components/TableComponent'
import { useEffect, useRef, useState } from 'react'
import { Search } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import {
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from 'reactstrap'
import { default as ExpandableCompanent } from './ExpandedCompanent'
import { useFetchReportQuery } from './learningReportApiSlice'
import { initTagUsers, setFetchOption } from './learningReportSlice'

const LearningReport = () => {
  const dispatch = useDispatch()
  const fetchTimeoutRef = useRef(0)

  const { tag } = useSelector((s) => s.learningReport)
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState('desc')
  const [search, setSearch] = useState('')
  const [searchTemp, setSearchTemp] = useState('')
  const res = useFetchReportQuery(
    {
      page: tag?.page,
      sortby: sortColumn,
      sortdir: sortDirection,
      size: tag?.size,
      s: searchTemp,
    },
    { skip: !tag },
  )

  const columns = [
    {
      name: <span>Nama</span>,
      selector: (row) => row.name,
      grow: 2,
      sortField: 'name',
      sortable: true,
      cell: (row) => <div>{row?.name}</div>,
    },
    {
      name: <span>Kemajuan</span>,
      selector: (row) => row.totalProgress,
      grow: 1,
      sortField: 'totalProgress',
      sortable: true,
      right: true,
      cell: (row) => <div>{Number(row?.totalProgress)}%</div>,
    },
    {
      name: <span>Status</span>,
      selector: (row) => row.status,
      grow: 2,
      sortField: 'status',
      sortable: true,
      cell: (row) => {
        if (row?.status === 'selesai') {
          return (
            <span className="badge rounded-pill bg-success-50 text-success">
              {row?.status}
            </span>
          )
        } else if (row?.status === 'belum') {
          return (
            <span className="badge rounded-pill bg-warning-50 text-warning-700">
              belum selesai
            </span>
          )
        }
      },
    },
  ]

  const handleSearch = (e) => {
    setSearch(e)
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current)
    }

    fetchTimeoutRef.current = setTimeout(() => {
      if (e.trim() !== '') {
        setSearchTemp(e)
      } else {
        setSearch('')
        setSearchTemp('')
      }
    }, 1000)
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.sortField)
    setSortDirection(direction === 'asc' ? 'desc' : 'asc')
  }

  const ExpandedComponent = ({ data }) => {
    return (
      <>
        <ExpandableCompanent data={data} />
      </>
    )
  }

  useEffect(() => {
    if (res?.data?.items) {
      const obj = res?.data?.items.reduce((acc, e) => {
        const userId = e?.userId
        if (userId) {
          acc[userId] = {
            page: 0,
            size: 10,
            sortby: null,
            sortdir: null,
          }
        }
        return acc
      }, {})

      dispatch(initTagUsers(obj))
    }
  }, [res])

  return (
    <div className="w-100 px-3 pb-3">
      <div className="d-flex align-items-start gap-2 pt-3">
        <BreadCrumbs breadCrumbActive={'Laporan Pembelajaran'} />
      </div>
      <Card className="border-0 shadow-sm">
        <CardBody>
          <DataTable
            columns={columns}
            data={res?.data?.items}
            progressPending={res?.isLoading || res?.status === 'pending'}
            progressComponent={<Spinner />}
            responsive
            persistTableHead
            defaultSortFieldId={1}
            defaultSortAsc={false}
            sortServer
            onSort={handleSort}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            pagination
            paginationServer
            paginationComponent={(props) => (
              <PMBPagination
                rowCount={res?.data?.totalItems}
                currentPage={tag?.page}
                rowsPerPage={tag?.size ?? 10}
                setCurrentPage={(e) => {
                  dispatch(setFetchOption({ page: e }))
                }}
                setRowsPerPage={(e) => {
                  dispatch(setFetchOption({ size: e }))
                }}
                props={props}
              />
            )}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100 d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                  <h5 className="fw-semibold">Laporan Pembelajaran</h5>
                </div>
                <div>
                  {' '}
                  <InputGroup style={{ borderColor: '#D0D5DD' }}>
                    <InputGroupText
                      style={{
                        backgroundColor: 'white',
                        borderRightColor: 'white',
                      }}
                    >
                      <Search color="#667085" size={16} />
                    </InputGroupText>
                    <Input
                      style={{
                        maxWidth: '346px',
                      }}
                      placeholder="Cari..."
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default LearningReport
