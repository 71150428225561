import barcodeMenu from 'assets/icons/qrcode.svg'

import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import PMBPagination from 'components/PMBPagination'
import PMBTooltip from 'components/PMBTooltip'
import SidebarDetails from 'components/SidebarDetails'
import { TableComponent as DataTable } from 'components/TableComponent'
import { itemUnitList, menusStock2, menuStock } from 'constants'
import { Can } from 'context/Acl'
import moment from 'moment-timezone'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import {
  CheckCircle,
  ChevronDown,
  ChevronRight,
  Codepen,
  Copy,
  Database,
  Edit2,
  Eye,
  FolderMinus,
  MoreVertical,
  Search,
  Trash2,
  X,
} from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap'
import { URL_INVENTORY } from 'utils/pathUrl'
import { BUSINESS_INVENTORY, MANAGE } from 'utils/subjectActions'
import {
  createStock,
  deleteInventory,
  fetchDynamicItems,
  fetchInventoryGroups,
  getInventory,
  getLabelMenuItems,
  getPackingDetail,
  getWarehouse,
  mergeInventory,
  packingAddStock,
  removeInventory,
  removePacking,
  splitInventory,
  splitToManyInventory,
  transformInventory,
  updateInventory,
} from './API/manageStockAction'
import {
  ActionModal,
  AddStockModal,
  AddStockSidebar,
  // ConfirmRemoveModal,
  ConfirmRemoveMultipleModal,
  DetailModal,
  EditStockModal,
  IDStockModal,
  ItemModal,
  ItemUnitModal,
  MergeStockModal,
  SplitStockModal,
} from './Components'
import ConfirmRemoveInventory from './Components/Modal/ConfirmRemoveInventory'
import ModalPacking from './Components/Modal/ModalPacking'
import ModalPindahGudang from './Components/Modal/ModalPindahGudang'
import ModalRemoveItemPacking from './Components/Modal/ModalRemoveItemPacking'
import ModalRemovePacking from './Components/Modal/ModalRemovePacking'
import ModalRenamePacking from './Components/Modal/ModalRenamePacking'
import TransformModal from './Components/Modal/TransformModal'
import './styles.css'

export default function ManageStock({
  isDetail,
  id,
  dataState,
  breadCrumbsData,
  onManageBreadCrumb,
}) {
  const actionMenuRef = useRef(null)
  const navigate = useNavigate()

  const intl = useIntl()
  const debounceTimer = useRef(0)
  const debounceTimerGrup = useRef(0)
  const {
    detail,
    loading: isLoading,
    warehouses,
    itemsInventory,
  } = useSelector((s) => s.manageStock)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [displayAmount, setDisplayAmount] = useState(null)
  const [displayUnitValue, setDisplayUnitValue] = useState(null)
  const [showModal, setShowModal] = useState({
    menuOption: false,
    detail: false,
    qrCode: false,
    remove: false,
    filter: false,
    edit: false,
    add: false,
    item: false,
    itemUnit: false,
    merge: false,
    split: false,
    confirmRemove: false,
    moveOutPacking: false,
    transform: false,
  })
  const [selectedSort, setSelectedSort] = useState({
    type: 'updatedAt',
    mode: 'desc',
  })

  const [selectedSortGroup, setSelectedSortGroup] = useState({
    type: 'updatedAt',
    mode: 'desc',
  })

  const [isFetch, setIsFetch] = useState('')
  const [inventoryList, setInventoryList] = useState([])
  const [inventoryGabungList, setInventoryGabungList] = useState([])
  const [inventoryMergeList, setInventoryMergeList] = useState([])
  const [groupInventoryList, setGroupInventoryList] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [totalPages, setTotalPage] = useState(0)
  const [size, setSize] = useState(10)
  const [selectedStock, setSelectedStock] = useState(null)
  const [selectedPacking, setSelectedPacking] = useState(null)
  const [searchInventory, setSearchInventory] = useState('')
  const [searchGrupInventory, setSearchGrupInventory] = useState('')
  const [selectedInventoryList, setSelectedInventoryList] = useState([])
  const [selectedDataRemove, setSelectedDataRemove] = useState([])
  const [selectedGroupInventory, setSelectedGroupInventory] = useState([])
  const [itemsList, setItemsList] = useState([])
  const [dataStock, setDataStock] = useState({
    unit: 'Kg',
    description: '',
    ownerCode: '',
  })
  const [selectedUnit, setSelectedUnit] = useState({})
  const [selectedEditAction, setSelectedEditAction] = useState({})
  const [position, setPosition] = useState({})
  const [locationBlocked, setLocationBlocked] = useState(false)
  const [splitItems, setSplitItems] = useState([])
  const [enableSplit, setEnableSplit] = useState(false)
  const [indexSelectedSplitStock, setIndexSelectedSplitStock] = useState(null)
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false)
  const [isFetchingMenus, setIsFetchingMenus] = useState(false)

  const defaultWarehouse = JSON.parse(
    localStorage.getItem('defaultWarehouseInventory'),
  )

  const [loading, setLoading] = useState({
    create: false,
    getItems: false,
    getInventory: false,
    dynamicMenu: false,
    removeStock: false,
  })
  const [toast, setToast] = useState({
    show: false,
    title: '',
    type: '',
  })

  // STATE FOR PROSES TRANFORM
  const unitOptions = itemUnitList?.map((item) => ({
    value: item?.title,
    label: item?.title,
  }))
  const [notes, setNotes] = useState('')
  const [transformItems, setTransformItems] = useState([
    {
      ownerCode: null,
      amount: 0,
      unitValue: 0,
      itemId: null,
      description: '',
      unit: unitOptions?.[0]?.value,
    },
  ])

  const [paginationGroup, setPaginationGroup] = useState({})
  const [clearRows, setClearRows] = useState(false)
  const [clearRowsGroup, setClearRowsGroup] = useState(false)
  const toggleClearRows = () => {
    setClearRows(!clearRows)
  }
  const toggleClearRowsGroup = () => {
    setClearRowsGroup(!clearRowsGroup)
  }
  const [paginationGabung, setPaginationGabung] = useState({ currentPage: 0 })
  const [dynamicMenus, setDynamicMenus] = useState([])
  const [isTransform, setIsTransform] = useState(false)
  const [page, setPage] = useState(() => {
    const state = window.history.state
    return state && state.page ? state.page : 0
  })

  const [isSubGroup, setIsSubGroup] = useState(false)
  const [currentGroups, setCurrentGroups] = useState([])
  const [parentGroupId, setParentGroupId] = useState(null)
  const [actionType, setActionType] = useState('')

  const handleResetDataStock = () => {
    setDataStock({ unit: 'Kg', description: '', ownerCode: '' })
  }

  const onHandleResetNewTransform = () => {
    setDynamicMenus([])
    setSelectedStock({})
    setSelectedUnit({})
    setIsTransform(false)
    handleResetDataStock()
    setNotes('')
    setTransformItems([
      {
        ownerCode: null,
        amount: 0,
        unitValue: 0,
        itemId: null,
        description: '',
        unit: unitOptions?.[0]?.value,
      },
    ])
  }

  useEffect(() => {
    dispatch(getWarehouse({ isForFilter: true }))
  }, [])

  useEffect(() => {
    if (warehouses?.newData?.length > 0) {
      fetchInventory(
        0,
        10,
        '',
        selectedSort?.type,
        selectedSort?.mode,
        defaultWarehouse?.id,
      )
      if (id) {
        fetchGroupInventory(0, '', '', '', '', '', id)
      }

      if (!isDetail) {
        fetchGroupInventory(
          0,
          10,
          '',
          selectedSortGroup?.type,
          selectedSortGroup?.mode,
          defaultWarehouse?.id,
        )
      }
    }
    if (isDetail && !dataState) {
      dispatch(getPackingDetail({ page: 0, id }))
    }
    // getMyLocation()
  }, [isDetail, warehouses, id])

  // HANDLE DEFAULT WAREHOUSE
  useEffect(() => {
    if (warehouses?.newData?.length > 0) {
      const storedWarehouse = JSON.parse(
        localStorage.getItem('defaultWarehouseInventory'),
      )
      const validWarehouse = warehouses?.newData?.find(
        (warehouse) => warehouse.id === storedWarehouse?.id,
      )

      if (!validWarehouse) {
        const defaultWarehouse = warehouses.newData[0]
        localStorage.setItem(
          'defaultWarehouseInventory',
          JSON.stringify(defaultWarehouse),
        )
      }
    }
  }, [warehouses])

  useEffect(() => {
    if (isFetch) {
      if (isFetch === 'all') {
        fetchInventory(
          currentPage,
          size,
          searchInventory,
          selectedSort?.type,
          selectedSort?.mode,
          defaultWarehouse?.id,
        )
        fetchGroupInventory(
          paginationGroup?.currentPage,
          paginationGroup?.size,
          searchInventory,
          selectedSortGroup?.type,
          selectedSortGroup?.mode,
          defaultWarehouse?.id,
          id,
        )
      } else if (isFetch === 'grup') {
        fetchGroupInventory(
          paginationGroup?.currentPage,
          paginationGroup?.size,
          searchInventory,
          selectedSortGroup?.type,
          selectedSortGroup?.mode,
          defaultWarehouse?.id,
          id,
        )
      } else {
        fetchInventory(
          currentPage,
          size,
          searchInventory,
          selectedSort?.type,
          selectedSort?.mode,
          defaultWarehouse?.id,
        )
        fetchGroupInventory(0, 10, '', '', '', '', parentGroupId)
        toggleClearRowsGroup()
        toggleClearRows()
      }
    }
  }, [isFetch])

  // FETCHING LABEL DYNAMICS
  const fetchLabelItems = async (rawItemId) => {
    try {
      setIsFetchingMenus(true)
      const response = await dispatch(getLabelMenuItems({ rawItemId })).unwrap()
      const newMenus = response?.items?.map((item) => ({
        id: Number(`${Date.now()}${Math.floor(Math.random() * 100000)}`),
        title: item?.createMethodLabel,
        screen: '/manage-stock/edit',
        type: 'edit',
        editType: 'general',
        modal: 'transform',
      }))
      setDynamicMenus(newMenus)
    } catch (error) {
      console.warn(error)
      enqueueSnackbar('Gagal mengambil data label items.', { variant: 'error' })
    } finally {
      setIsFetchingMenus(false)
    }
  }

  // HANDLE GET ITEMS DYANMIC BASED ON MENU/LABEL
  const handleDynamicMenuClick = async (menu, itemId) => {
    if (!menu?.id || !itemId) {
      enqueueSnackbar('Data tidak valid untuk memuat item.', {
        variant: 'error',
      })
      return
    }

    try {
      setLoading((prevState) => ({ ...prevState, dynamicMenu: true }))

      const response = await dispatch(
        fetchDynamicItems({
          createMethodLabel: menu.title,
          rawItemId: itemId,
        }),
      ).unwrap()
      setIsTransform(true)
      setItemsList(response?.items)
      onShowHideModal(menu.modal, true)
    } catch (error) {
      enqueueSnackbar('Gagal memuat item dinamis.', { variant: 'error' })
      console.warn('Error fetching dynamic items:', error)
    } finally {
      setLoading((prevState) => ({ ...prevState, dynamicMenu: false }))
    }
  }

  // MERGED MENU STOCK FOR TRANSFORM
  const mergedMenusStock2 = isFetchingMenus
    ? []
    : dynamicMenus.length > 0
      ? dynamicMenus
      : menusStock2 || []

  const fetchInventory = (
    page,
    size,
    search,
    sortBy,
    asc,
    warehouseId,
    groupId = id,
  ) => {
    setLoading((prevState) => ({ ...prevState, getInventory: true }))
    dispatch(
      getInventory({
        page: page,
        size: size,
        search: search,
        sortBy: sortBy,
        asc: asc,
        ...(!isDetail && {
          warehouseId: warehouseId ?? 'null',
        }),
        ...(isDetail
          ? {
              groupId: groupId,
            }
          : { groupId: 'null' }),
        all: true,
      }),
    )
      .then((res) => {
        setInventoryList(res?.payload?.items)
        setLoading((prevState) => ({ ...prevState, getInventory: false }))
        setTotalPage(res?.payload?.totalPages)
        setCurrentPage(res?.payload?.currentPage)
        setTotalItems(res?.payload?.totalItems)
      })
      .finally(() => {
        setLoading((prevState) => ({ ...prevState, getInventory: false }))
        setIsFetch(false)
      })
  }

  const fetchGabungInventory = (page, row) => {
    setLoading((prevState) => ({ ...prevState, merge: true }))
    dispatch(
      getInventory({
        page: page,
        excludeId: row?.id,
        all: true,
      }),
    )
      .then((res) => {
        setInventoryGabungList(res?.payload?.items)
        setLoading((prevState) => ({ ...prevState, merge: false }))
        setPaginationGabung({
          totalPages: res?.payload?.totalPages,
          currentPage: res?.payload?.currentPage,
        })
      })
      .finally(() => {
        setLoading((prevState) => ({ ...prevState, merge: false }))
      })
  }

  const fetchGroupInventory = (
    page,
    size,
    search,
    sortBy,
    asc,
    warehouseId,
    parentGroupId,
  ) => {
    setLoading((prevState) => ({ ...prevState, group: true }))
    dispatch(
      fetchInventoryGroups({
        page: page,
        size: size,
        search: search,
        sortBy: sortBy,
        asc: asc,
        warehouseId: warehouseId ?? 'null',
        parentGroupId: parentGroupId ?? '',
      }),
    )
      .then((res) => {
        setCurrentGroups(res?.payload?.items)
        setPaginationGroup((prev) => ({
          ...prev,
          totalPages: res?.payload?.totalPages,
          currentPage: res?.payload?.currentPage,
          totalItems: res?.payload?.totalItems,
        }))
        setParentGroupId(parentGroupId)
      })
      .finally(() => {
        setLoading((prevState) => ({ ...prevState, group: false }))
        setIsFetch(false)
      })
  }

  const handleSort = (column, direction) => {
    setSelectedSort({ type: column?.sortField, mode: direction })
    fetchInventory(
      0,
      size,
      searchInventory,
      column?.sortField,
      direction,
      defaultWarehouse?.id,
    )
  }

  const handleSortGroup = (column, direction) => {
    setSelectedSortGroup({ type: column?.sortField, mode: direction })
    fetchGroupInventory(
      0,
      size,
      searchGrupInventory,
      column?.sortField,
      direction,
      defaultWarehouse?.id,
    )
  }

  const handleFilterWarehouse = (filter) => {
    fetchInventory(
      0,
      size,
      searchInventory,
      selectedSort?.sortField,
      selectedSort?.mode,
      filter === undefined ? 'null' : filter,
    )

    // FILTER INVENTORY GRUP
    fetchGroupInventory(
      paginationGroup?.currentPage,
      paginationGroup?.size,
      searchGrupInventory,
      selectedSortGroup?.sortField,
      selectedSortGroup?.mode,
      filter === undefined ? 'null' : filter,
    )
  }

  const onShowHideModal = (key, value, editType, index, data) => {
    if (editType) {
      setSelectedEditAction(editType)
    }
    if (index) {
      setIndexSelectedSplitStock(index)
    }
    if (key === 'split' && value) {
      addSplitItem(data)
    }
    if (
      value === false &&
      !showModal?.item &&
      key !== 'itemUnit' &&
      dynamicMenus?.length > 0
    ) {
      onHandleResetNewTransform()
    }
    setPage((prevPage) => prevPage + 1)
    setShowModal((prevState) => ({
      ...prevState,
      menuOption: false,
      [key]: value,
    }))
  }

  const addSplitItem = (data) => {
    if (!data?.id) {
      data = selectedStock
    }
    if (splitItems?.[0]?.amount == 1) {
      return
    }
    let newItems = []
    const currentSplitAmount = splitItems?.length || 1
    let totalAmount = 0
    for (let i = 0; i <= currentSplitAmount; i++) {
      if (i == currentSplitAmount) {
        newItems.unshift({
          nameItem:
            data?.nameItem ||
            data?.SKU ||
            data?.item?.nameItem ||
            data?.item?.SKU,
          itemId: data?.itemId || data?.item?.id,
          amount: data?.amount - totalAmount,
          unit: data?.unit,
          desc: data?.description || '',
        })
      } else {
        const amountAdded = Math.floor(data?.amount / (currentSplitAmount + 1))
        newItems.unshift({
          nameItem:
            data?.nameItem ||
            data?.SKU ||
            data?.item?.nameItem ||
            data?.item?.SKU,
          itemId: data?.itemId || data?.item?.id,
          amount: amountAdded,
          unit: data?.unit,
          desc: data?.description || '',
        })
        totalAmount += amountAdded
      }
    }
    setSplitItems(newItems)
    setEnableSplit(newItems[0]?.amount > 1)
  }

  const onSelectStock = (item) => {
    setSelectedInventoryList((prevList) => {
      if (item?.selectedRows) {
        return item?.selectedRows
      } else if (prevList.includes(item.id)) {
        return prevList.filter((id) => id !== item.id)
      } else {
        return [...prevList, item.id]
      }
    })
  }

  const onSelectStockRemove = (item) => {
    setSelectedDataRemove((prevList) => {
      if (item?.selectedRows) {
        return item?.selectedRows
      } else if (prevList.includes(item.id)) {
        return prevList.filter((id) => id !== item.id)
      } else {
        return [...prevList, item.id]
      }
    })
  }

  const onSelectGroup = (item) => {
    setSelectedGroupInventory(item?.selectedRows)
  }

  const onSelectStockMenu = (item) => {
    setDisplayAmount(item?.amount)
    setDisplayUnitValue(item?.unitValue)
    setSelectedStock({ ...item?.item, ...item })
    setInventoryList((prevList) =>
      prevList.map((el) =>
        el.id === item.id
          ? { ...el, showSelectedMenu: !el.showSelectedMenu }
          : { ...el, showSelectedMenu: false },
      ),
    )
  }

  const onClickNextBack = (type, group, type2) => {
    if (group) {
      if (type === 'next') {
        fetchGroupInventory(
          paginationGroup?.currentPage + 1,
          paginationGroup?.size,
          searchInventory,
          selectedSortGroup?.sortField,
          selectedSortGroup?.mode,
          defaultWarehouse?.id,
        )
      } else {
        fetchGroupInventory(
          paginationGroup?.currentPage - 1,
          paginationGroup?.size,
          searchInventory,
          selectedSortGroup?.sortField,
          selectedSortGroup?.mode,
          defaultWarehouse?.id,
        )
      }
    } else if (type2 === 'gabung') {
      if (type === 'next') {
        fetchGabungInventory(paginationGabung?.currentPage + 1, selectedStock)
      } else {
        fetchGabungInventory(paginationGabung?.currentPage - 1, selectedStock)
      }
    } else {
      if (type === 'next') {
        fetchInventory(
          currentPage + 1,
          size,
          searchInventory,
          selectedSort?.type,
          selectedSort?.mode,
          defaultWarehouse?.id,
        )
      } else {
        fetchInventory(
          currentPage - 1,
          size,
          searchInventory,
          selectedSort?.type,
          selectedSort?.mode,
          defaultWarehouse?.id,
        )
      }
    }
  }

  const handleSearchChange = (text) => {
    setSearchInventory(text)
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }

    debounceTimer.current = setTimeout(() => {
      if (text.trim() !== '') {
        fetchInventory(
          0,
          size,
          text,
          selectedSort?.type,
          selectedSort?.mode,
          defaultWarehouse?.id,
        )
      } else {
        fetchInventory(
          0,
          size,
          '',
          selectedSort?.type,
          selectedSort?.mode,
          defaultWarehouse?.id,
        )
      }
    }, 600)
  }

  const handleSearchGroupChange = (text) => {
    setSearchGrupInventory(text)
    if (debounceTimerGrup.current) {
      clearTimeout(debounceTimerGrup.current)
    }

    debounceTimerGrup.current = setTimeout(() => {
      if (text.trim() !== '') {
        fetchGroupInventory(
          paginationGroup?.currentPage,
          paginationGroup?.size,
          text,
          selectedSortGroup?.sortField,
          selectedSortGroup?.mode,
          defaultWarehouse?.id,
          id,
        )
      } else {
        fetchGroupInventory(
          paginationGroup?.currentPage,
          paginationGroup?.size,
          '',
          selectedSortGroup?.sortField,
          selectedSortGroup?.mode,
          defaultWarehouse?.id,
          id,
        )
      }
    }, 600)
  }

  const onSelectItem = (item) => {
    if (indexSelectedSplitStock >= 0) {
      let newItems = [...splitItems]
      newItems[indexSelectedSplitStock] = {
        ...newItems[indexSelectedSplitStock],
        itemId: item?.id,
        nameItem: item?.nameItem,
      }
      setSplitItems(newItems)
    }
    onChangeDataStock('nameItem', item?.nameItem)
    onChangeDataStock('itemId', item?.id)
    onChangeDataStock('linkFoto', item?.linkFoto)
    onChangeSelectedDataStock('nameItem', item?.nameItem)
    onChangeSelectedDataStock('itemId', item?.id)
    onChangeSelectedDataStock('linkFoto', item?.linkFoto)
    onShowHideModal('item', false)
  }

  const onSelectItemUnit = (item) => {
    setSelectedUnit(item)
    onChangeDataStock('unit', item?.title)
    onChangeSelectedDataStock('unit', item?.title)
    onShowHideModal('itemUnit', false)
    onChangeSelectedDataStock('unit', item?.title)
  }

  const onChangeSelectedDataStock = (key, value) => {
    if (key === 'amount') {
      setSelectedStock((prevState) => ({
        ...prevState,
        [key]: Number(value),
      }))
    } else {
      setSelectedStock((prevState) => ({
        ...prevState,
        [key]: value,
      }))
    }
  }

  const handleChangeInputSeparator = (key, value, func, data) => {
    setDisplayAmount(value)
    if (
      Number(
        value
          ?.replace(/[^\d,]/g, '')
          ?.replace?.(/\./g, '')
          ?.replace?.(/\,/g, '.'),
      ) !== data?.[key] &&
      func
    ) {
      func(
        key,
        Number(
          value
            ?.replace(/[^\d,]/g, '')
            ?.replace?.(/\./g, '')
            ?.replace?.(/\,/g, '.'),
        ),
      )
    }
  }

  const handleChangeInputUnitValueSeparator = (key, value, func, data) => {
    setDisplayUnitValue(value)
    if (
      Number(
        value
          ?.replace(/[^\d,]/g, '')
          ?.replace?.(/\./g, '')
          ?.replace?.(/\,/g, '.'),
      ) !== data?.[key] &&
      func
    ) {
      func(
        key,
        Number(
          value
            ?.replace(/[^\d,]/g, '')
            ?.replace?.(/\./g, '')
            ?.replace?.(/\,/g, '.'),
        ),
      )
    }
  }

  const onChangeDataStock = (key, value) => {
    if (key === 'amount' || key === 'unitValue') {
      setDataStock((prevState) => ({
        ...prevState,
        [key]: Number(value),
      }))
    } else {
      setDataStock((prevState) => ({
        ...prevState,
        [key]: value,
      }))
    }
  }

  const getMyLocation = async () => {
    try {
      const location = window.navigator && window.navigator.geolocation

      if (!location) {
        throw new Error('Geolocation not supported')
      }

      const result = await new Promise((resolve, reject) => {
        location.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords?.latitude,
              longitude: position.coords?.longitude,
              locationBlocked: false,
            })
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              resolve({
                locationBlocked: true,
              })
            } else {
              reject(error)
            }
          },
        )
      })

      if (result.locationBlocked) {
        setLocationBlocked(true)
      } else {
        setPosition({
          latitude: result.latitude,
          longitude: result.longitude,
        })
        setLocationBlocked(false)
      }

      return result
    } catch (error) {
      console.error('Error getting location:', error)
      throw error
    }
  }

  const onCreateStock = async (type) => {
    setLoading((prevState) => ({ ...prevState, create: true }))
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      enqueueSnackbar('Harap aktifkan lokasi pada perangkat Anda.', {
        variant: 'error',
      })
      setLoading((prevState) => ({ ...prevState, create: false }))
      return
    }
    let payload = {
      ownerCode: dataStock?.ownerCode !== '' ? dataStock?.ownerCode : null,
      amount: dataStock?.amount,
      unitValue: dataStock?.unitValue,
      description: dataStock?.description,
      images: [{ url: dataStock?.linkFoto }],
      itemId: dataStock?.itemId,
      latitude: position?.latitude,
      longitude: position?.longitude,
      unit: dataStock?.unit,
      ...(dataStock?.warehouseId && {
        warehouseId: dataStock?.warehouseId,
      }),
    }
    if (locationBlocked) {
      alert('Please enable location services to create inventory.')
      setLoading((prevState) => ({ ...prevState, create: false }))
      return
    }
    dispatch(createStock(payload))
      .then((res) => {
        if (res?.error) {
          throw res
        } else {
          if (id) {
            onAddInventoryToGroup(res?.payload?.id)
          }

          fetchInventory(
            currentPage,
            size,
            searchInventory,
            selectedSort?.type,
            selectedSort?.mode,
            defaultWarehouse?.id,
          )
          if (type !== 'repeat') {
            onShowHideModal('add', false)
            handleResetDataStock()
          }
          setLoading((prevState) => ({ ...prevState, create: false }))
          enqueueSnackbar('Stok baru berhasil ditambahkan', {
            variant: 'success',
          })
        }
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoading((prevState) => ({ ...prevState, create: false }))
        enqueueSnackbar(err?.payload, {
          variant: 'error',
        })
      })
  }

  const onUpdateStock = async () => {
    setLoading((prevState) => ({ ...prevState, update: true }))
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      enqueueSnackbar('Harap aktifkan lokasi pada perangkat Anda.', {
        variant: 'error',
      })
      setLoading((prevState) => ({ ...prevState, update: false }))
      return
    }

    const payload = {
      ownerCode:
        selectedStock?.ownerCode !== '' ? selectedStock?.ownerCode : null,
      amount: selectedStock?.amount,
      unitValue: selectedStock?.unitValue,
      description: selectedStock?.description,
      images: [{ url: selectedStock?.linkFoto }],
      itemId: selectedStock?.itemId,
      latitude: position?.latitude,
      longitude: position?.longitude,
      unit: selectedStock?.unit,
      inventoryId: selectedStock?.id,
    }

    if (locationBlocked) {
      alert('Please enable location services to create stock.')
      return
    }

    if (isTransform) {
      const parseAmount = (value) => {
        if (!value) return 0
        const numericValue = value.replace(/\./g, '').replace(',', '.')
        return parseFloat(numericValue)
      }

      const newPayload = {
        inventoryId: selectedStock?.id,
        arrNew: transformItems.map((item) => ({
          ownerCode: item?.ownerCode ?? null,
          amount: parseAmount(item.amount),
          itemId: item.itemId,
          description: notes ?? '',
          unit: item.unit,
        })),
        notes: notes ?? '',
        latitude: position?.latitude,
        longitude: position?.longitude,
      }

      dispatch(splitToManyInventory(newPayload))
        .then((res) => {
          fetchInventory(
            currentPage,
            size,
            searchInventory,
            selectedSort?.type,
            selectedSort?.mode,
            defaultWarehouse?.id,
          )
          onShowHideModal('edit', false)
          setLoading((prevState) => ({ ...prevState, update: false }))
          if (isTransform) {
            enqueueSnackbar('Inventory berhasil di proses', {
              variant: 'success',
            })
            onHandleResetNewTransform()
          }
        })
        .catch((err) => {
          console.log(err, 'err')
          enqueueSnackbar(err?.message ?? 'Gagal memproses inventori', {
            variant: 'error',
          })
          setLoading((prevState) => ({ ...prevState, update: false }))
        })
    } else if (selectedEditAction !== 'general') {
      dispatch(updateInventory(payload))
        .then((res) => {
          fetchInventory(
            currentPage,
            size,
            searchInventory,
            selectedSort?.type,
            selectedSort?.mode,
            defaultWarehouse?.id,
          )
          onShowHideModal('edit', false)
          setSelectedStock((prevState) => ({
            ...prevState,
            ownerCode: selectedStock?.ownerCode,
            amount: selectedStock?.amount,
            unitValue: selectedStock?.unitValue,
            unit: selectedStock?.unit,
            item: { ...prevState?.item, linkFoto: selectedStock?.linkFoto },
            description: selectedStock?.description,
          }))
          setLoading((prevState) => ({ ...prevState, update: false }))
        })
        .catch((err) => {
          console.log(err, 'err')
          setLoading((prevState) => ({ ...prevState, update: false }))
        })
    } else {
      dispatch(transformInventory(payload))
        .then((res) => {
          if (res?.error) {
            enqueueSnackbar(res?.payload, {
              variant: 'error',
            })
          }
          fetchInventory(
            currentPage,
            size,
            searchInventory,
            selectedSort?.type,
            selectedSort?.mode,
            defaultWarehouse?.id,
          )
          onShowHideModal('edit', false)
          setLoading((prevState) => ({ ...prevState, update: false }))
          if (isTransform) {
            enqueueSnackbar('Inventory berhasil di proses', {
              variant: 'success',
            })
            onHandleResetNewTransform()
          }
        })
        .catch((err) => {
          console.log(err, 'err')
          setLoading((prevState) => ({ ...prevState, update: false }))
        })
    }
  }

  const onSplitStock = async () => {
    setLoading((prevState) => ({ ...prevState, split: true }))
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      enqueueSnackbar('Harap aktifkan lokasi pada perangkat Anda.', {
        variant: 'error',
      })
      setLoading((prevState) => ({ ...prevState, split: false }))
      return
    }
    let payload = {
      arrNew: splitItems?.map((item) => ({
        ...item,
        amount:
          typeof item?.amount === 'string'
            ? parseFloat(item?.amount?.replace(',', '.')) || 0
            : item?.amount,
        inventoryOfflineId: null,
      })),
      latitude: position?.latitude,
      longitude: position?.longitude,
      inventoryId: selectedStock?.id,
    }
    if (locationBlocked) {
      alert('Please enable location services to create stock.')
      return
    }
    dispatch(splitInventory(payload))
      .then((res) => {
        if (res?.error?.message === 'Rejected') {
          throw res?.error?.message
        }
        fetchInventory(
          currentPage,
          size,
          searchInventory,
          selectedSort?.type,
          selectedSort?.mode,
          defaultWarehouse?.id,
        )
        onShowHideModal('split', false)
        setSplitItems([])
        enqueueSnackbar('Inventori berhasil dipisah.', { variant: 'success' })
        setLoading((prevState) => ({ ...prevState, split: false }))
      })
      .catch((err) => {
        console.log(err, 'err')
        enqueueSnackbar('Stok gagal dipisah.', { variant: 'error' })
        setLoading((prevState) => ({ ...prevState, split: false }))
      })
  }

  const onMergeStock = async () => {
    setLoading((prevState) => ({ ...prevState, merge: true }))
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      enqueueSnackbar('Harap aktifkan lokasi pada perangkat Anda.', {
        variant: 'error',
      })
      setLoading((prevState) => ({ ...prevState, merge: false }))
      return
    }
    let payload = {
      arrInventoryIds: [selectedStock?.id, ...selectedInventoryList],
      description: dataStock?.description,
      images: [{ url: dataStock?.linkFoto }],
      latitude: position?.latitude,
      longitude: position?.longitude,
      newAmount: Number(dataStock?.amount),
      unit: selectedStock?.unit,
      newItemId: dataStock?.itemId,
      ownerCode: dataStock?.ownerCode ?? null,
    }
    if (locationBlocked) {
      alert('Please enable location services to create stock.')
      return
    }
    dispatch(mergeInventory(payload))
      .then((res) => {
        if (res?.error?.message) {
          throw res?.payload?.data?.message || res?.error?.message
        }

        fetchInventory(
          currentPage,
          size,
          searchInventory,
          selectedSort?.type,
          selectedSort?.mode,
          defaultWarehouse?.id,
        )
        onShowHideModal('add', false)
        onShowHideModal('merge', false)
        onShowHideModal('addStock', false)
        setSelectedInventoryList([])
        handleResetDataStock()
        setSelectedStock({})
        setLoading((prevState) => ({ ...prevState, merge: false }))
        enqueueSnackbar('Inventori berhasil digabungkan.', {
          variant: 'success',
        })
      })
      .catch((err) => {
        console.log(err, 'err')
        enqueueSnackbar(err, {
          variant: 'error',
        })
        setLoading((prevState) => ({ ...prevState, merge: false }))
      })
  }

  // NEW REMOVE INVENTORY
  const handleRemoveStock = async (payload) => {
    let action = 'DELETE'
    try {
      const { note = '', buyerName, price = 0 } = payload
      action = payload.action || 'DELETE'

      const promises = selectedDataRemove?.map((data) => {
        const requestPayload = {
          arrInventoryIds: [data.id],
          note,
          action,
          ...(action === 'SELL' && { buyerName, price }),
        }

        return dispatch(deleteInventory(requestPayload))
      })

      await Promise.all(promises)

      enqueueSnackbar('Inventori berhasil dihapus.', { variant: 'success' })

      fetchInventory(
        currentPage,
        size,
        searchInventory,
        selectedSort?.type,
        selectedSort?.mode,
        defaultWarehouse?.id,
      )

      onShowHideModal('confirmRemove', false)
      onShowHideModal('confirmRemoveMultiple', false)
      setSelectedDataRemove([])
      setSelectedInventoryList([])
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Terjadi kesalahan saat menghapus inventori.', {
        variant: 'error',
      })
    } finally {
      setLoading((prevState) => ({ ...prevState, removeStock: false }))
    }
  }
  // END NEW REMOVE INVENTORY

  const onRemoveStock = async (id) => {
    if (loading?.removeStock) return
    setLoading((prevState) => ({ ...prevState, removeStock: true }))
    const result = await getMyLocation()
    if (result?.locationBlocked) {
      enqueueSnackbar('Harap aktifkan lokasi pada perangkat Anda.', {
        variant: 'error',
      })
      setLoading((prevState) => ({ ...prevState, removeStock: false }))
      return
    }
    let payload = {
      arrInventoryIds: selectedInventoryList.map((e) => e?.id),
    }
    if (typeof id === 'number') {
      payload['arrInventoryIds'] = [id]
    }
    if (id?.length) {
      payload['arrInventoryIds'] = id
    }
    if (locationBlocked) {
      alert('Please enable location services to create stock.')
      return
    }
    dispatch(removeInventory(payload))
      .then((res) => {
        fetchInventory(
          currentPage,
          size,
          searchInventory,
          selectedSort?.type,
          selectedSort?.mode,
          defaultWarehouse?.id,
        )
        onShowHideModal('remove', false)
        onShowHideModal('confirmRemove', false)
        onShowHideModal('confirmRemoveMultiple', false)
        onShowHideModal('detail', false)
        setSelectedInventoryList([])
        enqueueSnackbar('Inventori berhasil dihapus.', { variant: 'success' })
        setLoading((prevState) => ({ ...prevState, removeStock: false }))
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoading((prevState) => ({ ...prevState, removeStock: false }))
      })
  }

  const onChangeValueStock = (key, value, index) => {
    const sanitizedValue = value.replace('.', '').replace(/^0+(?!\.|,|$)/, '')

    let newItems = [...splitItems]
    newItems[index] = {
      ...newItems[index],
      [key]: sanitizedValue,
    }

    setSplitItems(newItems)
  }

  const handleClickOutside = (event) => {
    if (
      actionMenuRef.current &&
      !actionMenuRef.current.contains(event.target)
    ) {
      onSelectStockMenu(selectedStock)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault()
      if (!isDetail) {
        if (page > 0) {
          setPage((prevPage) => prevPage - 1)

          setShowModal((prevState) => {
            const updatedModalState = { ...prevState }
            Object.keys(updatedModalState).forEach((key) => {
              updatedModalState[key] = false
            })
            return updatedModalState
          })
        }
      } else {
        if (breadCrumbsData?.length > 1) {
          onManageBreadCrumb({
            label: breadCrumbsData?.[breadCrumbsData?.length - 2]?.label,
            id: breadCrumbsData?.[breadCrumbsData?.length - 2]?.id,
          })
        }
        setShowModal((prevState) => {
          const updatedModalState = { ...prevState }
          Object.keys(updatedModalState).forEach((key) => {
            updatedModalState[key] = false
          })
          return updatedModalState
        })
      }
    }
    window.addEventListener('popstate', handleBackButton)
    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [page, isDetail, breadCrumbsData?.length])

  useEffect(() => {
    if (!isDetail) {
      if (page >= 0) {
        window.history.pushState({ page }, '', ``)
      }
    }
  }, [page])

  const onRemoveGroup = async (groupIds) => {
    try {
      const obj = {
        groupIds,
      }
      const res = await dispatch(removePacking(obj))
      if (res?.error) {
        throw res
      }
      setIsFetch('inventory')
      onShowHideModal('confirmRemoveMultiple', false)
      setSelectedGroupInventory([])
      toggleClearRowsGroup()
      enqueueSnackbar(`Inventori / grup di label berhasil dihapus.`, {
        variant: 'success',
      })
    } catch (error) {
      console.log(error?.message ?? error?.payload)
    } finally {
      setLoading(false)
    }
  }

  const onAddInventoryToGroup = async (idStock) => {
    setLoading((prevState) => ({ ...prevState, addStockToGroup: true }))

    try {
      const obj = {
        id: id,
        addedInventoryIds: [idStock],
      }
      const res = await dispatch(packingAddStock(obj))
      if (res?.error) {
        throw res
      } else {
        setIsFetch('all')
        enqueueSnackbar(`1 inventori berhasil ditambahkan ke grup`, {
          variant: 'success',
        })
        setSelectedInventoryList([])
        toggleClearRows()
      }
    } catch (error) {
      enqueueSnackbar(`1 inventori gagal ditambahkan ke grup`, {
        variant: 'error',
      })
    } finally {
      setLoading((prevState) => ({ ...prevState, addStockToGroup: false }))
    }
  }

  const WORDING_TOOLTIP =
    'Aksi tidak bisa dilakukan karena inventori ini sedang dalam proses transfer'

  const columns = [
    {
      name: <span>Kode Inventori</span>,
      sortable: true,
      sortField: 'ownerCode',
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <span>{row?.ownerCode ?? '-'}</span>
          </div>
        )
      },
    },
    {
      name: <span>Nama Inventori</span>,
      sortable: true,
      sortField: 'name',
      cell: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <span>{row?.item?.nameItem || row?.label}</span>
            </div>
          </>
        )
      },
    },
    {
      name: <span>Jumlah</span>,
      sortable: true,
      sortField: 'amount',
      cell: (row) => {
        return (
          <span>
            {intl.formatNumber(row?.amount || row?.totalInventory, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}{' '}
            {row?.unit || row?.item?.unit}{' '}
          </span>
        )
      },
    },
    {
      name: <span>Gudang</span>,
      cell: (row) => {
        return <span>{row?.warehouse?.name || row?.warehouseName}</span>
      },
    },
    {
      name: <span>Terakhir diperbarui</span>,
      sortable: true,
      sortField: 'updatedAt',
      cell: (row) => {
        return (
          <span>{row?.updatedAt ? moment(row.updatedAt).fromNow() : '-'}</span>
        )
      },
    },
    {
      name: '',
      cell: (row) => {
        const isPending = row?.pending
        return (
          <>
            <div className="d-flex gap-3">
              <Eye
                size={20}
                color="#475467"
                className="button"
                onClick={() => {
                  if (row?.parentGroupId) {
                    navigate(`${URL_INVENTORY}/${row?.id}`, {
                      state: row?.label,
                    })
                    setSearchInventory('')
                  } else {
                    onShowHideModal('detail', true, 'general')
                    setSelectedStock({ ...row?.item, ...row })
                  }
                }}
              />
              {row?.parentGroupId ? (
                <Can I={MANAGE} this={BUSINESS_INVENTORY}>
                  <Edit2
                    onClick={() => {
                      if (!isPending) {
                        setSelectedPacking(row)
                        onShowHideModal('renamePacking', true)
                      }
                    }}
                    size={20}
                    color={`${isPending ? '#D0D5DD' : '#475467'}`}
                    className="button"
                    disabled={isPending}
                    id={`tooltip-inventory-parent-edit-${row?.itemId}`}
                    style={{ cursor: isPending ? 'auto' : 'pointer' }}
                  />
                  {isPending && (
                    <PMBTooltip
                      placement="top"
                      target={`tooltip-inventory-parent-edit-${row?.itemId}`}
                      text={WORDING_TOOLTIP}
                    />
                  )}
                  <Trash2
                    onClick={() => {
                      if (!isPending) {
                        setSelectedPacking(row)
                        onShowHideModal('removePacking', true)
                      }
                    }}
                    size={20}
                    className={`button ${!isPending && 'text-danger'}`}
                    disabled={isPending}
                    style={{
                      color: isPending && '#D0D5DD',
                      cursor: isPending ? 'auto' : 'pointer',
                    }}
                    id={`tooltip-inventory-parent-remove-${row?.itemId}`}
                  />
                  {isPending && (
                    <PMBTooltip
                      placement="top"
                      target={`tooltip-inventory-parent-remove-${row?.itemId}`}
                      text={WORDING_TOOLTIP}
                    />
                  )}
                </Can>
              ) : (
                <>
                  <img
                    className="button"
                    onClick={() => {
                      onShowHideModal('qrCode', true, 'general')
                      setSelectedStock({ ...row?.item, ...row })
                    }}
                    alt="idStock"
                    src={barcodeMenu}
                  />

                  <Can I={MANAGE} this={BUSINESS_INVENTORY}>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="div"
                        onClick={() => {
                          if (!isPending) fetchLabelItems(row?.itemId)
                        }}
                        disabled={isPending}
                      >
                        <MoreVertical
                          disabled={!isPending}
                          size={20}
                          color={`${isPending ? '#D0D5DD' : '#475467'}`}
                          className="button"
                          style={{ cursor: isPending ? 'auto' : 'pointer' }}
                          id={`${isPending && `tooltip-inventory-${row?.itemId}`}`}
                        />
                        {isPending && (
                          <PMBTooltip
                            placement="top"
                            target={`tooltip-inventory-${row?.itemId}`}
                            text={WORDING_TOOLTIP}
                          />
                        )}
                      </DropdownToggle>
                      <DropdownMenu container="body">
                        <DropdownItem
                          className="mb-3 mt-3"
                          onMouseEnter={() => setIsSubMenuVisible(true)}
                          onMouseLeave={() => setIsSubMenuVisible(false)}
                        >
                          <div>
                            <div className="d-flex align-items-center w-100 justify-content-between">
                              <div className="d-flex">
                                <Codepen
                                  size={15}
                                  style={{ marginRight: 10 }}
                                />
                                <CardText style={{ fontSize: 12 }}>
                                  Proses
                                </CardText>
                              </div>
                              <ChevronRight
                                size={15}
                                style={{ marginRight: 10 }}
                              />
                            </div>

                            {isSubMenuVisible ? (
                              <div
                                className="rounded"
                                style={{
                                  position: 'absolute',
                                  right: '100%',
                                  top: '0px',
                                  backgroundColor: '#fff',
                                  border: '1px solid #ddd',
                                  zIndex: 1000,
                                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                }}
                              >
                                {isFetchingMenus ? (
                                  <div
                                    className="d-flex justify-content-center align-items-center py-3"
                                    style={{
                                      minWidth: 240,
                                    }}
                                  >
                                    <Spinner size="sm" color="primary" />
                                  </div>
                                ) : mergedMenusStock2?.length > 0 ? (
                                  mergedMenusStock2.map((el, i) => (
                                    <div
                                      key={i}
                                      className="mb-3 mt-3 py-1 px-2 button d-flex align-items-center hover"
                                      onClick={() => {
                                        if (el.id !== 5) {
                                          // 5 = Ubah Bentuk dari menusStock2
                                          handleDynamicMenuClick(
                                            el,
                                            row?.itemId,
                                          )
                                        }

                                        onShowHideModal(
                                          el?.modal,
                                          true,
                                          el?.editType,
                                          '',
                                          row,
                                        )
                                        setIsSubMenuVisible(false)
                                        onSelectStockMenu(row)
                                      }}
                                    >
                                      <span
                                        style={{
                                          minWidth: 240,
                                          fontSize: 12,
                                          color:
                                            el?.icon === 'trash'
                                              ? '#B42318'
                                              : null,
                                        }}
                                      >
                                        {el?.title}
                                      </span>
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    className="d-flex justify-content-center align-items-center py-3"
                                    style={{
                                      minWidth: 240,
                                    }}
                                  >
                                    <span
                                      style={{ fontSize: 12, color: '#B42318' }}
                                    >
                                      Item tidak tersedia
                                    </span>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </DropdownItem>

                        {menuStock?.map((el, i) => (
                          <DropdownItem
                            key={i}
                            className="mb-3 mt-3 pl-2 button d-flex align-items-center"
                            onClick={() => {
                              onShowHideModal(
                                el?.modal,
                                true,
                                el?.editType,
                                '',
                                row,
                              )
                              onSelectStockMenu(row)
                              if (el?.modal === 'merge') {
                                fetchGabungInventory(0, row)
                              }
                            }}
                          >
                            {el?.iconType === 'img' ? (
                              <img
                                alt={el?.modal}
                                src={el?.img}
                                style={{
                                  width: 15,
                                  height: 15,
                                  marginRight: 10,
                                }}
                              />
                            ) : el?.iconType === 'icon' &&
                              el?.icon === 'split' ? (
                              <Copy size={15} style={{ marginRight: 10 }} />
                            ) : el?.icon === 'eye' ? (
                              <Eye size={15} style={{ marginRight: 10 }} />
                            ) : (
                              <Trash2
                                size={15}
                                style={{ marginRight: 10 }}
                                color="#B42318"
                              />
                            )}
                            <span
                              style={{
                                fontSize: 12,
                                color: el?.icon === 'trash' ? '#B42318' : null,
                              }}
                            >
                              {el?.title}
                            </span>
                          </DropdownItem>
                        ))}
                        <Can I={MANAGE} this={BUSINESS_INVENTORY}>
                          <DropdownItem
                            className="mb-3 mt-3 pl-2 button d-flex align-items-center"
                            onClick={() => {
                              onSelectStockMenu(row)
                              setSelectedDataRemove([row])
                              onShowHideModal('confirmRemove', true)
                            }}
                          >
                            <Trash2
                              size={15}
                              style={{ marginRight: 10 }}
                              color="#B42318"
                            />
                            <span
                              style={{
                                fontSize: 12,
                                color: '#B42318',
                              }}
                            >
                              Hapus
                            </span>
                          </DropdownItem>
                        </Can>

                        {isDetail ? (
                          <DropdownItem
                            className="mb-3 mt-3 pl-2 button d-flex align-items-center"
                            onClick={() => {
                              onSelectStockMenu(row)
                              onShowHideModal('removeItemPacking', true)
                            }}
                          >
                            <FolderMinus
                              size={15}
                              style={{ marginRight: 10 }}
                              color="#B42318"
                            />
                            <span
                              style={{
                                fontSize: 12,
                                color: '#B42318',
                              }}
                            >
                              Keluarkan Inventori
                            </span>
                          </DropdownItem>
                        ) : (
                          <></>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Can>
                </>
              )}
            </div>
          </>
        )
      },
    },
  ]

  const columns2 = [
    {
      name: <span>Nama Grup / Label</span>,
      sortable: true,
      sortField: 'label',
      cell: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <span>{row?.label}</span>
            </div>
          </>
        )
      },
    },
    {
      name: <span>Jumlah</span>,
      sortable: true,
      sortField: 'totalInventory',
      cell: (row) => {
        return (
          <span>
            {intl.formatNumber(row?.inventory?.length || row?.totalInventory, {
              useGrouping: 'always',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}{' '}
            inventori
          </span>
        )
      },
    },
    {
      name: <span>Gudang</span>,
      cell: (row) => {
        return <span>{row?.warehouseName}</span>
      },
    },
    {
      name: <span>Terakhir diperbarui</span>,
      sortable: true,
      sortField: 'updatedAt',
      cell: (row) => {
        return (
          <span>{row?.updatedAt ? moment(row.updatedAt).fromNow() : '-'}</span>
        )
      },
    },
    {
      name: '',
      cell: (row) => {
        const isPending = row?.pending
        return (
          <>
            <div className="d-flex gap-3">
              <Eye
                size={20}
                color="#475467"
                className="button"
                onClick={() => {
                  if (row?.id && row?.label && onManageBreadCrumb) {
                    onManageBreadCrumb({
                      id: row?.id,
                      label: row?.label,
                    })
                  }
                  navigate(`${URL_INVENTORY}/${row?.id}`, { state: row?.label })
                  setSearchGrupInventory('')
                }}
              />
              <Can I={MANAGE} this={BUSINESS_INVENTORY}>
                <Edit2
                  onClick={() => {
                    if (!isPending) {
                      setSelectedPacking(row)
                      onShowHideModal('renamePacking', true)
                    }
                  }}
                  size={20}
                  color={`${isPending ? '#D0D5DD' : '#475467'}`}
                  className="button"
                  disabled={isPending}
                  id={`tooltip-group-edit-${row?.id}`}
                  style={{ cursor: isPending ? 'auto' : 'pointer' }}
                />
                {isPending && (
                  <PMBTooltip
                    placement="top"
                    target={`tooltip-group-edit-${row?.id}`}
                    text={WORDING_TOOLTIP}
                  />
                )}
                <Trash2
                  onClick={() => {
                    if (!isPending) {
                      setSelectedPacking(row)
                      onShowHideModal('removePacking', true)
                    }
                  }}
                  size={20}
                  className={`button ${!isPending && 'text-danger'}`}
                  disabled={isPending}
                  style={{
                    color: isPending && '#D0D5DD',
                    cursor: isPending ? 'auto' : 'pointer',
                  }}
                  id={`tooltip-group-remove-${row?.id}`}
                />
                {isPending && (
                  <PMBTooltip
                    placement="top"
                    target={`tooltip-group-remove-${row?.id}`}
                    text={WORDING_TOOLTIP}
                  />
                )}
              </Can>
            </div>
          </>
        )
      },
    },
  ]

  return (
    <div className="w-100 px-3">
      {renderModal()}
      {showModal?.add ? (
        <Container className="custom-container pb-5">
          <div className="d-flex align-items-start gap-2 pt-3">
            <BreadCrumbs
              breadCrumbActive={'Tambah Inventori'}
              breadCrumbParent={'Inventori'}
              breadCrumbParentOnClick={() => onShowHideModal('add', false)}
            />
          </div>
          <Card className="w-100 border-0 shadow-sm">
            <AddStockModal
              locationBlocked={locationBlocked}
              isOpen={showModal?.add}
              onClose={() => {
                handleResetDataStock()
                onShowHideModal('add', false)
              }}
              items={itemsInventory}
              dataStock={dataStock}
              onShowItem={onShowHideModal}
              selectedUnit={selectedUnit}
              selectedStock={selectedStock}
              onChangeDataStock={onChangeDataStock}
              onChangeAmount={handleChangeInputSeparator}
              onChangeUnitValue={handleChangeInputUnitValueSeparator}
              loading={loading?.create}
              onCreateStock={(type) => {
                if (showModal?.merge) {
                  onMergeStock()
                } else {
                  onCreateStock(type)
                }
              }}
              defaultWarehouse={defaultWarehouse}
            />
          </Card>
        </Container>
      ) : (
        <>
          <div className="d-flex align-items-start gap-2 pt-3">
            {isDetail ? (
              <BreadCrumbs
                breadCrumbActive={
                  breadCrumbsData ?? [
                    { label: detail?.label ?? dataState, id: id },
                  ]
                }
                breadCrumbParent={'Inventori'}
                breadCrumbParentLink={URL_INVENTORY}
                onClickBreadCrumb={(data) => {
                  onManageBreadCrumb({
                    label: data?.label,
                    id: data?.id,
                  })
                  navigate(`${URL_INVENTORY}/${data?.id}`, {
                    state: data?.label,
                  })
                  setSearchGrupInventory('')
                }}
              />
            ) : (
              <BreadCrumbs breadCrumbActive={'Inventori'} />
            )}
          </div>

          {!isDetail ? (
            <Col className="mb-3">
              <p className="mb-2">Gudang</p>
              <div style={{ maxWidth: '340px' }}>
                <Select
                  style={{ width: '100%' }}
                  options={warehouses?.newData}
                  onChange={(e) => {
                    localStorage.setItem(
                      'defaultWarehouseInventory',
                      JSON.stringify(e),
                    )
                    handleFilterWarehouse(e?.id)
                  }}
                  placeholder="Pilih gudang"
                  value={warehouses?.newData?.find(
                    (e) => e?.id === defaultWarehouse?.id,
                  )}
                />
              </div>
            </Col>
          ) : (
            <></>
          )}
          <Card className="mb-3 border-0 shadow-sm">
            <CardBody>
              <DataTable
                columns={columns}
                data={inventoryList}
                progressPending={loading?.getInventory}
                selectableRows
                onSelectedRowsChange={(e) => {
                  onSelectStock(e)
                  onSelectStockRemove(e)
                }}
                selectableRowDisabled={(row) => row?.pending === true}
                clearSelectedRows={clearRows}
                sortIcon={<ChevronDown />}
                sortServer
                onSort={handleSort}
                defaultSortFieldId={5}
                defaultSortAsc={false}
                noDataComponent={
                  <p className="my-3 fw-semibold">
                    Belum ada inventori yang ditambahkan.
                  </p>
                }
                pagination
                paginationServer
                paginationComponent={(props) => (
                  <PMBPagination
                    rowCount={totalItems}
                    currentPage={currentPage}
                    rowsPerPage={size ?? 10}
                    setCurrentPage={(e) => {
                      setCurrentPage(e)
                      fetchInventory(
                        e,
                        size,
                        searchInventory,
                        selectedSort?.type,
                        selectedSort?.mode,
                        defaultWarehouse?.id,
                      )
                    }}
                    setRowsPerPage={(e) => {
                      setSize(e)
                      fetchInventory(
                        currentPage,
                        e,
                        searchInventory,
                        selectedSort?.type,
                        selectedSort?.mode,
                        defaultWarehouse?.id,
                      )
                    }}
                    props={props}
                  />
                )}
                subHeader={true}
                subHeaderComponent={
                  <div className="container-fluid p-0">
                    <Row className="mb-2">
                      <Col>
                        <h5 className="fw-semibold">Inventori</h5>
                      </Col>
                      <Col>
                        <Can I={MANAGE} this={BUSINESS_INVENTORY}>
                          <div className="d-flex gap-2 justify-content-end align-items-center">
                            {selectedInventoryList?.length ? (
                              <div className="d-flex gap-2 align-items-center">
                                <span className="text-gray-500 me-2">
                                  {selectedInventoryList?.length > 0 && (
                                    <>
                                      {selectedInventoryList.some(
                                        (obj) => obj.parentGroupId,
                                      ) &&
                                      selectedInventoryList.some(
                                        (obj) => !obj.parentGroupId,
                                      )
                                        ? `${selectedInventoryList.length} item dipilih`
                                        : selectedInventoryList.some(
                                              (obj) => obj.parentGroupId,
                                            )
                                          ? `${selectedInventoryList.length} grup dipilih`
                                          : `${selectedInventoryList.length} inventori dipilih`}
                                    </>
                                  )}
                                </span>
                                {isDetail ? (
                                  <>
                                    <CustomButton
                                      className="btn-icon ml-1"
                                      size="sm"
                                      outline
                                      id="removeItemPacking"
                                      color="danger"
                                      onClick={() => {
                                        onShowHideModal(
                                          'removeItemPacking',
                                          true,
                                        )
                                      }}
                                    >
                                      <FolderMinus size={14} />
                                    </CustomButton>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="removeItemPacking"
                                    >
                                      Keluarkan Inventori
                                    </UncontrolledTooltip>
                                  </>
                                ) : (
                                  <>
                                    <CustomButton
                                      className="btn-icon ml-1"
                                      size="sm"
                                      outline
                                      id="packing"
                                      color="primary"
                                      onClick={() => {
                                        onShowHideModal('packing', true)
                                      }}
                                    >
                                      <Database size={14} />
                                    </CustomButton>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="packing"
                                    >
                                      Grupkan
                                    </UncontrolledTooltip>
                                  </>
                                )}

                                <Can I={MANAGE} this={BUSINESS_INVENTORY}>
                                  <CustomButton
                                    className="btn-icon ml-1"
                                    size="sm"
                                    outline
                                    color="danger"
                                    id="deleteBtn"
                                    onClick={() => {
                                      // onShowHideModal(
                                      //   'confirmRemoveMultiple',
                                      //   true,
                                      // )
                                      onShowHideModal('confirmRemove', true)
                                    }}
                                  >
                                    <Trash2 size={14} />
                                  </CustomButton>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target="deleteBtn"
                                  >
                                    Hapus
                                  </UncontrolledTooltip>{' '}
                                </Can>
                                <span className="text-gray-300">|</span>
                              </div>
                            ) : null}
                            <div>
                              <CustomButton
                                onClick={() => {
                                  onShowHideModal('add', true)
                                }}
                                size="sm"
                                color="primary"
                                style={{
                                  borderRadius: 8,
                                  paddingRight: 18,
                                  paddingLeft: 18,
                                }}
                              >
                                + Tambah Inventori
                              </CustomButton>
                            </div>
                          </div>
                        </Can>
                      </Col>
                    </Row>

                    <div className="d-flex gap-2 gap-md-3 mb-1 mb-md-2 flex-column flex-md-row">
                      <Col
                        style={{
                          maxWidth: '346px',
                        }}
                      >
                        <p className="mb-2">Cari</p>
                        <div className="d-flex mb-3">
                          <InputGroup style={{ borderColor: '#D0D5DD' }}>
                            <InputGroupText
                              style={{
                                backgroundColor: 'white',
                                borderRightColor: 'white',
                              }}
                            >
                              <Search color="#667085" size={16} />
                            </InputGroupText>
                            <Input
                              style={{
                                maxWidth: '100%',
                              }}
                              value={searchInventory}
                              placeholder="Cari inventori..."
                              onChange={(text) =>
                                handleSearchChange(text.target.value)
                              }
                            />
                          </InputGroup>
                        </div>
                      </Col>
                    </div>
                    {toast?.show ? (
                      <Card
                        className="small-padding mb-4"
                        style={{
                          backgroundColor: '#ECFDF3',
                          borderColor: '#027948',
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <CheckCircle
                              size={16}
                              className="small-margin-right"
                              color="#006386"
                            />
                            <CardText style={{ fontSize: 14 }}>
                              {toast?.title}
                            </CardText>
                          </div>
                          <X
                            className="button"
                            size={16}
                            color="#667085"
                            onClick={() => setToast({ show: false })}
                          />
                        </div>
                      </Card>
                    ) : null}
                  </div>
                }
              />
            </CardBody>
          </Card>

          <Card className="mb-3 border-0 shadow-sm">
            <CardBody>
              <Row>
                <Col>
                  <h5 className="fw-semibold">Grup Inventori</h5>
                </Col>
                <Can I={MANAGE} this={BUSINESS_INVENTORY}>
                  <Col className="d-flex justify-content-end">
                    {selectedGroupInventory?.length ? (
                      <div className="d-flex gap-2 align-items-center">
                        <span className="text-gray-500 me-2">
                          {selectedGroupInventory?.length + ' grup dipilih'}
                        </span>
                        {/* <CustomButton
                          className="btn-icon ml-1"
                          size="sm"
                          outline
                          id="pindahGudang"
                          color="primary"
                          onClick={() => {
                            onShowHideModal('pindahGudang', true)
                          }}
                        >
                          <CornerUpRight size={14} />
                        </CustomButton>
                        <UncontrolledTooltip
                          placement="bottom"
                          target="pindahGudang"
                        >
                          Pindah Gudang
                        </UncontrolledTooltip> */}
                        <>
                          <CustomButton
                            className="btn-icon ml-1"
                            size="sm"
                            outline
                            id="packing"
                            color="primary"
                            onClick={() => {
                              setIsSubGroup(true)
                              onShowHideModal('packing', true)
                            }}
                          >
                            <Database size={14} />
                          </CustomButton>
                          <UncontrolledTooltip
                            placement="bottom"
                            target="packing"
                          >
                            Grupkan
                          </UncontrolledTooltip>
                        </>
                        {isDetail ? (
                          <CustomButton
                            className="btn-icon ml-1"
                            size="sm"
                            outline
                            id="removeItemPacking"
                            color="danger"
                            onClick={() => {
                              onShowHideModal('moveOutPacking', true)
                            }}
                          >
                            <FolderMinus size={14} />
                          </CustomButton>
                        ) : null}
                        <CustomButton
                          className="btn-icon ml-1"
                          size="sm"
                          outline
                          color="danger"
                          id="deleteBtn"
                          onClick={() => onShowHideModal('removePacking', true)}
                        >
                          <Trash2 size={14} />
                        </CustomButton>
                        <UncontrolledTooltip
                          placement="bottom"
                          target="deleteBtn"
                        >
                          Hapus
                        </UncontrolledTooltip>{' '}
                      </div>
                    ) : null}
                  </Col>
                </Can>
              </Row>
              <div className="d-flex gap-2 gap-md-3 mb-1 mb-md-2 flex-column flex-md-row mt-2">
                <Col
                  style={{
                    maxWidth: '346px',
                  }}
                >
                  <p className="mb-2">Cari</p>
                  <div className="d-flex mb-3">
                    <InputGroup style={{ borderColor: '#D0D5DD' }}>
                      <InputGroupText
                        style={{
                          backgroundColor: 'white',
                          borderRightColor: 'white',
                        }}
                      >
                        <Search color="#667085" size={16} />
                      </InputGroupText>
                      <Input
                        style={{
                          maxWidth: '100%',
                        }}
                        value={searchGrupInventory}
                        placeholder="Cari label..."
                        onChange={(text) =>
                          handleSearchGroupChange(text.target.value)
                        }
                      />
                    </InputGroup>
                  </div>
                </Col>
                {/* SELECT GRUP INVENTORI */}
                {/* {!isDetail ? (
                    <Col>
                      <p className="mb-2">Gudang</p>
                      <div style={{ maxWidth: '340px' }}>
                        <Select
                          style={{ width: '100%' }}
                          options={warehouses?.newData}
                          onChange={(e) => handleFilterGrup(e?.id)}
                          placeholder="Pilih gudang"
                          value={warehouses?.newData?.find(
                            (e) => e?.id === defaultWarehouse?.idGrup,
                          )}
                        />
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )} */}
              </div>
              {currentGroups?.length < 1 ? (
                isDetail ? (
                  <span>Belum ada grup inventori di gudang ini.</span>
                ) : (
                  <span>
                    Belum ada grup inventori di gudang ini. Centang beberapa
                    inventori dan tekan tombol Grupkan untuk membuat grup
                    inventori.
                  </span>
                )
              ) : (
                <div>
                  <DataTable
                    columns={columns2}
                    data={currentGroups?.length ? [...currentGroups] : []}
                    progressPending={loading?.group}
                    sortServer
                    onSort={handleSortGroup}
                    defaultSortFieldId={4}
                    defaultSortAsc={false}
                    selectableRows
                    onSelectedRowsChange={(e) => onSelectGroup(e)}
                    selectableRowDisabled={(e) => e?.pending === true}
                    clearSelectedRows={clearRowsGroup}
                    noDataComponent={
                      <p className="my-3 fw-semibold">
                        Belum ada inventori grup yang ditambahkan.
                      </p>
                    }
                    pagination
                    paginationServer
                    paginationComponent={(props) => (
                      <PMBPagination
                        rowCount={paginationGroup?.totalItems}
                        currentPage={paginationGroup?.currentPage}
                        rowsPerPage={paginationGroup?.size ?? 10}
                        setCurrentPage={(e) => {
                          setPaginationGroup((prev) => ({
                            ...prev,
                            currentPage: e,
                          }))
                          fetchGroupInventory(
                            e,
                            paginationGroup?.size,
                            searchInventory,
                            selectedSortGroup?.sortField,
                            selectedSortGroup?.mode,
                            defaultWarehouse?.id,
                          )
                        }}
                        setRowsPerPage={(e) => {
                          setPaginationGroup((prev) => ({
                            ...prev,
                            size: e,
                          }))
                          fetchGroupInventory(
                            paginationGroup?.currentPage,
                            e,
                            searchInventory,
                            selectedSortGroup?.sortField,
                            selectedSortGroup?.mode,
                            defaultWarehouse?.id,
                          )
                        }}
                        props={props}
                      />
                    )}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )

  function renderModal() {
    return (
      <>
        <ModalPindahGudang
          isOpen={showModal?.pindahGudang}
          onClose={() => onShowHideModal('pindahGudang', false)}
        />
        <ModalPacking
          isOpen={showModal?.packing}
          onClose={() => {
            onShowHideModal('packing', false)
            onShowHideModal('detail', false)
          }}
          data={
            isSubGroup
              ? selectedGroupInventory
              : showModal?.detail
                ? [selectedStock]
                : selectedInventoryList
          }
          setIsFetch={setIsFetch}
          setSelectedInventoryList={
            isSubGroup ? setSelectedGroupInventory : setSelectedInventoryList
          }
          toggleClearRows={isSubGroup ? toggleClearRowsGroup : toggleClearRows}
          isSubGroup={isSubGroup}
          packing={showModal?.packing}
          setSelectedStock={(data) => {
            setSelectedStock((prevState) => ({ ...prevState, ...data }))
          }}
          actionType={actionType}
        />
        <ModalRenamePacking
          isOpen={showModal?.renamePacking}
          onClose={() => onShowHideModal('renamePacking', false)}
          data={selectedPacking}
          setIsFetch={setIsFetch}
          isDetail={isDetail}
        />
        <ModalRemovePacking
          isOpen={showModal?.removePacking}
          onClose={() => onShowHideModal('removePacking', false)}
          data={
            selectedGroupInventory?.length > 0
              ? selectedGroupInventory
              : selectedPacking
          }
          isDetail={isDetail}
          setIsFetch={setIsFetch}
          setSelectedGroupInventory={setSelectedGroupInventory}
          toggleClearRowsGroup={toggleClearRowsGroup}
        />
        <ModalRemoveItemPacking
          isOpen={showModal?.removeItemPacking}
          onClose={() => {
            onShowHideModal('removeItemPacking', false)
            onShowHideModal('detail', false)
          }}
          data={selectedStock ?? selectedInventoryList}
          setIsFetch={setIsFetch}
        />
        <ModalRemoveItemPacking
          isOpen={showModal?.moveOutPacking}
          onClose={() => onShowHideModal('moveOutPacking', false)}
          data={selectedGroupInventory}
          setIsFetch={setIsFetch}
          type={'group'}
        />
        <ActionModal
          isOpen={showModal?.menuOption}
          onClose={() => onShowHideModal('menuOption', false)}
          onSelectMenu={(item) =>
            onShowHideModal(item?.modal, true, item?.editType)
          }
        />
        <SidebarDetails
          open={showModal?.qrCode}
          title="Detail Informasi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => onShowHideModal('qrCode', false)}
        >
          <IDStockModal
            isOpen={showModal?.qrCode}
            onClose={() => onShowHideModal('qrCode', false)}
            selectedStock={selectedStock}
          />
        </SidebarDetails>

        <SidebarDetails
          open={showModal?.edit}
          title="Ubah Data"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => onShowHideModal('edit', false)}
          style={{ border: 'none' }}
        >
          <EditStockModal
            isOpen={showModal?.edit}
            onClose={() => onShowHideModal('edit', false)}
            selectedEditAction={selectedEditAction}
            selectedStock={selectedStock}
            onChangeSelectedDataStock={onChangeSelectedDataStock}
            onShowItem={onShowHideModal}
            onClickUpdate={onUpdateStock}
            loading={loading?.update}
            onChangeAmount={handleChangeInputSeparator}
            onChangeUnitValue={handleChangeInputUnitValueSeparator}
            displayAmount={displayAmount}
            displayUnitValue={displayUnitValue}
          />
        </SidebarDetails>

        {/* NEW FOR TRANFORM */}
        <SidebarDetails
          open={showModal?.transform && isTransform}
          title="Proses"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => onShowHideModal('transform', false)}
          style={{ border: 'none' }}
        >
          <TransformModal
            onUpdateStock={onUpdateStock}
            isLoading={loading?.update}
            selectedStock={selectedStock}
            notes={notes}
            setNotes={setNotes}
            transformItems={transformItems}
            setTransformItems={setTransformItems}
            itemsList={
              itemsList?.length
                ? itemsList?.map((item) => ({
                    value: item?.id,
                    label: item?.nameItem,
                    image: item?.linkFoto,
                  }))
                : []
            }
          />
        </SidebarDetails>
        {/* END NEW FOR TRANFORM */}

        <SidebarDetails
          open={showModal?.split}
          title="Detail Informasi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => {
            setSplitItems([])
            onShowHideModal('split', false)
          }}
        >
          <SplitStockModal
            isOpen={showModal?.split}
            selectedStock={selectedStock}
            splitItems={splitItems}
            enableSplit={enableSplit}
            addSplitItem={addSplitItem}
            onShowItem={onShowHideModal}
            onSelectItemUnit={(item, index) => onSelectItemUnit(item, index)}
            setIndexSelectedSplitStock={(index) =>
              setIndexSelectedSplitStock(index)
            }
            onChangeValue={onChangeValueStock}
            onSplitStock={onSplitStock}
            loading={loading.split}
          />
        </SidebarDetails>
        <SidebarDetails
          open={showModal?.merge}
          title="Gabungkan Inventori"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => {
            setSelectedInventoryList([])
            onShowHideModal('merge', false)
          }}
        >
          <MergeStockModal
            selectedList={selectedInventoryList}
            isOpen={showModal?.merge}
            onClose={() => {
              setSelectedInventoryList([])
              onShowHideModal('merge', false)
            }}
            selectedStock={selectedStock}
            data={inventoryGabungList}
            onSelectStock={(item) => onSelectStock(item)}
            currentPage={paginationGabung?.currentPage}
            totalPage={paginationGabung?.totalPages}
            onClickPagination={(type) => onClickNextBack(type)}
            onMergeStock={onMergeStock}
            onShowHideModal={onShowHideModal}
            loading={loading.merge}
          />
        </SidebarDetails>
        <SidebarDetails
          isBack
          handleBack={() => {
            handleResetDataStock()
            onShowHideModal('merge', true)
            onShowHideModal('addStock', false)
          }}
          open={showModal?.addStock}
          title="Gabungkan Menjadi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => {
            setSelectedInventoryList([])
            onShowHideModal('addStock', false)
          }}
        >
          <AddStockSidebar
            onClose={() => {
              handleResetDataStock()
              setSelectedInventoryList([])
              onShowHideModal('addStock', false)
            }}
            selectedStock={selectedStock}
            data={inventoryList}
            onShowItem={onShowHideModal}
            currentPage={currentPage}
            totalPage={totalPages}
            onClickPagination={(type) => onClickNextBack(type)}
            onMergeStock={onMergeStock}
            onShowHideModal={onShowHideModal}
            loading={loading.addStock}
            dataStock={dataStock}
            onChangeDataStock={onChangeDataStock}
          />
        </SidebarDetails>
        <SidebarDetails
          open={showModal?.merge}
          title="Gabungkan Inventori"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => {
            onShowHideModal('merge', false)
            setSelectedInventoryList([])
          }}
        >
          <MergeStockModal
            selectedList={selectedInventoryList}
            isOpen={showModal?.merge}
            onClose={() => {
              setSelectedInventoryList([])
              onShowHideModal('merge', false)
            }}
            selectedStock={selectedStock}
            data={inventoryGabungList}
            onSelectStock={(item) => onSelectStock(item)}
            currentPage={paginationGabung?.currentPage}
            totalPage={paginationGabung?.totalPages}
            onClickPagination={(type) => onClickNextBack(type, false, 'gabung')}
            onMergeStock={onMergeStock}
            onShowHideModal={onShowHideModal}
            loading={loading.merge}
          />
        </SidebarDetails>
        <ItemModal
          isOpen={showModal?.item}
          itemsList={itemsInventory}
          onClose={() => onShowHideModal('item', false)}
          onSelectItem={(item) => onSelectItem(item)}
          loading={loading?.getItems}
        />
        <ItemUnitModal
          isOpen={showModal?.itemUnit}
          onClose={() => onShowHideModal('itemUnit', false)}
          onSelectItemUnit={(item) => onSelectItemUnit(item)}
        />
        <ConfirmRemoveInventory
          isOpen={showModal?.confirmRemove}
          onClose={() => onShowHideModal('confirmRemove', false)}
          onSelectItemUnit={(item) => onSelectItemUnit(item)}
          handleRemoveStock={(data) => handleRemoveStock(data)}
          disabled={loading?.removeStock}
        />
        {/* <ConfirmRemoveModal
          isOpen={showModal?.confirmRemove}
          onClose={() => onShowHideModal('confirmRemove', false)}
          onSelectItemUnit={(item) => onSelectItemUnit(item)}
          onRemoveStock={() => onRemoveStock(selectedStock?.id)}
          disabled={loading?.removeStock}
        /> */}
        <ConfirmRemoveMultipleModal
          selectedInventoryList={selectedInventoryList}
          isOpen={showModal?.confirmRemoveMultiple}
          onClose={() => onShowHideModal('confirmRemoveMultiple', false)}
          onSelectItemUnit={(item) => onSelectItemUnit(item)}
          onRemoveStock={(data) => onRemoveStock(data)}
          disabled={loading?.removeStock}
          data={selectedInventoryList}
          onRemoveGroup={(data) => onRemoveGroup(data)}
        />
        <SidebarDetails
          open={showModal?.detail}
          title="Detail Informasi"
          size="lg"
          headerClassName="mb-1"
          contentClassName="pt-0"
          toggleSidebar={() => onShowHideModal('detail', false)}
        >
          <DetailModal
            onClose={() => onShowHideModal('detail', false)}
            selectedStock={selectedStock}
            onClickMenu={() => onShowHideModal('menuOption', true)}
            onSetGroup={() => {
              onShowHideModal('packing', true)
            }}
            onMoveOutGroup={() => {
              onShowHideModal('removeItemPacking', true)
            }}
            onMovePack={() => {
              onShowHideModal('packing', true)
              setActionType('Pindah')
            }}
          />
        </SidebarDetails>
      </>
    )
  }
}
