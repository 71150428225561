import { createAppSlice } from 'app/createAppSlice'
import { getComodities, getDetailCashFlow, getUnitList } from './cashFlowAction'

const date = new Date()
const y = date.getFullYear()
const m = date.getMonth()
const firstDay = new Date(y, m, 1)
const lastDay = new Date(y, m, date.getDate())

const initialState = {
  detail: {},
  isOpenSidebarCashFlow: false,
  isOpenModalCategory: false,
  isOpenModalDelete: false,
  isOpenBanner: false,
  units: [],
  comodities: [],
  startdate: '',
  enddate: '',
  tag: {
    size: 10,
    page: 0,
  },
  filters: {
    selectedDate: [firstDay, lastDay],
    selectedFilter: { label: 'Semua catatan', value: 'all' },
  },
}

export const cashFlowSlice = createAppSlice({
  name: 'catatan-arus-kas',
  initialState,
  reducers: (create) => ({
    toggleSidebarCashflow: create.reducer((state, { payload }) => {
      state.isOpenSidebarCashFlow = !state.isOpenSidebarCashFlow
    }),
    toggleModalDelete: create.reducer((state, { payload }) => {
      state.isOpenModalDelete = !state.isOpenModalDelete
    }),
    toggleModalCategory: create.reducer((state, { payload }) => {
      state.isOpenModalCategory = !state.isOpenModalCategory
    }),
    toggleBanner: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    setDate: create.reducer((state, { payload }) => {
      state.startdate = payload.startdate
      state.enddate = payload.enddate
    }),
    setFetchOption: create.reducer((state, { payload }) => {
      state.tag = { ...state.tag, ...payload }
    }),
    setFilters: create.reducer((state, { payload }) => {
      state.filters = { ...state.filters, ...payload }
    }),
  }),
  extraReducers: (builder) => {
    builder.addCase(getDetailCashFlow.fulfilled, (state, { payload }) => {
      state.detail = payload
      state.loading = false
    })
    builder.addCase(getDetailCashFlow.pending, (state, { payload }) => {
      state.loading = true
    })
    builder.addCase(getDetailCashFlow.rejected, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(getComodities.fulfilled, (state, { payload }) => {
      state.comodities = payload
      state.loading = false
    })
    builder.addCase(getComodities.pending, (state, { payload }) => {
      state.loading = true
    })
    builder.addCase(getComodities.rejected, (state, { payload }) => {
      state.loading = false
    })
    builder.addCase(getUnitList.fulfilled, (state, { payload }) => {
      state.units = payload
      state.loading = false
    })
    builder.addCase(getUnitList.pending, (state, { payload }) => {
      state.loading = true
    })
    builder.addCase(getUnitList.rejected, (state, { payload }) => {
      state.loading = false
    })
  },
})

export const {
  toggleSidebarCashflow,
  toggleModalCategory,
  toggleModalDelete,
  toggleBanner,
  setDate,
  setFetchOption,
  setFilters,
} = cashFlowSlice.actions

export default cashFlowSlice.reducer
