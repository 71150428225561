import { useEffect, useRef, useState } from 'react'
import { Input, InputGroup } from 'reactstrap'

const InventoryCodeInput = ({
  value,
  onChange,
  placeholder = 'Masukkan kode inventori',
  maxSuggestions = 5,
  isAutoFocus = false,
}) => {
  const [suggestions, setSuggestions] = useState([])
  const inputRef = useRef(null)

  useEffect(() => {
    const storedCodes =
      JSON?.parse(localStorage?.getItem('lastInventoryCodes')) || []
    setSuggestions(storedCodes)
  }, [])

  const saveToLocalStorage = (newCode) => {
    let storedCodes =
      JSON?.parse(localStorage?.getItem('lastInventoryCodes')) || []

    storedCodes = storedCodes?.filter((code) => code !== newCode)

    storedCodes?.unshift(newCode)
    if (storedCodes?.length > maxSuggestions) {
      storedCodes = storedCodes?.slice(0, maxSuggestions)
    }

    localStorage?.setItem('lastInventoryCodes', JSON?.stringify(storedCodes))
  }

  const handleBlur = () => {
    if (value && value?.trim()) {
      saveToLocalStorage(value?.trim())
    }
  }

  // AUTOFOCUS
  useEffect(() => {
    if (isAutoFocus) {
      inputRef.current?.focus()
    }
  }, [isAutoFocus])

  return (
    <InputGroup className="mt-2">
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={handleBlur}
        innerRef={inputRef}
        list="inventory-suggestions"
      />
      <datalist id="inventory-suggestions">
        {suggestions.map((suggestion, index) => (
          <option key={index} value={suggestion} />
        ))}
      </datalist>
    </InputGroup>
  )
}

export default InventoryCodeInput
