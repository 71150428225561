import ZapIcon from 'assets/icons/zap.svg'
import BreadCrumbs from 'components/breadcrumbs'
import CustomButton from 'components/Buttons/CustomButton'
import LoadingV2 from 'components/LoadingV2'
import PMBModalRemove from 'components/PMBModalRemove'
import { useSnackbar } from 'notistack'
import { setTotal } from 'private-views/transactions/commodityPurchaseSlice'
import { useState } from 'react'
import { Check } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import {
  useFetchTransactionByIdQuery,
  useFetchTransactionNoteQuery,
  useRejectTransactionMutation,
} from '../dashboardApiSlice'
import ModalChoosePayment from './ModalChoosePayment'
import ModalTransactionNote from './ModalTransactionNote'
import DetailOrderSection from './Sections/DetailOrderSection'
import HeaderSection from './Sections/HeaderSection'
import HistoryTransactionSection from './Sections/HistoryTransactionSection'
import TransactionInformation from './Sections/TransactionInformation'
import SidebarDetailPayment from './SidebarDetailPayment'

const DetailTransaction = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()
  const { ownerData, profile, balance } = useSelector((s) => s.dashboard)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const transactionType = searchParams.get('type')

  const isBuyer = transactionType === 'BUY'

  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const [isModalPayment, setIsModalPayment] = useState(false)
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false)
  const [isNoteModalOpenEdit, setIsNoteModalOpenEdit] = useState(false)
  const [isChangeInstantPayment, setIsChangeInstantPayment] = useState(false)
  const [isRejectTransaction, setIsRejectTransaction] = useState(false)

  const toggleSidebar = () => setIsOpenSidebar((prev) => !prev)

  const {
    data,
    isLoading,
    refetch: refetchTransactionById,
  } = useFetchTransactionByIdQuery({ id })
  const transaction = data?.items?.[0]

  const escrowpaymentId = transaction?.escrowpaymentId
  const tradeshortcutId = !escrowpaymentId ? transaction?.id : undefined

  const {
    data: transactionNoteData,
    isLoading: isTransactionNoteLoading,
    refetch,
  } = useFetchTransactionNoteQuery(
    {
      type: transactionType,
      escrowpaymentId,
      tradeshortcutId,
    },
    { skip: !transactionType || !(escrowpaymentId || tradeshortcutId) },
  )

  const [
    rejectTransaction,
    { isLoading: isLoadinReject, isError, data: dataReject },
  ] = useRejectTransactionMutation()

  if (isLoading) return <LoadingV2 />

  const togglePayment = () => {
    setIsModalPayment((prev) => !prev)
    setIsChangeInstantPayment(false)
  }

  const toggleNoteModal = () => {
    setIsNoteModalOpen((prev) => !prev)
    if (isNoteModalOpenEdit) {
      setIsNoteModalOpenEdit(false)
    }
  }

  const handleRefetch = () => {
    refetch()
  }

  const isCreator =
    profile?.name === transaction?.requesterName ||
    (transaction?.orderDetail?.type === 'BUY'
      ? profile?.name === transaction?.buyerDetail?.fullName
      : profile?.name === transaction?.sellerDetail?.fullName)

  const sellerUserId = Number(transaction?.sellerDetail?.userId)
  const buyerUserId = Number(transaction?.buyerDetail?.userId)
  const ownerUserId = Number(ownerData?.userId)

  const isSellerShowCard =
    transaction?.status === 'Menunggu Pembayaran Pembeli' &&
    !transaction?.escrowpaymentId &&
    transaction?.orderDetail.type === 'SELL' &&
    !transaction?.buyerDetail?.mustVerify &&
    Number(transaction?.buyerDetail?.userId) === ownerUserId

  const isBuyerShowCard =
    transaction?.status === 'Menunggu Pembayaran Pembeli' &&
    !transaction?.escrowpaymentId &&
    transaction?.orderDetail.type === 'BUY'

  const isShowCardPaymentandReject = isSellerShowCard || isBuyerShowCard

  const onHandleOpenCloseModalReject = () => {
    setIsRejectTransaction((prev) => !prev)
  }

  const onRejectTransaction = async (id) => {
    try {
      if (!id) {
        enqueueSnackbar('Transaksi gagal ditolak!', {
          variant: 'error',
        })

        return
      }

      const res = await rejectTransaction({ id }).unwrap()

      if (res) {
        enqueueSnackbar('Transaksi berhasil ditolak!', {
          variant: 'success',
        })

        refetchTransactionById()
        handleRefetch()
        onHandleOpenCloseModalReject()
      }
    } catch (error) {
      console.warn(error)
      enqueueSnackbar(
        error?.data?.meta?.message ||
          error?.data?.message ||
          error?.message ||
          'Transaksi gagal ditolak!',
        {
          variant: 'error',
        },
      )

      onHandleOpenCloseModalReject()
    }
  }

  return (
    // <div className="container mt-4">
    <div className="w-100 px-4">
      {/* Breadcrumb */}
      <div className="mt-4">
        <BreadCrumbs
          breadCrumbActive={`Detail ${isBuyer ? 'Pembelian' : 'Penjualan'}`}
        />
      </div>

      {/* MODAL REJECT TRANSACTION  */}
      <PMBModalRemove
        isOpen={isRejectTransaction}
        onClose={() => onHandleOpenCloseModalReject()}
        title={`Tolak transaksi`}
        dataId={transaction?.id}
        desc={`Apakah Anda yakin ingin menolak transaksi dari ${transaction?.sellerDetail?.fullName ?? '-'}?`}
        isLoading={isLoadinReject}
        onRemoveData={(id) => {
          onRejectTransaction(id)
        }}
        buttonTitle="Tolak"
      />
      {/* END MODAL REJECT TRANSACTION  */}

      {/* MODAL PAYMENT */}
      <ModalChoosePayment
        isOpen={isModalPayment}
        toggle={togglePayment}
        transaction={transaction}
        refetchTransactionById={refetchTransactionById}
        isChangeInstantPayment={isChangeInstantPayment}
      />
      {/* END MODAL PAYMENT */}

      {/* SIDEBAR */}
      <SidebarDetailPayment
        isOpen={isOpenSidebar}
        toggle={toggleSidebar}
        row={transaction}
      />
      {/* END SIDEBAR */}

      {/* MODAL TRANSACTION NOTE */}
      <ModalTransactionNote
        open={isNoteModalOpen}
        toggle={toggleNoteModal}
        transactionNoteData={transactionNoteData}
        handleRefetch={handleRefetch}
        isEdit={isNoteModalOpenEdit}
      />
      {/* END MODAL TRANSACTION NOTE */}

      {/* HEADER */}
      <HeaderSection transaction={transaction} isBuyer={isBuyer} />
      {/* END HEADER */}

      <Row>
        {/* LEFT COLUMN  */}
        <Col md="5">
          {/* INFO PEMBAYARAN */}
          {transaction?.orderDetail?.disbursmentPricing && (
            <Card
              className="shadow-sm text-center mb-3"
              style={{
                backgroundColor: '#ECFAFF',
                border: '1px solid #8ABBCC !important',
              }}
            >
              <CardBody>
                <h6 className="fw-semibold">
                  Pembayaran{' '}
                  {Number(transaction?.orderDetail?.disbursmentPricing?.fee) ===
                  0
                    ? transaction?.orderDetail?.disbursmentPricing?.title
                    : `Instan ${transaction?.orderDetail?.disbursmentPricing?.title}`}
                </h6>
                <p>
                  {transaction?.orderDetail?.disbursmentPricing?.description ??
                    '-'}
                </p>
              </CardBody>
            </Card>
          )}
          {/* END INFO PEMBAYARAN */}

          {/* LANJUT PEMBAYARAN */}
          {isShowCardPaymentandReject && (
            <Card
              className="shadow-sm text-center mb-3"
              style={{
                backgroundColor: '#ECFAFF',
                border: '1px solid #8ABBCC !important',
              }}
            >
              <CardBody>
                <p>Lakukan pembayaran untuk melanjutkan transaksi</p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {isSellerShowCard && (
                    <Button
                      outline
                      color="primary"
                      size="sm"
                      style={{ flex: 1 }}
                      onClick={() => onHandleOpenCloseModalReject()}
                    >
                      Tolak
                    </Button>
                  )}

                  <CustomButton
                    block
                    color="primary"
                    size="sm"
                    style={{ flex: 1 }}
                    onClick={() => {
                      let dataComodities = []
                      data?.items?.forEach((e) => {
                        e?.itemDetail?.forEach((i) => {
                          dataComodities?.push({
                            comodity: {
                              value: i.id,
                              label: i.nameItem,
                              id: i.id,
                              img: i.imageUrl,
                              sku: i.SKU,
                            },
                            totalPrice:
                              i.totalPrice ??
                              parseInt(i.price) * parseInt(i.amount),
                            quantity: i.amount,
                            unit: {
                              value: i.unit,
                              label: i.unit,
                            },
                            price: i.price,
                          })
                        })
                      })

                      localStorage.setItem(
                        'userData',
                        JSON.stringify({
                          dataComodities: dataComodities,
                          dataSeller: transaction?.sellerDetail,
                          talangin: balance?.talangin,
                          wallet1: balance?.uangBelanja,
                          wallet2: balance?.uangUsaha,
                          trxId: transaction?.id,
                        }),
                      )

                      dispatch(
                        setTotal(
                          dataComodities?.reduce(
                            (sum, a) => sum + a.totalPrice,
                            0,
                          ),
                        ),
                      )

                      navigate('/transaksi-pembelian-pembayaran')
                    }}
                  >
                    Lanjut Pembayaran
                  </CustomButton>
                </div>
              </CardBody>
            </Card>
          )}
          {/* END LANJUT PEMBAYARAN */}

          {/* KONFIRMASI PESANAN */}
          {transaction?.escrowpayment?.status === 'ON_GOING' &&
            (transaction?.orderDetail?.type === 'BUY' ||
              transactionType === 'BUY') &&
            !transaction?.orderDetail?.autoRelease &&
            !transaction?.orderDetail?.useProofOfPayment && (
              <Card
                className="shadow-sm text-center mb-3"
                style={{
                  backgroundColor: '#ECFAFF',
                  border: '1px solid #8ABBCC !important',
                }}
              >
                <CardBody>
                  <p>Pesanan Anda sedang diproses oleh penjual</p>
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={togglePayment}
                  >
                    <Check />
                    <span style={{ marginLeft: '4px' }}>
                      Konfirmasi Barang Sampai
                    </span>
                  </Button>
                </CardBody>
              </Card>
            )}
          {/* END KONFIRMASI PESANAN */}

          {/* CHANGE PAYMENT */}
          {transaction?.changeInstantPayment && (
            <Card
              className="shadow-sm text-center mb-3"
              style={{
                backgroundColor: '#ECFAFF',
                border: '1px solid #8ABBCC !important',
              }}
            >
              <CardBody>
                {/* <p>Pesanan Anda sedang diproses oleh penjual</p> */}
                <Button
                  color="primary"
                  className="w-100"
                  onClick={() => {
                    setIsChangeInstantPayment(true)
                    setIsModalPayment((prev) => !prev)
                  }}
                >
                  <img src={ZapIcon} alt="Zap Icon" />
                  <span style={{ marginLeft: '4px' }}>
                    Percepat Pengiriman Pembayaran
                  </span>
                </Button>
              </CardBody>
            </Card>
          )}
          {/* END CHANGE PAYMENT */}

          {/* INFORMASI TRANSAKSI */}
          <TransactionInformation
            transaction={transaction}
            transactionNoteData={transactionNoteData}
            isBuyer={isBuyer}
            isCreator={isCreator}
            ownerUserId={ownerUserId}
            sellerUserId={sellerUserId}
            buyerUserId={buyerUserId}
            toggleNoteModal={toggleNoteModal}
            setIsNoteModalOpenEdit={setIsNoteModalOpenEdit}
          />
          {/* END INFORMASI TRANSAKSI */}

          {/* ALUR TRANSAKSI */}
          <HistoryTransactionSection transaction={transaction} />
          {/* END ALUR TRANSAKSI */}
        </Col>
        {/* END LEFT COLUMN  */}

        {/* RIGHT COLUMN */}
        <Col md="7">
          {/* RINCIAN PESANAN */}
          <DetailOrderSection
            transaction={transaction}
            setIsOpenSidebar={setIsOpenSidebar}
          />
          {/* END RINCIAN PESANAN */}
        </Col>
        {/* END RIGHT COLUMN */}
      </Row>
    </div>
  )
}

export default DetailTransaction
