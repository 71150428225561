import IPhoto from 'assets/icons/Pak-Tani.png'
import PMBPagination from 'components/PMBPagination'
import PMBSearchInputGroup from 'components/PMBSearchInputGroup'
import { TableComponent as DataTable } from 'components/TableComponent'
import { useDebounce } from 'helpers'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import useGetTeamsAvatarQuery from 'hooks/useGetTeamsAvatarQuery'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Badge, CardBody } from 'reactstrap'
import { URL_406 } from 'utils/pathUrl'

const TeamSetting = ({ action, subject }) => {
  const ability = useAbilityAccess({ action, subject })
  const [search, setSearch] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortColumn, setSortColumn] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')

  const teams = useGetTeamsAvatarQuery({
    page: currentPage,
    size: rowsPerPage,
    sortby: sortColumn ?? 'name',
    sortdir: sortDirection ?? 'asc',
    search,
  })

  const debouncedSearch = useDebounce((value) => {
    setCurrentPage(0)
    setRowsPerPage(10)
    setSearch(value)
    // teams?.refetch()
  }, 600)

  const onHandleSearchChange = (value) => {
    setSearchInput(value ?? '')
    debouncedSearch(value ?? '')
  }

  const handleSort = (column, direction) => {
    setSortColumn(column.selector)
    setSortDirection(direction)
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ' && search.length === 0) {
      e.preventDefault()
    }
  }

  const columns = [
    {
      name: <span>Nama</span>,
      selector: 'name',
      sortable: true,
      cell: (row) => {
        return (
          <div className="flex-centered gap-2">
            <>
              <img
                src={row?.personAvatar ?? IPhoto}
                alt="pak tani"
                width={20}
                height={20}
                className="rounded-circle"
              />
              <span>{row?.name}</span>
            </>
          </div>
        )
      },
    },
    {
      name: <span>Email</span>,
      selector: 'email',
      sortable: true,
      cell: (row) => <span>{row?.email}</span>,
    },
    {
      name: <span>Peran</span>,
      selector: 'rolesName',
      sortable: true,
      cell: (row) => <span>{row?.rolesName}</span>,
    },
    {
      name: <span>Status</span>,
      cell: (row) => {
        return !row?.active ? (
          <Badge className="badge-danger">Nonaktif</Badge>
        ) : (
          <Badge className="badge-verify">Aktif</Badge>
        )
      },
    },
  ]

  if (!ability) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <div className="px-3">
      <h5 className="mx-3 mt-3">Tim Saya</h5>
      <p className="text-muted mx-3">
        Daftar anggota yang bergabung dengan tim
      </p>
      <hr className="mx-3 text-muted" />
      <div className="px-3">
        <PMBSearchInputGroup
          label={'Cari'}
          value={searchInput}
          placeholder="Cari nama tim..."
          onChange={(e) => onHandleSearchChange(e.target.value)}
          maxWidth="15%"
          onKeyDown={handleKeyDown}
        />
      </div>
      <CardBody>
        <DataTable
          columns={columns}
          data={teams?.data?.items}
          progressPending={teams?.isFetching ?? teams?.isLoading}
          paginationTotalRows={teams?.data?.totalItems ?? []}
          className="border p-0 border-1 rounded-top"
          pagination
          paginationServer
          responsive
          persistTableHead
          defaultSortFieldId={1}
          defaultSortAsc={true}
          sortServer
          onSort={handleSort}
          noDataComponent={
            <p className="my-3 fw-semibold">
              {searchInput !== '' &&
              !teams?.data?.items?.length &&
              !teams?.isFetching
                ? 'Tidak ada data yang sesuai dengan filter Anda.'
                : 'Belum ada daftar tim yang didaftarkan'}
            </p>
          }
          paginationComponent={(props) => {
            return (
              <PMBPagination
                rowCount={teams?.data?.totalItems || 0}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                setRowsPerPage={setRowsPerPage}
                props={props}
              />
            )
          }}
        />
      </CardBody>
    </div>
  )
}

export default TeamSetting
