import LoadingV2 from 'components/LoadingV2'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Calendar } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import { useDispatch } from 'react-redux'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { getChart3 } from '../API/ap2hiActions'

const MonthlyTab = ({ selectedMonthYear, handleChangeMonthYear }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [chartData, setChartData] = useState({
    series: [],
    labels: [],
    total: 0,
    othersTotal: 0,
    othersDetails: [],
    monthlySpecies: [],
  })

  const MAX_SEGMENTS = 5

  useEffect(() => {
    if (selectedMonthYear) {
      const month = new Date(selectedMonthYear).getMonth() + 1
      const year = new Date(selectedMonthYear).getFullYear()

      setLoading(true)

      dispatch(getChart3({ month, year }))
        .then((response) => {
          if (response.payload && response.payload.monthlySpecies) {
            const speciesData = response.payload.monthlySpecies

            // SUM TOTAL VALUE
            const totalValue = speciesData.reduce(
              (sum, item) => sum + item.total,
              0,
            )

            // SORT DATA FROM LARGEST TO SMALLEST
            const sortedData = speciesData.sort((a, b) => b.total - a.total)

            // GET TOP MAX_SEGMENTS, AND MERGE REMAINING TO "OTHERS"
            const topData = sortedData.slice(0, MAX_SEGMENTS)
            const otherData = sortedData.slice(MAX_SEGMENTS)

            const otherTotal = otherData.reduce(
              (sum, item) => sum + item.total,
              0,
            )

            // HANDLE OTHERS
            const finalData = [...topData]
            if (otherTotal > 0) {
              finalData.push({
                species: 'Others',
                total: otherTotal,
                details: otherData,
              })
            }

            setChartData({
              series: finalData.map((item) => item.total),
              labels: finalData.map((item) => item.species),
              total: totalValue,
              othersTotal: otherTotal,
              othersDetails: otherData,
              monthlySpecies: speciesData,
            })
          }
        })
        .catch((error) => {
          console.warn('Error fetching monthly data:', error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [dispatch, selectedMonthYear])

  const options = {
    chart: {
      type: 'donut',
    },
    labels: chartData?.labels || [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    legend: {
      position: 'bottom',
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        colors: ['#fff'],
        fontWeight: 'normal',
        textShadow: 'none',
      },
      formatter: (val, opts) => {
        const seriesIndex = opts.seriesIndex

        // GET SPECIES BASED ON INDEX
        const speciesName = chartData?.labels[seriesIndex]
        const speciesData = chartData?.monthlySpecies?.find(
          (species) => species.species === speciesName,
        )

        const totalAmount = speciesData ? speciesData.total : 0

        // SHOWING TOTAL IN OTHERS
        if (speciesName === 'Others') {
          return chartData?.othersTotal
            ? newFormatThousandSeparator(chartData.othersTotal)
            : '0'
        }

        return newFormatThousandSeparator(totalAmount)
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '16px',
              color: '#000',
              fontWeight: 'normal',
              offsetY: -10,
              textShadow: 'none',
              formatter: () => 'Total',
            },
            value: {
              show: true,
              fontSize: '20px',
              color: '#000',
              fontWeight: 'normal',
              offsetY: 10,
              formatter: () => {
                return chartData?.total
                  ? newFormatThousandSeparator(chartData.total)
                  : '0'
              },
              textShadow: 'none',
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '14px',
              fontWeight: 'normal',
              textShadow: 'none',
              formatter: () => {
                return chartData?.total
                  ? newFormatThousandSeparator(chartData.total)
                  : '0'
              },
            },
          },
        },
      },
    },

    colors: ['#6AB04A', '#2980B9', '#E74C3C', '#F1C40F', '#9B59B6', '#6FD195'],
    tooltip: {
      custom: function ({ seriesIndex, series, dataPointIndex, w }) {
        const color = options.colors[seriesIndex] || '#000'
        const label = chartData?.labels[seriesIndex] || 'Unknown'
        const totalValue =
          series && series[seriesIndex]
            ? newFormatThousandSeparator(series[seriesIndex])
            : '0'

        if (label === 'Others') {
          const othersDetails =
            chartData?.othersDetails && chartData.othersDetails.length > 0
              ? chartData.othersDetails
                  .map(
                    (item) =>
                      `<strong>${item.species}:</strong> ${newFormatThousandSeparator(item.total)}`,
                  )
                  .join('<br />')
              : 'No additional data available'

          return `
              <div style="background-color: ${color}; padding: 10px; border-radius: 5px; color: #fff; font-weight: normal;">
                <strong>Others:</strong><br />
                ${othersDetails}<br /><br />
                <strong>Total:</strong> ${chartData?.othersTotal ? newFormatThousandSeparator(chartData.othersTotal) : '0'}
              </div>
            `
        }

        return `
          <div style="background-color: ${color}; padding: 10px; border-radius: 5px; color: #fff; font-weight: normal;">
            <strong>${label}:</strong> ${totalValue}
          </div>
        `
      },
    },
  }

  return (
    <div>
      <div className="d-flex flex-column flex-sm-row align-items-start justify-content-md-between">
        <h5 className="mb-0">Summary Tangkapan Bulanan</h5>
        <div
          style={{ width: 300 }}
          className="d-flex flex-column flex-sm-row mt-2 mt-sm-0 mb-2"
        >
          <div style={{ minWidth: '300px' }}>
            <div className="d-flex align-items-center justify-content-center border rounded">
              <Calendar size={17} className="ms-3" />
              <Flatpickr
                value={selectedMonthYear}
                onChange={handleChangeMonthYear}
                options={{
                  altInput: true,
                  altFormat: 'F Y',
                  dateFormat: 'Y-m',
                  defaultDate: selectedMonthYear,
                  disableMobile: true,
                  plugins: [
                    typeof window !== 'undefined' &&
                    window.matchMedia('(min-width: 480px)').matches
                      ? new MonthSelectPlugin({
                          shorthand: false,
                          dateFormat: 'Y-m',
                          altFormat: 'F Y',
                        })
                      : undefined,
                  ].filter(Boolean),
                }}
                className="form-control bg-transparent border-0 shadow-none"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <LoadingV2 />
        ) : chartData?.labels?.length > 0 ? (
          <Chart
            options={options}
            series={chartData.series}
            type="donut"
            height={400}
          />
        ) : (
          <>Tidak ada data yang tesedia.</>
        )}
      </div>
    </div>
  )
}

export default MonthlyTab
