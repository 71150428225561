import { useLocation } from 'react-router-dom'
import ManageStock from './ManageStock'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export const DetailInventoriesPacking = () => {
  const location = useLocation()
  const dataState = location.state
  const { detail } = useSelector((s) => s.manageStock)

  const id = window.location.href.substring(
    window.location.href.lastIndexOf('/') + 1,
  )
  const [breadCrumbsData, setBreadCrumbsData] = useState([
    { id: id, label: detail?.label ?? dataState },
  ])

  return (
    <>
      <ManageStock
        isDetail={true}
        id={id}
        dataState={dataState}
        breadCrumbsData={breadCrumbsData}
        onManageBreadCrumb={(data) => onChangeBreadCrumb(data)}
      />
    </>
  )

  function onChangeBreadCrumb(data) {
    setBreadCrumbsData((prev) => {
      const index = prev.findIndex((crumb) => crumb.id === data.id)

      if (index !== -1) {
        return prev.slice(0, index + 1)
      }
      return [...prev, { id: data.id, label: data.label }]
    })
  }
}
