import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'

const Collapsible = ({ isOpen, children }) => {
  const contentRef = useRef(null)
  const [maxHeight, setMaxHeight] = useState('0px')

  useEffect(() => {
    if (isOpen) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`)
    } else {
      setMaxHeight('0px')
    }
  }, [isOpen])

  return (
    <div
      className="collapsible-container"
      style={{
        maxHeight,
        transition: 'max-height 0.3s ease-out',
        overflow: 'hidden',
      }}
      ref={contentRef}
    >
      {children}
    </div>
  )
}

Collapsible.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default Collapsible
