import { Card, CardBody } from 'reactstrap'
import { formatDatePMBV2 } from 'utils/formatDate'

const HistoryTransactionSection = ({ transaction }) => {
  const getTimelineItem = (description, date) => (
    <div className="d-flex timeline-item">
      <div className="timeline-icon">
        <span className="circle-icon-wrap">
          <span className="circle-icon"></span>
        </span>
      </div>
      <div className="timeline-content">
        <div className="text-sm flex align-items-center gap-2">
          <span>{description}</span>
        </div>
        <small style={{ color: '#667085' }}>{formatDatePMBV2(date)}</small>
      </div>
    </div>
  )

  return (
    <Card className="shadow-sm border-0 mb-3">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="fw-semibold mb-3">Alur Transaksi</h6>
        </div>
        <div style={{ maxHeight: '250px', overflowY: 'auto' }}>
          {transaction?.history?.createdDate || transaction?.createdAt
            ? getTimelineItem(
                'Pesanan dibuat oleh pembeli',
                transaction?.history?.createdDate ?? transaction?.createdAt,
              )
            : null}
          {transaction?.history?.sellerConfirmDate
            ? getTimelineItem(
                'Pesanan diproses oleh penjual',
                transaction?.history?.sellerConfirmDate,
              )
            : null}
          {transaction?.history?.releaseDate && (
            <>
              {getTimelineItem(
                'Pembeli konfirmasi barang sampai',
                transaction?.history?.releaseDate,
              )}
              {getTimelineItem(
                'Transaksi selesai',
                transaction?.history?.updatedAt,
              )}
            </>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

export default HistoryTransactionSection
