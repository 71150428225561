import AmountInput from 'components/AmountInput'
import CustomButton from 'components/Buttons/CustomButton'
import { ChevronDown } from 'react-feather'
import { Card, CardText, Input } from 'reactstrap'
import UploadImageCard from '../Card/UploadImageCard'
import InventoryCodeInput from '../Input/InventoryCodeInput'

export default function EditStockModal({
  selectedStock,
  onChangeSelectedDataStock,
  onShowItem,
  onClickUpdate,
  loading,
  onChangeAmount,
  onChangeUnitValue,
  displayAmount,
  displayUnitValue,
  selectedEditAction,
}) {
  const handleInventoryCodeChange = (code) => {
    onChangeSelectedDataStock('ownerCode', code)
  }

  return (
    <>
      <div className="col-space-between">
        <Card className="small-padding mt-3 mb-3 border-0 shadow-sm">
          <div>
            <div>
              <CardText className="no-padding">
                Kode inventori (opsional)
              </CardText>
              <InventoryCodeInput
                value={selectedStock?.ownerCode}
                onChange={handleInventoryCodeChange}
                isAutoFocus
              />
            </div>
            <div className="mt-4">
              <CardText className="no-padding ">
                Komoditas
                <span style={{ color: '#b42218' }}>*</span>
              </CardText>
              <div
                className={`bordered mt-2 d-flex justify-content-between align-items-center`}
                style={{ cursor: 'none' }}
              >
                <Input
                  disabled={selectedEditAction !== 'general'}
                  style={{
                    borderColor: 'white',
                    caretColor: 'transparent',
                  }}
                  className="border-input button"
                  value={selectedStock?.nameItem}
                  onClick={() => {
                    onShowItem('item', true)
                  }}
                />
              </div>
            </div>
            <div className="mt-4">
              <UploadImageCard
                onChangeDataStock={onChangeSelectedDataStock}
                uploadedImage={selectedStock?.linkFoto}
              />
            </div>
            <div className="d-flex justify-content-between">
              <div className="mt-4" style={{ width: '49%' }}>
                <CardText className="no-padding ">
                  Jumlah<span style={{ color: '#b42218' }}>*</span>
                </CardText>
                <div
                  className={`bordered mt-2 d-flex justify-content-between align-items-center`}
                >
                  <AmountInput
                    value={displayAmount ?? selectedStock?.amount}
                    name="quantity"
                    cb={(e) =>
                      onChangeAmount(
                        'amount',
                        e,
                        onChangeSelectedDataStock,
                        selectedStock,
                      )
                    }
                    placeholder={selectedStock?.amount}
                  />
                </div>
              </div>
              <div className="mt-4" style={{ width: '49%' }}>
                <CardText className="no-padding ">
                  Satuan<span style={{ color: '#b42218' }}>*</span>
                </CardText>
                <div
                  className={`bordered mt-2 d-flex justify-content-between align-items-center`}
                >
                  <Input
                    style={{
                      borderColor: 'white',
                      caretColor: 'transparent',
                    }}
                    className="border-input button"
                    value={selectedStock?.unit || selectedStock?.item?.unit}
                    onClick={() => {
                      onShowItem('itemUnit', true)
                    }}
                  />
                  <ChevronDown color="#667085" style={{ paddingRight: 10 }} />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="mt-4 w-100">
                <CardText className="no-padding ">Harga satuan</CardText>
                <div
                  className={`bordered mt-2 d-flex justify-content-between align-items-center`}
                >
                  <AmountInput
                    value={
                      displayUnitValue ?? selectedStock?.userValue?.unitValue
                    }
                    name="unitValue"
                    cb={(e) =>
                      onChangeUnitValue(
                        'unitValue',
                        e,
                        onChangeSelectedDataStock,
                        selectedStock,
                      )
                    }
                    placeholder={selectedStock?.userValue?.unitValue}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <CardText className="no-padding ">Catatan</CardText>
              <div className="d-flex align-items-center">
                <div className="bordered mt-2" style={{ width: '100%' }}>
                  <Input
                    style={{ borderColor: 'white', height: 200 }}
                    className="border-input"
                    type="textarea"
                    value={selectedStock?.description}
                    onChange={(text) =>
                      onChangeSelectedDataStock(
                        'description',
                        text?.target?.value,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div>
          <CustomButton
            disabled={loading || !selectedStock}
            onClick={onClickUpdate}
            style={{
              width: '100%',
              backgroundColor: '#006386',
              fontSize: 14,
              padding: 10,
            }}
            className=""
          >
            Simpan
          </CustomButton>
        </div>
      </div>
    </>
  )
}
