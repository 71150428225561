import { createApiSlice } from 'app/createApiSlice'
import { API_TUTORIAL_REPORT } from 'utils/apiList'

export const learningReportApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'learningReport/api',
  endpoints(builder) {
    return {
      fetchReport: builder.query({
        query({ page, size = 10, sortby, sortdir, s }) {
          return {
            url: `/${API_TUTORIAL_REPORT}`,
            params: {
              page,
              size,
              sortby,
              sortdir,
              s: s ?? '',
            },
          }
        },
      }),
    }
  },
})

export const { useFetchReportQuery } = learningReportApiSlice
