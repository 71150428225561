import { useEffect, useState } from 'react'
import { ArrowLeft, Delete, Eye } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Container, FormGroup, Input } from 'reactstrap'
import {
  useSendOtpLoginMutation,
  useVerifyOtpLoginMutation,
} from './loginNewApiSlice'
import { setIsV3 } from 'features/private-views/dashboard/dashboardSlice'

const Otp = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { token } = useSelector((s) => s.auth)
  const dataState = location.state
  const [pin, setPin] = useState(['', '', '', '', ''])
  const [active, setActive] = useState('form-control-0')
  const [isAction, setIsAction] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [countdown, setCountdown] = useState(dataState?.expireTime ?? 60)
  const [verifyOtp, resultVerifyOtp] = useVerifyOtpLoginMutation()
  const [sendOtp, resultSendOtp] = useSendOtpLoginMutation()

  const handleSubmit = async () => {
    try {
      setErrMsg('')
      setIsAction(true)
      const res = await verifyOtp({
        identity: dataState?.phoneNumber,
        deviceHash: dataState?.deviceHash,
        type: 'login',
        otp: pin.join(''),
      })
      if (!res?.error) {
        navigate('/pin', { state: { ...dataState } })
      } else {
        throw res
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.error?.data?.meta?.message ?? error?.message)
    } finally {
      setIsAction(false)
    }
  }

  const handleResend = async () => {
    try {
      const res = await sendOtp({
        identity: dataState?.phoneNumber,
        deviceHash: dataState?.deviceHash,
        type: 'login',
        channel: 'WHATSAPP',
      })
      if (!res?.data?.meta?.error) {
        setCountdown(res?.data?.data?.expireTime)
      }
    } catch (error) {
      setErrMsg(error?.error?.data?.meta?.message)
      console.log(error)
    }
  }

  const nextInput = (id) => {
    const input = document.getElementById(id)
    if (input) input.focus()
  }

  const handleKeypadClick = (number) => {
    setErrMsg('')
    const nextIndex = pin.findIndex((p) => p === '')

    if (nextIndex !== -1) {
      const newPin = [...pin]
      newPin[nextIndex] = number
      setPin(newPin)

      if (nextIndex < 4) {
        setActive(`form-control-${nextIndex + 1}`)
        nextInput(`form-control-${nextIndex + 1}`)
      }
    }
  }

  const handleDelete = () => {
    setErrMsg('')
    const lastIndex = pin.findIndex((p) => p === '')
    const indexToDelete = lastIndex === -1 ? 4 : lastIndex - 1

    if (indexToDelete >= 0) {
      const newPin = [...pin]
      newPin[indexToDelete] = ''
      setPin(newPin)
      setActive(`form-control-${indexToDelete}`)
      nextInput(`form-control-${indexToDelete}`)
    }
  }

  const formatCountdown = (count) => {
    const minutes = Math.floor(count / 60)
    const seconds = count % 60
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(interval)
          return 0
        }
        return prevCountdown - 1
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (pin.length === 5 && pin.every((pin) => pin !== '' && !isAction)) {
      handleSubmit()
    }
  }, [pin])

  useEffect(() => {
    setCountdown(Number(dataState?.countDown))
  }, [dataState])

  useEffect(() => {
    if (token) {
      dispatch(setIsV3(true))
      navigate('/')
    }
  }, [token])

  return (
    <Container
      style={{ minHeight: '100vh' }}
      className="d-flex flex-column justify-content-between py-3"
    >
      {/* Header Section */}
      <div>
        <div
          onClick={() => {
            navigate(-1)
          }}
          className="d-flex justify-content-start"
        >
          <ArrowLeft size={20} />
        </div>
        <h5 className="fw-bold mt-3">Konfirmasi nomor telepon</h5>
        <span>
          Masukkan kode yang dikirim melalui Whatsapp ke{' '}
          {dataState?.phoneNumber}.
        </span>
      </div>

      {/* PIN */}
      <div className="d-flex align-items-center justify-content-center flex-column gap-4">
        <div className="text-danger text-center">{errMsg}</div>
        <FormGroup className="d-flex gap-4">
          {[...Array(5)].map((_, i) => (
            <div key={i}>
              <div
                className="position-absolute top-0 start-0 w-0 h-0"
                style={{ zIndex: 2, backgroundColor: 'transparent' }}
                onClick={() => nextInput(active)}
              ></div>
              <Input
                readOnly
                style={{
                  zIndex: 1,
                }}
                autoFocus={i === 0}
                className="w-100 h-100 fs-2 border border-dark border-top-0 border-start-0 border-end-0 text-center text-primary rounded-0"
                id={`form-control-${i}`}
                key={i}
                maxLength={1}
                inputMode="numeric"
                pattern="[0-9]*"
                onFocus={(e) => {
                  e.target.style.boxShadow = 'none'
                }}
                value={pin[i]}
                autoComplete="off"
                onKeyDown={(e) => {
                  if (!e.code.includes('Digit') && e.code !== 'Backspace') {
                    e.preventDefault()
                  } else if (e.code === 'Backspace') {
                    if (i > 0 || pin[i] !== '') {
                      setPin((prevPin) => {
                        const newPin = [...prevPin]
                        newPin[i] = ''
                        return newPin
                      })
                      setActive(`form-control-${i - 1}`)
                      nextInput(`form-control-${i - 1}`)
                    }
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value

                  if (value.match(/^[0-9]$/)) {
                    setPin((prev) => {
                      const newPin = [...prev]
                      newPin[i] = value
                      return newPin
                    })

                    if (e.target.id !== 'form-control-4') {
                      setActive(`form-control-${i + 1}`)
                      nextInput(`form-control-${i + 1}`)
                    }
                  }
                }}
              />
            </div>
          ))}
        </FormGroup>

        {/* Show/Hide PIN */}
        <div
          onClick={() => {
            if (countdown === 0) {
              handleResend()
            }
          }}
        >
          <span className={countdown > 0 ? 'text-muted' : ''}>
            Kirim ulang kode ({formatCountdown(countdown)})
          </span>
        </div>
      </div>

      {/* Keypad */}
      <div className="d-flex flex-column mt-4">
        <ButtonGroup className="d-flex justify-content-center">
          <Button
            block
            outline
            color="primary"
            className="border border-top-0 border-start-0 rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(1)}
          >
            1
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-top-0 rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(2)}
          >
            2
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border border-end-0 border-top-0 rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(3)}
          >
            3
          </Button>
        </ButtonGroup>
        <ButtonGroup className="d-flex justify-content-center">
          <Button
            block
            outline
            color="primary"
            className="border border-top-0 border-start-0 rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(4)}
          >
            4
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(5)}
          >
            5
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border border-end-0 rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(6)}
          >
            6
          </Button>
        </ButtonGroup>
        <ButtonGroup className="d-flex justify-content-center">
          <Button
            block
            outline
            color="primary"
            className="border border-top-0 border-start-0 rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(7)}
          >
            7
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(8)}
          >
            8
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border border-end-0 rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(9)}
          >
            9
          </Button>
        </ButtonGroup>
        <ButtonGroup className="d-flex justify-content-center">
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border-top-0 border-start-0 rounded-0 p-3"
            size="lg"
          ></Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 rounded-0 p-3"
            size="lg"
            onClick={() => handleKeypadClick(0)}
          >
            0
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border border-end-0 rounded-0 p-3"
            size="lg"
            onClick={handleDelete}
          >
            <Delete size={20} />
          </Button>
        </ButtonGroup>
      </div>
    </Container>
  )
}

export default Otp
