import ImageUserNotFound from 'assets/icons/Pak-Tani.png'
import { useEffect, useRef, useState } from 'react'
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from 'reactstrap'

import CustomButton from 'components/Buttons/CustomButton'
import PMBSelect from 'components/PMBSelect'
import PMBTooltip from 'components/PMBTooltip'
import ModalSavedContacts from 'features/private-views/payable-notes/ModalSavedContacts'
import { useLazyFindAgentsQuery } from 'features/private-views/payable-notes/payableNotesApiSlice'
import { useSnackbar } from 'notistack'
import { Bookmark, Check, Info } from 'react-feather'
import { useSelector } from 'react-redux'
import { useFetchBanksQuery } from '../forms/formApiSlice'

const ModalDisbursement = ({
  isOpen,
  toggle,
  handleAddDisbursement,
  noHpFarmer,
  isDetailFarmer,
  isLoading,
  dataFormEdit,
  methodName,
  isBankExist,
  isEdit,
  agentSelected,
  isSwitchAgent,
  isAgentExist,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { profile } = useSelector((s) => s.dashboard)
  const [findAgent, res] = useLazyFindAgentsQuery()

  const { data: banks } = useFetchBanksQuery()

  const banksOptions = banks?.map((item) => ({
    value: item?.code,
    label: item?.label,
    image: item?.logo,
  }))

  const [selectedMethod, setSelectedMethod] = useState(
    !isDetailFarmer ? 'bank' : 'agent',
  )
  const [accountNumber, setAccountNumber] = useState('')
  const [accountName, setAccountName] = useState('')
  const [agentInfo, setAgentInfo] = useState(null)
  const [agentPhone, setAgentPhone] = useState('')
  const [bankSelected, setBankSelected] = useState(null)
  const [isAddressBook, setIsAddressBook] = useState(false)
  const [primaryAgent, setPrimaryAgent] = useState(false)

  const accountNumberRef = useRef(null)
  const agentPhoneRef = useRef(null)

  const resetState = () => {
    setSelectedMethod(!isDetailFarmer ? 'bank' : 'agent')
    setAccountName('')
    setAccountNumber('')
    setBankSelected(null)
    setAgentPhone('')
    setAgentInfo(null)
    setPrimaryAgent(false)
  }

  const handleCheckAgent = async () => {
    if (isDetailFarmer && noHpFarmer === agentPhone) {
      enqueueSnackbar(
        'Email atau Nomor handphone agen tidak boleh sama dengan nomor farmer!',
        {
          variant: 'error',
        },
      )
      return
    }

    if (agentPhone) {
      const res = await findAgent({
        s: agentPhone,
      })
      setAgentInfo(res?.data?.[0] || null)
      if (res?.data?.length === 0) {
        enqueueSnackbar('Maaf, Agen tidak ditemukan!', {
          variant: 'error',
        })
      }
    } else {
      setAgentInfo(null)
      enqueueSnackbar('Email atau Nomor handphone agen harap diisi!', {
        variant: 'error',
      })
    }
  }

  const handleSubmit = () => {
    const data = {
      method: selectedMethod,
      agentPhone: selectedMethod === 'agent' ? agentPhone : null,
      agentInfo: selectedMethod === 'agent' ? agentInfo : null,
      bankDetails:
        selectedMethod === 'bank'
          ? { accountNumber, bankSelected, accountName }
          : null,
      isDefault: primaryAgent ? 1 : 0,
    }
    handleAddDisbursement(data)

    resetState()
  }

  const getRadioStyle = (method) => {
    const isActive = selectedMethod === method
    return {
      backgroundColor: isActive ? '#ECFAFF' : '#FFFFFF',
      border: isActive ? '1px solid #8ABBCC' : '1px solid #EAECF0',
      padding: 16,
      borderRadius: 8,
      cursor: 'pointer',
    }
  }

  const handleSelectedContact = (selectedAgen) => {
    if (selectedAgen?.relationUserId) {
      setAgentPhone(selectedAgen?.relationPhoneNumber ?? '')
      setAgentInfo(selectedAgen)
    }
  }

  const isDisabledButton = () => {
    if (isLoading) return false
    if (selectedMethod === 'bank') {
      return accountNumber !== '' && bankSelected && accountName !== ''
    } else if (selectedMethod === 'agent') {
      return !!agentInfo
    }
    return false
  }

  // HANDLE EDIT
  useEffect(() => {
    // BANK
    if (isEdit && isOpen && dataFormEdit && methodName === 'bank') {
      setAccountName(dataFormEdit?.bankAccountName ?? '')
      setAccountNumber(dataFormEdit?.bankAccountNumber ?? '')
      setBankSelected(
        banksOptions?.find((item) => item?.value === dataFormEdit?.bankCode),
      )
    }
    // AGENT
    if (isEdit && isOpen && agentSelected && methodName === 'agent') {
      setAgentPhone(
        agentSelected?.agentverifiedprofile?.phoneNumber ?? noHpFarmer ?? '',
      )
      setAgentInfo(agentSelected)
      setPrimaryAgent(isSwitchAgent)
    }
    // SET METHOD
    if (methodName) {
      setSelectedMethod(methodName)
    }
  }, [isOpen, dataFormEdit, methodName, isEdit])

  // HANDLE AUTO FOCUS
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (selectedMethod === 'bank') {
          accountNumberRef.current?.focus()
        } else if (selectedMethod === 'agent') {
          agentPhoneRef.current?.focus()
        }
      }, 0)
    }
  }, [selectedMethod, isOpen])

  return (
    <>
      {isAddressBook ? (
        <ModalSavedContacts
          isReceivable={true}
          isOpen={isAddressBook}
          toggle={() => setIsAddressBook(!isAddressBook)}
          cb={handleSelectedContact}
          isFarmers={true}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          toggle={() => {
            toggle()
            resetState()
          }}
          centered
        >
          <ModalHeader
            toggle={() => {
              toggle()
              resetState()
            }}
          >
            {isEdit
              ? `Ubah ${methodName === 'agent' ? 'agen' : 'bank'}`
              : 'Tambah metode'}
          </ModalHeader>
          <ModalBody>
            <Form>
              {/* CHOOSE METHOD */}
              <FormGroup>
                {/* BANK */}
                {!isDetailFarmer &&
                  (!isBankExist || isEdit) &&
                  (!isEdit || methodName !== 'agent') && (
                    <div
                      className="mb-3"
                      style={getRadioStyle('bank')}
                      onClick={() => {
                        resetState()
                        setSelectedMethod('bank')
                      }}
                    >
                      <Input
                        type="radio"
                        name="paymentMethod"
                        value="bank"
                        checked={selectedMethod === 'bank'}
                        onChange={() => {
                          resetState()
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                      <Label
                        className="ms-2 fw-semibold"
                        style={{ cursor: 'pointer' }}
                      >
                        Transfer bank
                      </Label>
                      <p
                        className="text-muted text-sm ms-4 mb-0"
                        style={{ color: '#475467' }}
                      >
                        Pembayaran akan masuk ke rekening bank anggota
                      </p>
                    </div>
                  )}
                {/* END BANK */}

                {/* AGENT */}
                {/* {(isDetailFarmer ||
                  (!isAgentExist && !isEdit) ||
                  (isEdit && methodName !== 'bank')) && (
                  <div
                    style={getRadioStyle('agent')}
                    onClick={() => {
                      resetState()
                      setSelectedMethod('agent')
                    }}
                  >
                    <Input
                      type="radio"
                      name="paymentMethod"
                      value="agent"
                      checked={selectedMethod === 'agent'}
                      onChange={() => {
                        resetState()
                        setSelectedMethod('agent')
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                    <Label
                      className="ms-2 fw-semibold"
                      style={{ cursor: 'pointer' }}
                    >
                      Pembayaran lewat agen
                    </Label>
                    <p
                      className="text-muted text-sm ms-4 mb-0"
                      style={{ color: '#475467' }}
                    >
                      Pembayaran akan ditransfer ke agen, dan diteruskan secara
                      tunai ke anggota
                    </p>
                  </div>
                )} */}
                {/* END AGENT */}
              </FormGroup>
              {/* END CHOOSE METHOD */}

              <Label
                className="fw-semibold mb-3"
                style={{ color: '#344054', fontSize: '20px' }}
              >
                Informasi {selectedMethod === 'bank' ? 'rekening' : 'agen'}
              </Label>

              {/* FORM TRANSFER BANK */}
              {selectedMethod === 'bank' && !isDetailFarmer && (
                <>
                  <FormGroup>
                    <Label for="accountNumber">Nomor rekening</Label>
                    <Input
                      type="text"
                      id="accountNumber"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                      placeholder="Masukkan nomor rekening"
                      innerRef={accountNumberRef}
                    />
                  </FormGroup>
                  {/* SELECT BANK */}
                  <FormGroup>
                    <label className="mb-1">Bank</label>
                    <PMBSelect
                      value={bankSelected}
                      onChange={(selected) => setBankSelected(selected)}
                      options={banksOptions}
                      placeholder="Pilih bank"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="accountName">Nama pemilik rekening</Label>
                    <Input
                      type="text"
                      id="accountName"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                      placeholder="Masukkan nama pemilik rekening"
                    />
                  </FormGroup>
                </>
              )}
              {/* END FORM TRANSFER BANK */}

              {/* FORM AGENT */}
              {selectedMethod === 'agent' && (
                <>
                  <FormGroup>
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <Label>
                        Agen{' '}
                        <Info
                          size="18"
                          style={{ color: '#667085' }}
                          id="info-agen"
                        />
                      </Label>
                      <PMBTooltip
                        placement="right"
                        target="info-agen"
                        text="Agen yang disimpan akan otomatis disimpan ke dalam daftar tersimpan"
                      />

                      <CustomButton
                        onClick={() => setIsAddressBook(!isAddressBook)}
                        outline
                        color="primary"
                        size="sm"
                      >
                        <Bookmark size={20} /> Pilih dari daftar tersimpan
                      </CustomButton>
                    </div>
                    <InputGroup>
                      <Input
                        type="text"
                        placeholder="Masukkan email atau nomor handphone agen"
                        value={agentPhone}
                        onChange={(e) => setAgentPhone(e.target.value)}
                        name="phoneNumber"
                        id="phoneNumber"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            if (agentPhone.trim() !== '') {
                              handleCheckAgent()
                            }
                          }
                        }}
                        innerRef={agentPhoneRef}
                      />
                      <InputGroupText
                        onClick={agentPhone !== '' && handleCheckAgent}
                        style={{
                          cursor: agentPhone !== '' && 'pointer',
                          opacity: agentPhone === '' && 0.4,
                          pointerEvents: agentPhone === '' && 'none',
                        }}
                      >
                        {res?.isFetching ? <Spinner size="sm" /> : 'Cek'}
                      </InputGroupText>
                    </InputGroup>
                  </FormGroup>

                  {agentInfo && (
                    <>
                      <div
                        style={{
                          border: '1px solid #039855',
                          backgroundColor: '#F6FEF9',
                          borderRadius: 8,
                          padding: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          marginBottom: '10px',
                        }}
                      >
                        <img
                          src={
                            agentInfo?.partnership?.logo || ImageUserNotFound
                          }
                          alt={
                            agentInfo?.partnership?.name ??
                            agentInfo?.agentverifiedprofile?.name ??
                            '-'
                          }
                          style={{ width: 30, height: 30, borderRadius: '50%' }}
                          loading="lazy"
                        />
                        <span>
                          {agentInfo?.partnership?.name ??
                            agentInfo?.agentverifiedprofile?.name ??
                            '-'}
                        </span>
                        <span style={{ marginLeft: 'auto', color: '#039855' }}>
                          <Check />
                        </span>
                      </div>
                    </>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '10px',
                      marginLeft: 'auto',
                    }}
                  >
                    <Input
                      disabled={!agentInfo}
                      checked={primaryAgent}
                      defaultChecked={false}
                      type="checkbox"
                      onChange={(e) => setPrimaryAgent(e.target.checked)}
                      style={{
                        cursor: agentInfo && 'pointer',
                        border: !agentInfo
                          ? '1px solid grey'
                          : '1px solid #016286',
                        backgroundColor: !agentInfo && '#e5e5e5',
                      }}
                    />
                    <Label
                      style={{
                        marginBottom: 0,
                        color: !agentInfo && '#C1BFBF',
                      }}
                    >
                      Jadikan Agen sebagai utama
                    </Label>
                  </div>
                </>
              )}
              {/* END FORM AGENT */}
            </Form>
          </ModalBody>

          <div className="d-flex gap-3 m-3">
            <Button
              className="button-cancel"
              onClick={() => {
                toggle()
                resetState()
              }}
              outline
              block
            >
              Batal
            </Button>
            <Button
              color="primary"
              disabled={!isDisabledButton()}
              onClick={handleSubmit}
              block
            >
              {isLoading ? <Spinner size="sm" /> : isEdit ? 'Simpan' : 'Tambah'}
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ModalDisbursement
