import warningRed from 'assets/icons/warning-red.svg'
import CustomButton from 'components/Buttons/CustomButton'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch } from 'react-redux'
import { CardText, CardTitle, Modal } from 'reactstrap'
import { removePacking } from '../../API/manageStockAction'

export default function ModalRemovePacking({
  isOpen,
  onClose,
  data,
  setIsFetch,
  setSelectedGroupInventory,
  toggleClearRowsGroup,
  isDetail,
}) {
  const dispatch = useDispatch()
  const [errMsg, setErrMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const obj = {
        groupIds: data?.id ? [data?.id] : data?.map((e) => e?.id),
      }
      const res = await dispatch(removePacking(obj))
      if (res?.error) {
        throw res
      } else {
        setIsFetch('inventory')
        onClose()
        setSelectedGroupInventory([])
        toggleClearRowsGroup()
        enqueueSnackbar(
          `Semua inventori di label ${data?.map((e) => {
            return `${e?.label},`
          })} berhasil dikeluarkan.`,
          {
            variant: 'success',
          },
        )
      }
    } catch (error) {
      setErrMsg(error?.message ?? error?.payload)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setErrMsg('')
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <div className="regular-padding-horizontal small-scrollable-container small-padding-vertical">
        <img
          alt="Warning"
          src={warningRed}
          style={{ width: 50, height: 50, marginTop: 10, marginBottom: 10 }}
          className="button small-margin-right"
        />
        <CardTitle className="fw-semibold">
          Hapus {data?.length > 0 ? `${data?.length} grup` : data?.label}?{' '}
        </CardTitle>
        <CardText className="mt-2" style={{ color: '#475467' }}>
          Grup akan dihapus dan semua inventori di dalamnya akan dikeluarkan.
        </CardText>
        {errMsg && (
          <div className="mb-2">
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </div>
        )}
      </div>
      <div className="regular-padding-horizontal regular-padding-bottom d-flex justify-content-between mt-2">
        <CustomButton
          onClick={onClose}
          outline
          disabled={loading}
          className="fw-semibold btn-cancel"
          style={{ width: '49%', borderColor: '#D0D5DD', color: '#344054' }}
        >
          Batal
        </CustomButton>
        <CustomButton
          onClick={handleSubmit}
          disabled={loading}
          className="fw-semibold"
          style={{
            width: '49%',
            backgroundColor: '#D92D20',
            borderColor: '#F97066',
          }}
        >
          Hapus
        </CustomButton>
      </div>
    </Modal>
  )
}
