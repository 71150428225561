import CustomButton from 'components/Buttons/CustomButton'
import { Plus } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap'
import { addItem } from '../sellCommoditySlice'
import Item2 from './index2'

const ItemInfo = () => {
  const dispatch = useDispatch()
  const { addItemCount } = useSelector((state) => state.sellCommodity)

  return (
    <Card className="border-0 shadow-sm">
      <CardBody className='p-4'>
        <Row>
          <Col sm="12">
            <CardTitle tag="h5">Barang yang dijual</CardTitle>
            {addItemCount?.map((num, idx) => (
              <Item2 key={idx} fieldNum={num} idx={idx} />
            ))}

            <CustomButton
              size="sm"
              style={{ maxWidth: 186 }}
              outline
              color="primary"
              onClick={() => {
                dispatch(addItem())
              }}
            >
              <Plus size={16} /> Tambah Komoditas
            </CustomButton>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ItemInfo
