import CustomButton from 'components/Buttons/CustomButton'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { createOrder } from '../sellCommodityAction'
import { toggleModal } from '../sellCommoditySlice'
import { AlertCircle } from 'react-feather'

function ModalTrx() {
  const { isOpenModal, orders, invPacking } = useSelector(
    (s) => s.sellCommodity,
  )
  const { profile } = useSelector((s) => s.dashboard)
  const dispatch = useDispatch()
  const [errMsg, setErrMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const toggle = useCallback(() => {
    dispatch(toggleModal())
  }, [dispatch])

  const navigate = useNavigate()
  useEffect(() => {
    if (orders.isSuccess) {
      toggle()
      navigate('/sell-commodity-success')
    }
  }, [orders, navigate, toggle])

  const payloadOrderModified = (orders, invPacking) => {
    const dataCommodity = orders?.item?.filter((ord) => !ord.isPacking)
    const newData = dataCommodity?.map((e, i) => {
      return {
        ...e,
        orderItemId: i + 1,
        price: Number(
          e?.price?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
        ),
        amount: Number(
          e?.amount
            ?.replace?.(/\./g, '')
            ?.replace?.(/\,/g, '.')
            ?.slice?.(0, 15),
        ),
      }
    })

    const newInvData = invPacking?.map((e, i) => {
      return {
        orderItemId: newData?.length + (i + 1),
        ...e,
      }
    })

    return {
      ...orders,
      item: [...newData, ...newInvData],
    }
  }

  const handleSavedContacts = (e) => {
    const saved =
      JSON.parse(localStorage.getItem(`savedContacts-${profile?.userId}`)) ?? []
    let newListComodities = [...saved]

    const updatedOptions = newListComodities.filter(
      (item) => item.userId !== e.userId,
    )

    updatedOptions.unshift(e)

    if (updatedOptions.length > 5) {
      const removedItem = updatedOptions.pop()

      newListComodities = {
        removedItem,
      }
    }

    localStorage.setItem(
      `savedContacts-${profile?.userId}`,
      JSON.stringify(updatedOptions),
    )
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      let payloadOrder = { ...orders }
      delete payloadOrder.step
      delete payloadOrder.isLoading
      const validatedOrders = {
        ...orders,
        item: orders.item.map((orderItem) => ({
          ...orderItem,
          totalPrice: orderItem.totalPrice === '' ? 0 : orderItem.totalPrice,
        })),
      }

      const res = await dispatch(
        createOrder(payloadOrderModified(validatedOrders, invPacking)),
      )
      if (res?.error) {
        throw res
      } else {
        handleSavedContacts(orders?.buyer)
      }
    } catch (error) {
      setErrMsg(error?.payload ?? error?.message ?? error?.error?.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!isOpenModal) {
      setErrMsg('')
    }
  }, [isOpenModal])

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      centered
      style={{ maxWidth: '400px' }}
    >
      <ModalBody>
        <div className="text-left">
          <h5 className="fw-bold text-md">Buat Transaksi</h5>
          <p className="text-sm">
            Pastikan data yang kamu masukkan sudah benar.
          </p>
        </div>

        {errMsg ? (
          <div className="my-3">
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </div>
        ) : (
          <></>
        )}

        <div className="border-0 flex-centered flex-row gap-2 w-100">
          <CustomButton
            color="secondary"
            onClick={() => {
              toggle()
            }}
            outline
            className="w-50"
          >
            <span className="text-normal">Batal</span>
          </CustomButton>
          <CustomButton
            disabled={loading}
            color="primary"
            onClick={() => {
              handleSubmit()
            }}
            className="w-50"
          >
            <span className="text-normal">Lanjutkan</span>
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalTrx
