import { setIsV3 } from 'features/private-views/dashboard/dashboardSlice'
import { useEffect, useState } from 'react'
import { ArrowLeft, Delete, Eye } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, Container, FormGroup, Input } from 'reactstrap'
import { version } from 'utility/Utils'
import { useLoginUserNewMutation } from './loginNewApiSlice'
import { login, setToken } from '../login/loginSlice'

const Pin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { token } = useSelector((s) => s.auth)
  const dataState = location.state
  const [pin, setPin] = useState(['', '', '', '', '', ''])
  const [active, setActive] = useState('form-control-0')
  const [isAction, setIsAction] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const toggleShowPw = () => setIsShow(!isShow)
  const [loginUser, resultLogin] = useLoginUserNewMutation()

  const handleSubmit = async () => {
    try {
      setErrMsg('')
      setIsAction(true)
      const res = await loginUser({
        identity: dataState?.phoneNumber,
        deviceHash: dataState?.deviceHash,
        password: pin.join(''),
        fcmToken: dataState?.deviceHash,
        version: version,
      })
      if (!res?.error) {
        dispatch(
          login({
            ...res?.data,
          }),
        )
        dispatch(setIsV3(true))
        navigate('/', { replace: true })
      } else {
        throw res
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.error?.data?.meta?.message)
    } finally {
      setIsAction(false)
    }
  }

  const nextInput = (id) => {
    const input = document.getElementById(id)
    if (input) input.focus()
  }

  const handleKeypadClick = (number) => {
    setErrMsg('')
    const nextIndex = pin.findIndex((p) => p === '')

    if (nextIndex !== -1) {
      const newPin = [...pin]
      newPin[nextIndex] = number
      setPin(newPin)

      if (nextIndex < 5) {
        setActive(`form-control-${nextIndex + 1}`)
        nextInput(`form-control-${nextIndex + 1}`)
      }
    }
  }

  const handleDelete = () => {
    setErrMsg('')
    const lastIndex = pin.findIndex((p) => p === '')
    const indexToDelete = lastIndex === -1 ? 5 : lastIndex - 1

    if (indexToDelete >= 0) {
      const newPin = [...pin]
      newPin[indexToDelete] = ''
      setPin(newPin)
      setActive(`form-control-${indexToDelete}`)
      nextInput(`form-control-${indexToDelete}`)
    }
  }

  useEffect(() => {
    if (pin.length === 6 && pin.every((pin) => pin !== '' && !isAction)) {
      handleSubmit()
    }
  }, [pin])

  useEffect(() => {
    if (token) {
      dispatch(setIsV3(true))
      navigate('/')
    }
  }, [token])

  return (
    <Container
      style={{ minHeight: '100vh' }}
      className="d-flex flex-column justify-content-between py-3"
    >
      {/* Header Section */}
      <div>
        <div
          onClick={() => {
            navigate('/login-v2')
          }}
          className="d-flex justify-content-start"
        >
          <ArrowLeft size={20} />
        </div>
        <h5 className="fw-bold text-center mt-5">Masukkan PIN</h5>
      </div>

      {/* PIN */}
      <div className="d-flex align-items-center justify-content-center flex-column gap-4">
        <div className="text-danger text-center">{errMsg}</div>
        <FormGroup className="d-flex gap-4">
          {[...Array(6)].map((_, i) => (
            <div key={i}>
              <div
                className="position-absolute top-0 start-0 w-0 h-0"
                style={{ zIndex: 2, backgroundColor: 'transparent' }}
                onClick={() => nextInput(active)}
              ></div>
              <Input
                readOnly
                style={{
                  zIndex: 1,
                }}
                autoFocus={i === 0}
                className="w-100 h-100 fs-2 border border-dark border-top-0 border-start-0 border-end-0 text-center text-primary rounded-0"
                id={`form-control-${i}`}
                key={i}
                maxLength={1}
                inputMode="numeric"
                pattern="[0-9]*"
                onFocus={(e) => {
                  e.target.style.boxShadow = 'none'
                }}
                value={
                  isShow && pin[i] !== ''
                    ? pin[i]
                    : !isShow && pin[i] !== ''
                      ? '●'
                      : ''
                }
                autoComplete="off"
                onKeyDown={(e) => {
                  if (!e.code.includes('Digit') && e.code !== 'Backspace') {
                    e.preventDefault()
                  } else if (e.code === 'Backspace') {
                    if (i > 0 || pin[i] !== '') {
                      setPin((prevPin) => {
                        const newPin = [...prevPin]
                        newPin[i] = ''
                        return newPin
                      })
                      setActive(`form-control-${i - 1}`)
                      nextInput(`form-control-${i - 1}`)
                    }
                  }
                }}
                onChange={(e) => {
                  const value = e.target.value

                  if (value.match(/^[0-9]$/)) {
                    setPin((prev) => {
                      const newPin = [...prev]
                      newPin[i] = value
                      return newPin
                    })

                    if (e.target.id !== 'form-control-5') {
                      setActive(`form-control-${i + 1}`)
                      nextInput(`form-control-${i + 1}`)
                    }
                  }
                }}
              />
            </div>
          ))}
        </FormGroup>

        {/* Show/Hide PIN */}
        <div
          onClick={toggleShowPw}
          className="d-flex gap-2 flex-centered text-primary fw-bold"
        >
          <Eye size={20} /> <span className="text-primary">Lihat PIN</span>
        </div>
      </div>

      {/* Keypad */}
      <div className="d-flex flex-column mt-4">
        <ButtonGroup className="d-flex justify-content-center">
          <Button
            block
            outline
            color="primary"
            className="border border-top-0 border-start-0 rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(1)}
          >
            1
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-top-0 rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(2)}
          >
            2
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border border-end-0 border-top-0 rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(3)}
          >
            3
          </Button>
        </ButtonGroup>
        <ButtonGroup className="d-flex justify-content-center">
          <Button
            block
            outline
            color="primary"
            className="border border-top-0 border-start-0 rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(4)}
          >
            4
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(5)}
          >
            5
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border border-end-0 rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(6)}
          >
            6
          </Button>
        </ButtonGroup>
        <ButtonGroup className="d-flex justify-content-center">
          <Button
            block
            outline
            color="primary"
            className="border border-top-0 border-start-0 rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(7)}
          >
            7
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(8)}
          >
            8
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border border-end-0 rounded-0 p-3n button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(9)}
          >
            9
          </Button>
        </ButtonGroup>
        <ButtonGroup className="d-flex justify-content-center">
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border border-start-0 rounded-0 p-3 button-no-focus"
            size="lg"
            disabled
          >
            {' '}
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={() => handleKeypadClick(0)}
          >
            0
          </Button>
          <Button
            block
            outline
            color="primary"
            className="border border-bottom-0 border border-end-0 rounded-0 p-3 button-no-focus"
            size="lg"
            onClick={handleDelete}
          >
            <Delete size={20} />
          </Button>
        </ButtonGroup>
      </div>
    </Container>
  )
}

export default Pin
