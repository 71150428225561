import CustomButton from 'components/Buttons/CustomButton'
import PMBSelect from 'components/PMBSelect'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import {
  manageGroupForGroup,
  packingAddStock,
  packingStock,
} from '../../API/manageStockAction'
import { enqueueSnackbar } from 'notistack'

export default function ModalPacking({
  isOpen,
  onClose,
  data,
  setIsFetch,
  setSelectedInventoryList,
  toggleClearRows,
  isSubGroup,
  packing,
  setSelectedStock,
  actionType = 'Buatkan',
}) {
  const dispatch = useDispatch()
  const { groupsInventory } = useSelector((s) => s.manageStock)
  const [label, setLabel] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [isExist, setIsExist] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    if (isSubGroup) {
      onCreateGroupForGroup()
      return
    }
    setLoading(true)
    try {
      const obj = {
        ...(!isExist && {
          label: label,
        }),
        ...(isExist && {
          id: label?.id,
        }),
        addedInventoryIds: data?.map((e) => e?.id),
      }
      const res = isExist
        ? await dispatch(packingAddStock(obj))
        : await dispatch(packingStock(obj))

      if (packing) {
        setSelectedStock({ group: res?.payload })
      }

      if (res?.error) {
        throw res
      } else {
        setIsFetch('all')
        onClose()
        enqueueSnackbar(
          `${data?.length} inventori berhasil di${actionType?.toLowerCase()} grup`,
          {
            variant: 'success',
          },
        )
        setSelectedInventoryList([])
        toggleClearRows()
      }
    } catch (error) {
      setErrMsg(error?.message ?? error?.payload)
    } finally {
      setLoading(false)
    }
  }

  const onCreateGroupForGroup = async () => {
    const payload = {
      ...(!isExist && {
        label: label,
      }),
      ...(isExist && {
        id: label?.id,
      }),
      addedGroupIds: data?.map((e) => e?.id),
    }

    const id = isExist ? label?.id : null

    try {
      const res = await dispatch(
        manageGroupForGroup({ actionType: 'create', payload, id }),
      ).unwrap()
      if (res?.error) {
        throw res
      }
      setIsFetch('all')
      onClose()
      enqueueSnackbar(
        `${data?.length} grup berhasil di${actionType?.toLowerCase()} grup`,
        {
          variant: 'success',
        },
      )
      setSelectedInventoryList([])
      toggleClearRows()
    } catch (err) {
      console.error('Kesalahan dalam onCreateGroupForGroup:', err)
      enqueueSnackbar('Terjadi kesalahan saat membuat grup', {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setLabel('')
      setErrMsg('')
    }
  }, [isOpen])

  return (
    <Modal className="p-4" isOpen={isOpen} centered>
      <ModalBody>
        <h5>{actionType} Grup</h5>
        <FormGroup>
          <Label>Label grup</Label>
          {!isExist ? (
            <Input
              name="label"
              id="label"
              autoFocus={true}
              value={label ?? ''}
              onChange={(e) => {
                setLabel(e.target.value)
                setErrMsg('')
              }}
              placeholder="Contoh: Packingan 1"
            />
          ) : (
            <PMBSelect
              placeholder="Pilih grup..."
              options={groupsInventory?.items}
              value={label}
              onChange={(e) => {
                setLabel(e)
              }}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Input
            name="isExist"
            id="isExist"
            value={label ?? ''}
            onChange={(e) => {
              if (isExist) {
                setIsExist(false)
              } else {
                setIsExist(true)
              }
              setErrMsg('')
            }}
            type="checkbox"
            className="me-2"
          />
          Masukkan ke grup yang sudah ada
        </FormGroup>
        {errMsg && (
          <div className="mb-2">
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </div>
        )}
        <div className="d-flex gap-3">
          <CustomButton
            onClick={() => {
              onClose()
              setIsExist(false)
              setErrMsg('')
            }}
            disabled={loading}
            color="primary"
            outline
            block
          >
            Batal
          </CustomButton>
          <CustomButton
            disabled={loading || !label}
            onClick={handleSubmit}
            color="primary"
            block
          >
            {actionType} Grup
          </CustomButton>
        </div>
      </ModalBody>
    </Modal>
  )
}
