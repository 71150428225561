import PropTypes from 'prop-types'
import { createContext } from 'react'
import { createMongoAbility } from '@casl/ability'
import { createContextualCan } from '@casl/react'
import { useSelector } from 'react-redux'
import { BUSINESS_AUTOREPO, MANAGE } from 'utils/subjectActions'

export const AbilityContext = createContext()

export const Can = createContextualCan(AbilityContext.Consumer)

export const AbilityProvider = ({ children }) => {
  const { abilityRole, serviceProvision } = useSelector((s) => s.auth)

  const safeAbilityRole = Array.isArray(abilityRole) ? abilityRole : []

  let customRole = []

  if (serviceProvision?.autorepo === true) {
    customRole.push({
      action: MANAGE,
      subject: BUSINESS_AUTOREPO,
    })
  }

  const extendedRoles = safeAbilityRole.reduce((acc, role) => {
    if (role.subject === 'business_all') {
      const actions = Array.isArray(role.action) ? role.action : [role.action]
      actions.forEach((action) => {
        acc.push({ action, subject: 'all' })
      })
    } else {
      acc.push(role)
    }
    return acc
  }, [])

  const mergeRole = [...extendedRoles, ...customRole]

  return (
    <AbilityContext.Provider value={createMongoAbility(mergeRole)}>
      {children}
    </AbilityContext.Provider>
  )
}

AbilityProvider.propTypes = {
  children: PropTypes.node,
}
