import CheckIcon from 'assets/icons/check-circle-success.svg'
import ICDefaultPhoto from 'assets/icons/default-ava-profile-user.svg'
import ICPhone from 'assets/icons/ic-mobile.png'
import ICTransaction from 'assets/icons/icon-transaksi.svg'
import ThunderIcon from 'assets/icons/petir.svg'
import ICThunderDisable from 'assets/icons/thunder-disable.svg'
import ICThunder from 'assets/icons/thunder-icon.svg'
import ICUangBelanja from 'assets/icons/uang-belanja.png'
import ICUangUsaha from 'assets/icons/uang-usaha.png'
import axios from 'axios'
import CustomButton from 'components/Buttons/CustomButton'
import RoundedComponent from 'components/Rounded'
import SidebarDetails from 'components/SidebarDetails'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { setTotal } from 'private-views/transactions/commodityPurchaseSlice'
import { useEffect, useState } from 'react'
import { ArrowRight, ChevronDown, ChevronUp, FileText } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardText,
  Collapse,
  FormGroup,
  Input,
  Spinner,
} from 'reactstrap'
import { formatDatePMBV2 } from 'utils/formatDate'
import {
  BUSINESS_TRANSACTION_PURCHASE,
  MAKE,
  MANAGE,
} from 'utils/subjectActions'
import ModalInfo from '../ModalDashboard'
import {
  getDetailProfile,
  getPricing,
  postConfirmTransaction,
} from '../dashboardAction'
import {
  setBannerType,
  setMessageBanner,
  toggleBanner,
} from '../dashboardSlice'
import './style.scss'

const SidebarDashboard = (props) => {
  const {
    isOpen,
    toggle,
    toggleModal,
    row,
    isOpenModal,
    handleCallback,
    options,
  } = props
  const { token } = useSelector((s) => s.auth)
  const { pricing, detail, balance, loading } = useSelector((s) => s.dashboard)
  const intl = useIntl()
  const dispatch = useDispatch()
  const [isInstant, setIsInstant] = useState(false)
  const [selectedPricing, setSelectedPricing] = useState(null)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [isDetail, setIsDetail] = useState(false)
  const toggleCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse)
  }
  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar)
  }
  const navigate = useNavigate()
  const makePurchase = useAbilityAccess({
    action: MAKE,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })
  const managePurchase = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      const obj = {
        escrowPaymentId: row?.escrowpaymentId,
        useDisbursementpricingId: detail?.items?.[0].orderDetail
          ?.useProofOfPayment
          ? 0
          : (selectedPricing ?? pricing?.items?.find((e) => e?.fee === 0)?.id),
      }

      const res = await dispatch(postConfirmTransaction(obj))
      if (res.payload.status === 200) {
        toggle()
        toggleModal()
        handleCallback()
        navigate('/')
        dispatch(setBannerType('success'))
        dispatch(
          setMessageBanner(
            `Konfirmasi diterima. Pembelian ke ${row?.sellerDetail?.fullName} telah selesai.`,
          ),
        )
        dispatch(toggleBanner())
      } else {
        throw res
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setErrMsg(error?.payload)
    } finally {
      setIsLoading(false)
    }
  }

  const handleIsInstant = () => {
    setIsInstant(!isInstant)
    if (!isInstant) {
      const maxPricing = pricing?.items?.reduce(
        (max, item) => {
          return item.fee > max.fee && !item.disable ? item : max
        },
        { fee: -Infinity },
      )
      setSelectedPricing(maxPricing?.id || null)
    } else {
      setSelectedPricing(null)
    }
  }

  const downloadAsPdf = (data, url, type) => {
    setIsLoading(true)
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...{ responseType: 'blob' },
      })
      .then((response) => {
        const defName = `TRX/ID/${data.loan ? 'PMG-' : ''}${data.id}`
        var pdfNumber = defName
        if (data.deponumbers && data.deponumbers.length > 1) {
          pdfNumber = data.deponumbers[0].fullNumber
          if (data.loan || data.creditRequested > 0) {
            pdfNumber.replace(type, `${type}/PMG-`)
          }
        }
        var filename = `${pdfNumber.replaceAll('/', '-')}.pdf`
        const disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        const blobFile = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blobFile)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(function (error) {})
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (
      isOpen &&
      row?.status === 'Diproses' &&
      !detail?.items?.[0]?.orderDetail?.autoRelease &&
      !detail?.items?.[0]?.orderDetail?.useProofOfPayment
    ) {
      dispatch(getPricing({ escrowpaymentId: row?.escrowpaymentId }))
    }

    if (row && isOpen) {
      dispatch(getDetailProfile({ id: row?.id, type: row?.orderDetail?.type }))
    }
  }, [isOpen, row, dispatch])

  useEffect(() => {
    if (!isOpen) {
      setIsInstant(false)
      setSelectedPricing(null)
      setIsOpenCollapse(false)
      setErrMsg('')
      setIsDetail(false)
    }
    if (!isOpenModal) {
      setErrMsg('')
    }
  }, [isOpen, isOpenModal])

  return (
    <>
      <ModalInfo
        isOpen={isOpenModal}
        toggle={toggleModal}
        toggleSidebar={toggleSidebar}
        handleConfirm={handleConfirm}
        errMsg={errMsg}
        isLoading={isLoading}
      />
      <SidebarDetails
        size="lg"
        open={isOpen}
        title="Detail Transaksi"
        headerClassName="mb-1"
        contentClassName="pt-0"
        toggleSidebar={toggle}
        isBack={isDetail}
        handleBack={() => setIsDetail(false)}
      >
        {loading ? (
          <div className="flex-centered">
            <Spinner color="primary" />
          </div>
        ) : isDetail ? (
          <div className="h-100 w-100 mt-3">
            <div className="gap-1">
              {detail?.items?.[0]?.itemDetail?.map((e, i) => {
                return (
                  <Card key={i} body className="mb-3 border-0 shadow-sm">
                    <div className="mb-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="fw-600">{e?.nameItem}</span>
                        <span>
                          Rp
                          {intl.formatNumber(e?.price * e?.amount ?? 0, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                      <span>
                        <span className="text-primary">
                          {intl.formatNumber(e?.amount, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })}{' '}
                          {e?.unit} x Rp
                          {intl.formatNumber(e?.price, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>{' '}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Margin PasarMIKRO</span>
                      <span>
                        {' '}
                        Rp
                        {e?.price
                          ? intl.formatNumber(
                              ((e?.amount * e?.price) /
                                (detail?.items?.[0]?.totalAmount ?? 0)) *
                                (detail?.items?.[0]?.credit?.margin ?? 0),
                              {
                                useGrouping: 'always',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              },
                            )
                          : 0}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Subtotal ({e?.nameItem})</span>
                      <span>
                        {' '}
                        Rp
                        {e?.price
                          ? intl.formatNumber(
                              e?.amount * e?.price +
                                ((e?.amount * e?.price) /
                                  (detail?.items?.[0]?.totalAmount ?? 0)) *
                                  (detail?.items?.[0]?.credit?.margin ?? 0),
                              {
                                useGrouping: 'always',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              },
                            )
                          : 0}
                      </span>
                    </div>
                  </Card>
                )
              })}
            </div>
            <Card body className="mb-3 border-0 shadow-sm">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <span>
                  Subtotal ({detail?.items?.[0]?.itemDetail?.length} Komoditas)
                </span>
                <span>
                  Rp
                  {intl.formatNumber(
                    (detail?.items?.[0]?.totalAmount ?? 0) +
                      (detail?.items?.[0]?.credit?.margin ?? 0),
                    {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    },
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Biaya transaksi</span>
                <span>
                  {' '}
                  Rp
                  {intl.formatNumber(
                    (detail?.items?.[0]?.credit?.feeTx ?? 0) -
                      (detail?.items?.[0]?.credit?.feeTxVAT ?? 0),
                    {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    },
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>PPN</span>
                <span>
                  {' '}
                  Rp
                  {intl.formatNumber(
                    (detail?.items?.[0]?.credit?.totalVAT ?? 0) +
                      (detail?.items?.[0]?.credit?.feeTxVAT ?? 0),
                    {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    },
                  )}
                </span>
              </div>
              <hr></hr>
              <div className="d-flex justify-content-between align-items-center">
                <span className="fw-bold">Total yang dibayarkan</span>
                <span className="fw-bold">
                  Rp
                  {intl.formatNumber(
                    (detail?.items?.[0]?.totalAmount ?? 0) +
                      (detail?.items?.[0]?.credit?.margin ?? 0) +
                      (detail?.items?.[0]?.credit?.feeTx ?? 0),
                    {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    },
                  )}
                </span>
              </div>
            </Card>
          </div>
        ) : (
          <div className="h-100 w-100 mt-3">
            {/* PO */}
            {detail?.items?.[0]?.orderDetail?.poNumber && (
              <Card className="mb-3 border-0 shadow-sm">
                <CardBody>
                  <span className="fw-bold flex-centered">
                    {detail?.items?.[0]?.orderDetail?.poNumber}
                  </span>
                </CardBody>
              </Card>
            )}
            {/* Alur Transaksi */}

            <Card
              onClick={() => {
                toggleCollapse()
              }}
              body
              className="mb-3 border-0 shadow-sm"
            >
              <div className="d-flex gap-2 flex-row align-items-center justify-content-between">
                <div className="d-flex flex-row gap-2">
                  <div className="flex-centered justify-content-start gap-1 align-items-center">
                    <img src={ICTransaction} alt="ava" width={40} height={40} />
                  </div>
                  <div>
                    <CardText tag="h6" className="fw-bold text-primary">
                      Alur Transaksi
                    </CardText>
                    <CardText tag="p" className="m-0 fw-light">
                      Pembeli membuat transaksi:
                    </CardText>
                    <CardText className="fw-light" tag="p">
                      {`(${formatDatePMBV2(detail?.items?.[0]?.history?.createdDate ?? detail?.items?.[0]?.createdAt)})`}
                    </CardText>
                  </div>
                </div>
                {detail?.items?.[0]?.history &&
                  (isOpenCollapse ? (
                    <ChevronUp size={15} />
                  ) : (
                    <ChevronDown size={15} />
                  ))}
              </div>
              {detail?.items?.[0]?.history && (
                <Collapse isOpen={isOpenCollapse}>
                  <CardBody>
                    <div>
                      {(detail?.items?.[0]?.history?.createdDate ||
                        detail?.items?.[0]?.createdAt) && (
                        <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                          <RoundedComponent
                            childern="1"
                            style={{
                              backgroundColor: '#C0E5F2',
                              width: '20px',
                              height: '20px',
                            }}
                          />
                          <div className="d-flex flex-column">
                            <span>Pesanan dibuat oleh pembeli</span>
                            <small className="text-secondary">
                              {' '}
                              {`(${formatDatePMBV2(detail?.items?.[0]?.history?.createdDate ?? detail?.items?.[0]?.createdAt)})`}
                            </small>
                          </div>
                        </div>
                      )}
                      {detail?.items?.[0]?.history?.sellerConfirmDate && (
                        <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                          <RoundedComponent
                            childern="2"
                            style={{
                              backgroundColor: '#C0E5F2',
                              width: '20px',
                              height: '20px',
                            }}
                          />
                          <div className="d-flex flex-column">
                            <span>Pesanan diproses oleh penjual</span>
                            <small className="text-secondary">{`(${formatDatePMBV2(detail?.items?.[0]?.history?.sellerConfirmDate)})`}</small>
                          </div>
                        </div>
                      )}
                      {detail?.items?.[0]?.history?.releaseDate && (
                        <>
                          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                            <RoundedComponent
                              childern="3"
                              style={{
                                backgroundColor: '#C0E5F2',
                                width: '20px',
                                height: '20px',
                              }}
                            />
                            <div className="d-flex flex-column">
                              <span>Pembeli konfirmasi barang sampai</span>
                              <small className="text-secondary">{`(${formatDatePMBV2(
                                detail?.items?.[0]?.history?.releaseDate,
                              )})`}</small>
                            </div>
                          </div>
                          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                            <RoundedComponent
                              childern="4"
                              style={{
                                backgroundColor: '#C0E5F2',
                                width: '20px',
                                height: '20px',
                              }}
                            />
                            <div className="d-flex flex-column">
                              <span>Transaksi selesai</span>
                              <small className="text-secondary">{`(${formatDatePMBV2(
                                detail?.items?.[0]?.history?.updatedAt,
                              )})`}</small>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Collapse>
              )}
            </Card>

            {/* STATUS */}

            <div className="rounded badge-warning p-3 text-center mb-3 d-block w-100">
              {row.status}
            </div>

            {/* PO */}
            {detail?.items?.[0]?.orderDetail?.transaction_number && (
              <Card className="mb-3 border-0 shadow-sm">
                <CardBody>
                  <div className="d-flex flex-column">
                    <span className="fw-bold">Nomor Transaksi (Custom)</span>
                    <span>
                      {detail?.items?.[0]?.orderDetail?.transaction_number}
                    </span>
                  </div>
                </CardBody>
              </Card>
            )}

            {/* penjual */}
            <Card body className="mb-3 border-0 shadow-sm">
              <CardText tag="h6" className="fw-bold">
                {options?.type === 'SELL' ? 'Pembeli' : 'Penjual'}
              </CardText>
              <div className="flex-centered justify-content-start gap-1">
                {options?.type === 'BUY' && (
                  <>
                    <img
                      src={row?.sellerOrBuyAvatar ?? ICDefaultPhoto}
                      className="rounded-circle"
                      alt="pak tani"
                      width={32}
                      height={32}
                    />
                    <span>{row.sellerDetail?.fullName}</span>
                  </>
                )}
                {options?.type === 'SELL' && (
                  <>
                    <img
                      src={row?.sellerOrBuyAvatar ?? ICDefaultPhoto}
                      className="rounded-circle"
                      alt="pak tani"
                      width={32}
                      height={32}
                    />
                    <span>{row.buyerDetail?.fullName}</span>
                  </>
                )}
              </div>
            </Card>

            {/* Rincian Pesanan */}
            <Card body className="mb-3 border-0 shadow-sm">
              <CardText tag="h6" className="fw-bold">
                Rincian Pesanan
              </CardText>
              <div className="gap-1">
                {detail?.items?.[0]?.itemDetail?.map((e, i) => {
                  return (
                    <div
                      key={i}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="d-flex flex-column mb-2">
                        <span>{e?.nameItem}</span>
                        <span>
                          <span className="fw-light">
                            {intl.formatNumber(e?.amount, {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            })}{' '}
                            {e?.unit} •{' '}
                          </span>{' '}
                          <span className="text-primary">
                            Rp
                            {intl.formatNumber(e?.price, {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                          </span>
                        </span>
                      </div>
                      <span>
                        Rp
                        {intl.formatNumber(e?.price * e?.amount ?? 0, {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </span>
                    </div>
                  )
                })}
                <hr></hr>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="fw-bold">Total yang dibayarkan</span>
                  <span className="fw-bold">
                    Rp
                    {intl.formatNumber(detail?.items?.[0]?.totalAmount, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
                {detail?.items?.[0]?.orderDetail?.autoRelease ? (
                  <>
                    <div className="text-success-700 rounded bg-success-50 p-1 mt-2">
                      <small className="fw-500 m-1 d-flex align-items-center gap-2">
                        <img src={CheckIcon} alt="check-icon" />
                        <span>Barang sudah diterima</span>
                      </small>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <CustomButton
                onClick={() => setIsDetail(true)}
                block
                color="primary"
                outline
                className="mt-2"
              >
                <ArrowRight size={20} /> Lihat Detail Pembayaran
              </CustomButton>
            </Card>

            {/* Pembayaran */}
            {detail?.items?.[0]?.escrowpayment?.status === 'ON_GOING' &&
              detail?.items?.[0]?.orderDetail?.type === 'BUY' &&
              !detail?.items?.[0]?.orderDetail?.autoRelease &&
              !detail?.items?.[0]?.orderDetail?.useProofOfPayment && (
                <Card body className="mb-3 border-0 shadow-sm">
                  <div className="d-flex justify-content-between">
                    <CardText tag="h6" className="fw-bold">
                      Pembayaran Instan
                    </CardText>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        role="switch"
                        onChange={handleIsInstant}
                      />
                    </FormGroup>
                  </div>
                  <div className="flex-centered justify-content-between gap-1">
                    {isInstant && (
                      <div className="d-flex flex-column gap-3">
                        {pricing?.items?.map((e, i) => {
                          if (e?.fee > 0) {
                            return (
                              <div key={i}>
                                <Card
                                  style={{
                                    backgroundColor:
                                      selectedPricing === e?.id
                                        ? '#ECFAFF'
                                        : e?.disable
                                          ? '#F9FAFB'
                                          : '',
                                  }}
                                  className={
                                    selectedPricing === e?.id
                                      ? 'border-primary'
                                      : ''
                                  }
                                  onClick={() => {
                                    if (!e?.disable) {
                                      setSelectedPricing(e?.id)
                                      setErrMsg('')
                                    }
                                  }}
                                >
                                  <CardBody className="pb-0">
                                    <div className="d-flex flex-row gap-2">
                                      <div>
                                        <img
                                          src={
                                            e?.disable
                                              ? ICThunderDisable
                                              : ICThunder
                                          }
                                          alt="kilat"
                                          width={40}
                                          height={40}
                                        />
                                      </div>
                                      <div>
                                        <span style={{ fontWeight: '500' }}>
                                          Instan {e?.title} (Rp
                                          {intl.formatNumber(e?.fee, {
                                            useGrouping: 'always',
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0,
                                          })}
                                          )
                                        </span>
                                        <p
                                          style={{
                                            color:
                                              selectedPricing === e?.id
                                                ? '#006386'
                                                : '',
                                          }}
                                        >
                                          {e?.disable ? (
                                            <span style={{ color: '#B42318' }}>
                                              {e?.disableReason}
                                            </span>
                                          ) : (
                                            <span>{e.description}</span>
                                          )}
                                        </p>
                                      </div>
                                      <Input
                                        type="radio"
                                        disabled={e?.disable}
                                        checked={selectedPricing === e?.id}
                                        onChange={() => {
                                          if (!e?.disable) {
                                            setSelectedPricing(e?.id)
                                            setErrMsg('')
                                          }
                                        }}
                                      />
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            )
                          }
                        })}
                        {pricing?.items?.some(
                          (e) => e?.fee > balance?.uangBelanja,
                        ) ? (
                          <CustomButton
                            outline
                            block
                            color="primary"
                            onClick={() => navigate('/topup')}
                          >
                            Tambah Uang Belanja
                          </CustomButton>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </Card>
              )}

            {/* Details Dibuat oleh */}
            <Card body className="mb-3 border-0 shadow-sm">
              <CardText tag="h6" className="fw-bold">
                Dibuat Oleh
              </CardText>
              <div className="flex-centered justify-content-between gap-1">
                <div className="d-flex gap-2 align-items-center">
                  <img
                    src={row?.requesterAvatar ?? ICDefaultPhoto}
                    alt="ava"
                    width={32}
                    height={32}
                    className="rounded-circle"
                  />
                  <span>
                    {row?.requesterName
                      ? row?.requesterName
                      : row?.orderDetail?.type === 'BUY'
                        ? row?.buyerDetail?.fullName
                        : row?.sellerDetail?.fullName}
                  </span>
                </div>
                {detail?.items?.[0]?.orderDetail?.source ? (
                  <></>
                ) : (
                  <img src={ICPhone} alt="Mobile" width={24} height={24} />
                )}
              </div>
            </Card>

            {/* Details Dibuat Approve oleh */}

            {/* Approval Detail  */}
            {row?.pmbmakerchecker?.checkerName && (
              <Card body className="mb-3 border-0 shadow-sm">
                <CardText tag="h6" className="fw-bold text-success">
                  Di-approve Oleh
                </CardText>
                <div className="flex-centered justify-content-start gap-1">
                  <img
                    src={row?.checkerAvatar ?? ICDefaultPhoto}
                    alt="ava"
                    width={32}
                    height={32}
                    className="rounded-circle"
                  />
                  <span>{row?.pmbmakerchecker?.checkerName}</span>
                </div>
              </Card>
            )}

            {/* Metode Pembayaran */}
            {detail?.items?.[0].escrowpayment ||
            detail?.items?.[0].credit ||
            detail?.items?.[0].creditRequested ||
            detail?.items?.[0].orderDetail?.useProofOfPayment ? (
              <Card className="mb-3 border-0 shadow-sm">
                <CardBody>
                  <CardText tag="h6" className="fw-bold">
                    Metode Pembayaran
                  </CardText>
                  <div className="d-flex justify-content-center align-items-center flex-wrap">
                    {detail?.items?.[0].orderDetail?.useProofOfPayment ? (
                      <div className="rounded w-100">
                        <span>
                          Tunai atau sudah bayar sebelum transaksi dibuat
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {detail?.items?.[0]?.credit?.upfront ? (
                      <div
                        className="rounded p-2 d-flex w-100 gap-2"
                        style={{ backgroundColor: '#ECF9FE' }}
                      >
                        <img
                          style={{ width: '24px', height: '24px' }}
                          alt="Uang Belanja"
                          src={ICUangBelanja}
                        />
                        <span className="fw-bold">Uang Belanja</span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {(detail?.items?.[0]?.credit?.upfront &&
                      detail?.items?.[0].escrowpayment?.userpinjamanId) ||
                    detail?.items?.[0].creditRequested ? (
                      <span>+</span>
                    ) : (
                      <></>
                    )}
                    {detail?.items?.[0].escrowpayment?.userpinjamanId ||
                    detail?.items?.[0].creditRequested ? (
                      <div
                        className="rounded p-2 d-flex w-100 gap-2"
                        style={{ backgroundColor: '#FFFAEB' }}
                      >
                        <img
                          style={{ width: '24px', height: '24px' }}
                          alt="Uang Usaha"
                          src={ICUangUsaha}
                        />
                        <span className="fw-bold">Talangin</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </CardBody>
              </Card>
            ) : (
              <></>
            )}

            {detail?.items?.[0].orderDetail?.useDisbursementpricingId ? (
              <>
                <Card className="mb-3 border-0 shadow-sm">
                  <CardBody>
                    <CardText tag="h6" className="fw-bold">
                      Pembayaran instan yang digunakan
                    </CardText>
                    <div>
                      <span className="fw-bold">
                        {detail?.items?.[0].orderDetail?.disbursementPricing
                          ?.title
                          ? detail.items[0].orderDetail.disbursementPricing.title.toUpperCase()
                          : ''}
                        {Array.from({
                          length:
                            detail?.items?.[0]?.orderDetail?.disbursementPricing
                              ?.numBolts ?? 0,
                        }).map((_, index) => (
                          <img
                            key={index}
                            src={ThunderIcon}
                            alt="Thunder Icon"
                          />
                        ))}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </>
            ) : (
              <></>
            )}

            {/* tombol download invoice */}
            {detail?.items?.[0]?.status === 'Selesai' &&
            detail?.items?.[0].invoice ? (
              <Card className="mb-3 border-0 shadow-sm">
                <CardBody>
                  <CardText tag="h6" className="fw-bold">
                    Invoice
                  </CardText>
                  <CustomButton
                    onClick={() => {
                      downloadAsPdf(
                        row,
                        detail?.items?.[0].escrowpayment?.userpinjamanId
                          ? detail?.items?.[0].userId ===
                            detail?.items?.[0].buyerDetail?.userId
                            ? detail?.items?.[0].invoice?.pmToBuyer?.download
                            : detail?.items?.[0].invoice?.pmToSeller?.download
                          : detail?.items?.[0].invoice?.download,
                        'INVF',
                      )
                    }}
                    disabled={loading || isLoading}
                    color="primary"
                    outline
                    block
                  >
                    <FileText /> Unduh Invoice
                  </CustomButton>
                </CardBody>
              </Card>
            ) : (
              <></>
            )}

            {/* Start Tombol Lanjut Pembayaran  */}
            {((makePurchase &&
              !managePurchase &&
              detail?.items?.[0]?.pmbmakerchecker) ||
              (managePurchase && !detail?.items?.[0]?.pmbmakerchecker)) &&
              detail?.items?.[0]?.status === 'Menunggu Pembayaran Pembeli' &&
              !detail?.items?.[0].escrowpaymentId &&
              detail?.items?.[0].orderDetail.type === 'BUY' && (
                <CustomButton
                  block
                  color="primary"
                  size="sm"
                  onClick={() => {
                    let dataComodities = []
                    detail.items.forEach((e) => {
                      e.itemDetail.forEach((i) => {
                        dataComodities.push({
                          comodity: {
                            value: i.id,
                            label: i.nameItem,
                            id: i.id,
                            img: i.imageUrl,
                            sku: i.SKU,
                          },
                          totalPrice:
                            i.totalPrice ??
                            parseInt(i.price) * parseInt(i.amount),
                          quantity: i.amount,
                          unit: {
                            value: i.unit,
                            label: i.unit,
                          },
                          price: i.price,
                        })
                      })
                    })

                    localStorage.setItem(
                      'userData',
                      JSON.stringify({
                        dataComodities: dataComodities,
                        dataSeller: detail.items[0].sellerDetail,
                        talangin: balance.talangin,
                        wallet1: balance.uangBelanja,
                        wallet2: balance.uangUsaha,
                        trxId: detail.items[0].id,
                      }),
                    )

                    dispatch(
                      setTotal(
                        dataComodities?.reduce(
                          (sum, a) => sum + a.totalPrice,
                          0,
                        ),
                      ),
                    )

                    navigate('/transaksi-pembelian-pembayaran')
                  }}
                >
                  Lanjut Pembayaran
                </CustomButton>
              )}
            {/* End Tombol Lanjut Pembayaran  */}
            {detail?.items?.[0]?.escrowpayment?.status === 'ON_GOING' &&
              detail?.items?.[0]?.orderDetail?.type === 'BUY' &&
              !detail?.items?.[0]?.orderDetail?.autoRelease &&
              Number(detail?.items?.[0]?.buyerDetail?.userId) ===
                Number(detail?.items?.[0]?.userId) && (
                <div className="d-flex justify-content-between gap-3 w-100">
                  {/* <CustomButton
                    block
                    color="primary"
                    size="sm"
                    outline
                    onClick={toggle}
                  >
                    Penyesuaian Pesanan
                  </CustomButton> */}
                  <CustomButton
                    block
                    color="primary"
                    size="sm"
                    onClick={() => {
                      toggleModal()
                    }}
                  >
                    Konfirmasi
                  </CustomButton>
                </div>
              )}
          </div>
        )}
      </SidebarDetails>
    </>
  )
}

export default SidebarDashboard
