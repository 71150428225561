import SidebarDetails from 'components/SidebarDetails'
import { useIntl } from 'react-intl'
import { Card } from 'reactstrap'

const SidebarDetailPayment = (props) => {
  const { isOpen, toggle, row: transaction } = props

  const intl = useIntl()
  const valuePPN =
    (transaction?.credit?.totalVAT ?? 0) +
    (transaction?.credit?.useFreeTx === 1
      ? 0
      : (transaction?.credit?.feeTxVAT ?? 0))

  return (
    <>
      <SidebarDetails
        size="lg"
        open={isOpen}
        title="Detail Transaksi"
        headerClassName="mb-1"
        contentClassName="pt-0"
        toggleSidebar={toggle}
        isBack={toggle}
        handleBack={() => toggle()}
      >
        <div className="h-100 w-100 mt-3">
          <div className="gap-1">
            {transaction?.itemDetail?.map((e, i) => {
              return (
                <Card key={i} body className="mb-3 border-0 shadow-sm">
                  <div className="mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fw-600">{e?.nameItem}</span>
                      <span>
                        Rp
                        {intl.formatNumber(e?.price * e?.amount ?? 0, {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </span>
                    </div>
                    <span>
                      <span className="text-primary">
                        {intl.formatNumber(e?.amount, {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}{' '}
                        {e?.unit} x Rp
                        {intl.formatNumber(e?.price, {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </span>{' '}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Margin PasarMIKRO</span>
                    <span>
                      {' '}
                      Rp
                      {intl.formatNumber(
                        transaction?.totalAmount && transaction?.totalAmount > 0
                          ? (((e?.amount ?? 0) * (e?.price ?? 0)) /
                              transaction?.totalAmount) *
                              (transaction?.credit?.margin ?? 0)
                          : (e?.amount ?? 0) *
                              (e?.price ?? 0) *
                              (transaction?.credit?.margin ?? 0),
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Subtotal ({e?.nameItem})</span>
                    <span>
                      {' '}
                      Rp
                      {intl.formatNumber(
                        (e?.amount ?? 0) * (e?.price ?? 0) +
                          (transaction?.totalAmount &&
                          transaction?.totalAmount > 0
                            ? ((e?.amount ?? 0) * (e?.price ?? 0)) /
                              transaction.totalAmount
                            : 0) *
                            (transaction?.credit?.margin ?? 0),
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                    </span>
                  </div>
                </Card>
              )
            })}
          </div>
          <Card body className="mb-3 border-0 shadow-sm">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <span>
                Subtotal ({transaction?.itemDetail?.length} Komoditas)
              </span>
              <span>
                Rp
                {intl.formatNumber(
                  (transaction?.totalAmount ?? 0) +
                    (transaction?.credit?.margin ?? 0),
                  {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  },
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span>Biaya transaksi</span>
              <span>
                {' '}
                Rp
                {intl.formatNumber(
                  (transaction?.credit?.feeTx ?? 0) -
                    (transaction?.credit?.useFreeTx === 1
                      ? 0
                      : (transaction?.credit?.feeTxVAT ?? 0)),
                  {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  },
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span>PPN</span>
              <span>
                {' '}
                Rp
                {intl.formatNumber(valuePPN, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
            </div>
            <hr></hr>
            <div className="d-flex justify-content-between align-items-center">
              <span className="fw-bold">Total yang dibayarkan</span>
              <span className="fw-bold">
                Rp
                {intl.formatNumber(
                  (transaction?.totalAmount ?? 0) +
                    (transaction?.credit?.margin ?? 0) +
                    ((transaction?.credit?.feeTx ?? 0) -
                      (transaction?.credit?.feeTxVAT ?? 0)) +
                    (valuePPN ?? 0),
                  {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  },
                )}
              </span>
            </div>
          </Card>
        </div>
      </SidebarDetails>
    </>
  )
}

export default SidebarDetailPayment
