import ICDefaultPhoto from 'assets/icons/default-ava-profile-user.svg'
import ICTransaction from 'assets/icons/icon-transaksi.svg'
import IPhoto from 'assets/icons/Pak-Tani.png'
import SidebarDetails from 'components/SidebarDetails'
import { TableComponent as DataTable } from 'components/TableComponent'
import { useState } from 'react'
import { Check, CheckCircle, Eye, X } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Card, CardText } from 'reactstrap'
import { selectedData, setOpenModal } from '../approvalSlice'
import ModalInfoFast from '../ModalApproval'

import CustomButton from 'components/Buttons/CustomButton'
import PMBPagination from 'components/PMBPagination'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import useGetTrxApproval from 'hooks/useGetTrxApproval'
import { formatDatePMBV2 } from 'utils/formatDate'
import { newFormatThousandSeparator } from 'utils/formatNumber'
import { BUSINESS_MYAPPROVAL, MANAGE } from 'utils/subjectActions'
import { statusApproval } from '../ApprovalTable'

const TableRequestSell = () => {
  const access = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_MYAPPROVAL,
  })
  const dispatch = useDispatch()

  const { item, isOpenModal } = useSelector((s) => s.approval)

  const [selectRows, setSelectRows] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [clearRows, setClearRows] = useState(false)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)

  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const [isAction, setIsAction] = useState('')
  const [isOpen2, setIsOpen2] = useState(false)

  const [pagination, setPagination] = useState({ size: 10, page: 0 })

  const trxApproval = useGetTrxApproval({
    filter: 'Request sell',
    page: pagination.page ?? 0,
    size: pagination.size ?? 0,
  })

  const toggle = () => setIsOpen(!isOpen)

  const toggleClearRows = () => {
    setClearRows(!clearRows)
  }

  const toggleSidebar = (payload) => {
    dispatch(setOpenModal())
    if (payload) {
      dispatch(selectedData(payload))
    }
  }

  const toggleFastApprove = (payload, type) => {
    setIsOpen2(!isOpen2)
    setSelectRows({ data: payload, type })
  }

  const handleSelect = (e) => {
    setSelectedRows(e)
  }

  const columns = [
    {
      name: <span>Pembeli</span>,
      minWidth: '200px',
      cell: (row) => (
        <div className="flex-centered gap-2">
          <img
            src={row?.buyerAvatar ?? IPhoto}
            alt="pak tani"
            width={20}
            height={20}
            className="rounded-circle"
          />
          <span>{row.buyerDetail?.fullName}</span>
        </div>
      ),
    },
    {
      name: <span>Komoditas</span>,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span className="flex-row">{`${row?.itemDetail?.[0]?.nameItem} • ${row?.itemDetail?.[0]?.amount} ${row?.itemDetail?.[0]?.unit}`}</span>
          <span className="fw-light">
            {row?.itemDetail?.length > 1
              ? `dan ${row?.itemDetail?.length - 1} komoditas lainnya`
              : ''}
          </span>
        </div>
      ),
    },
    {
      name: (
        <div
          style={{
            textAlign: 'right',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          Total Transaksi
        </div>
      ),
      cell: (row) => (
        <div className="d-flex justify-content-end w-100">
          <span>
            Rp
            {newFormatThousandSeparator(
              row?.itemDetail
                ?.map((e) => e.totalPrice)
                ?.reduce((sum, e) => sum + e, 0),
              0,
            )}
          </span>
        </div>
      ),
    },
    {
      name: <span>Dibuat oleh</span>,
      cell: (row) => (
        <div className="flex-centered gap-2">
          <img
            src={row?.makerAvatar ?? IPhoto}
            alt="pak tani"
            width={20}
            height={20}
            className="rounded-circle"
          />
          <span>{row?.makerName}</span>
        </div>
      ),
    },
    {
      name: '',
      cell: (row) => {
        return (
          <>
            <Eye
              size={18}
              className="fw-bold mx-1"
              onClick={() => toggleSidebar(row)}
              style={{ cursor: 'pointer' }}
            />
            {access && (
              <>
                <X
                  size={18}
                  className="text-danger fw-bold mx-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    toggleFastApprove([row], statusApproval.reject)
                  }}
                />
                <Check
                  size={18}
                  className="text-success fw-bold mx-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    toggleFastApprove([row], statusApproval.approve)
                  }}
                />
              </>
            )}
          </>
        )
      },
      center: true,
    },
  ]

  return (
    <>
      <Card body className="mb-3 border-0 shadow-sm">
        <div className="d-flex justify-content-between align-items-center">
          <CardText tag="h5" className="pb-2 py-2">
            Permintaan Persetujuan
          </CardText>
          {selectedRows?.selectedCount > 0 ? (
            <div className="d-flex gap-3 align-items-center justify-content-center">
              <span className="text-muted">
                {selectedRows?.selectedCount} transaksi terpilih
              </span>
              <div className="d-flex gap-2">
                <CustomButton
                  onClick={() => {
                    toggleFastApprove(
                      selectedRows?.selectedRows,
                      statusApproval.reject,
                    )
                  }}
                  className="rounded"
                  size="sm"
                  color="danger"
                  outline
                >
                  {' '}
                  <X size={20} />{' '}
                </CustomButton>
                <CustomButton
                  onClick={() => {
                    toggleFastApprove(
                      selectedRows?.selectedRows,
                      statusApproval.approve,
                    )
                  }}
                  className="rounded"
                  size="sm"
                  color="success"
                  outline
                >
                  {' '}
                  <Check size={20} />{' '}
                </CustomButton>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Alert
          color="success"
          isOpen={showAlertSuccess}
          toggle={() => setShowAlertSuccess(false)}
          className="mb-2 mt-2"
        >
          <CheckCircle className="text-success me-2" size={16} />
          <span>Transaksi berhasil disetujui.</span>
        </Alert>
        <Alert
          color="danger"
          isOpen={showAlertError}
          toggle={() => setShowAlertError(false)}
        >
          <CheckCircle className="text-success me-2" size={16} />
          <span>Transaksi berhasil dibatalkan.</span>
        </Alert>

        <DataTable
          columns={columns}
          data={trxApproval?.data?.items}
          progressPending={trxApproval?.isLoading}
          selectableRows
          onSelectedRowsChange={(e) => handleSelect(e)}
          clearSelectedRows={clearRows}
          pagination
          paginationServer
          paginationComponent={(props) => (
            <PMBPagination
              rowCount={trxApproval?.data?.totalItems || 0}
              currentPage={pagination?.page}
              rowsPerPage={pagination?.size}
              setCurrentPage={(e) => {
                setPagination((prev) => ({
                  ...prev,
                  page: e,
                }))
              }}
              setRowsPerPage={(e) => {
                setPagination((prev) => ({
                  ...prev,
                  size: e,
                }))
              }}
              props={props}
            />
          )}
        />
      </Card>
      <SidebarDetails
        size="lg"
        open={isOpenModal}
        title="Data Transaksi"
        headerClassName="mb-1"
        contentClassName="pt-0"
        toggleSidebar={() => toggleSidebar()}
      >
        <div className="h-100 w-100">
          <Card body className="mb-3 border-0 shadow-sm">
            <div className="d-flex gap-2 flex-row">
              <div className="flex-centered justify-content-start gap-1">
                <img src={ICTransaction} alt="ava" width={40} height={40} />
              </div>
              <div>
                <CardText tag="h6" className="fw-bold text-primary">
                  Alur Transaksi
                </CardText>
                <CardText tag="p" className="m-0">
                  Pembeli membuat transaksi:
                </CardText>
                <CardText tag="p">{`(${formatDatePMBV2(item?.createdAt)})`}</CardText>
              </div>
            </div>
          </Card>
          {/* penjual */}
          <Card body className="mb-3 border-0 shadow-sm">
            <CardText tag="h6" className="fw-bold">
              Pembeli
            </CardText>
            <div className="flex-centered justify-content-start gap-1">
              <img
                src={item?.buyerAvatar ?? ICDefaultPhoto}
                alt="ava"
                width={32}
                height={32}
                className="rounded-circle"
              />
              <span>{item?.buyerDetail?.fullName}</span>
            </div>
          </Card>

          {/* Rincian Pesanan */}
          <Card body className="mb-3 border-0 shadow-sm">
            <CardText tag="h6" className="fw-bold">
              Rincian Pesanan
            </CardText>
            <div className="gap-1">
              {item?.itemDetail?.map((detail, idx) => {
                return (
                  <div
                    key={idx}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex flex-column mb-2">
                      <span>{detail?.nameItem}</span>
                      <span>
                        <span className="fw-light">
                          {detail.amount} {detail.unit} •{' '}
                        </span>{' '}
                        <span className="text-primary">Rp {detail.price}</span>
                      </span>
                    </div>
                    <span>{`Rp${detail.totalPrice}`}</span>
                  </div>
                )
              })}

              <hr></hr>
              <div className="d-flex justify-content-between align-items-center">
                <span className="fw-bold">Total yang dibayarkan</span>
                <span className="fw-bold">{`Rp${intl.formatNumber(
                  item?.itemDetail
                    ?.map((e) => e.totalPrice)
                    ?.reduce((sum, e) => sum + e, 0),
                  {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  },
                )}`}</span>
              </div>
            </div>
          </Card>
          {/* Details */}
          <Card body className="mb-3 border-0 shadow-sm">
            <CardText tag="h6" className="fw-bold">
              Dibuat oleh
            </CardText>
            <div className="flex-centered justify-content-start gap-1">
              <img
                src={item?.makerAvatar ?? ICDefaultPhoto}
                alt="ava"
                width={32}
                height={32}
                className="rounded-circle"
              />
              <span>{item?.makerName}</span>
            </div>
          </Card>
          {access && (
            <div className="d-flex justify-content-between gap-3 w-100">
              <CustomButton
                block
                color="primary"
                size="sm"
                outline
                onClick={() => {
                  toggleFastApprove([item], statusApproval.reject)
                }}
              >
                Tolak
              </CustomButton>
              <CustomButton
                block
                color="primary"
                size="sm"
                onClick={() => {
                  toggleFastApprove([item], statusApproval.approve)
                }}
              >
                Approve
              </CustomButton>
            </div>
          )}
        </div>
      </SidebarDetails>

      <ModalInfoFast
        isOpen={isOpen2}
        requestData={selectRows}
        toggleClearRows={toggleClearRows}
        setIsOpen={setIsOpen2}
        toggle={() => toggleFastApprove()}
        toggleSuccess={() => {
          if (item) {
            toggleSidebar()
            setShowAlertSuccess(true)
            trxApproval.refetch()
            toggleClearRows()
            setSelectedRows([])
          } else {
            setShowAlertSuccess(true)
            trxApproval.refetch()
            toggleClearRows()
            setSelectedRows([])
          }
        }}
        toggleReject={() => {
          if (item) {
            toggleSidebar()
            setShowAlertError(true)
            trxApproval.refetch()
            toggleClearRows()
            setSelectedRows([])
          } else {
            setShowAlertError(true)
            trxApproval.refetch()
            toggleClearRows()
            setSelectedRows([])
          }
        }}
      />
    </>
  )
}

export default TableRequestSell
